import { ChecklistCheck } from './checklist-check.model';
import { ChecklistGroup } from './checklist-group.model';

export class Checklist {

  guidId: string;
  name: string;
  checklistResult: string;
  groups: ChecklistGroup[];
  checks: ChecklistCheck[];
  allowFreeTextAtConfirm: boolean;
  confirmWithSignature: boolean;

  $completed: boolean;
  $freeText: string;
  $signatureB64: string;
  $signatureResourceGuidId: string;

  constructor(item?: Partial<Checklist>) {
    Object.assign(this, item);
  }

}
