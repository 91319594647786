import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/app';


@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
  ) {

  }
  transform(text: string): string {
    return this.translateService.instant(text);
  }

}