import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DictString, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutValue, RuntimeLayoutValueType } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';


@Component({
  selector: 'lc-control-location1',
  templateUrl: 'control-location1.component.html',
  styleUrls: ['./control-location1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlLocation1Component extends ControlBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly defaultTimeoutInSec = 30;

  private previousNow: number;
  theme: string;
  private timeout: number;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.timeout = this.layoutControl.parseRV('TimeoutInSeconds', this.defaultTimeoutInSec) * 1000;
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.start();

    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  start() {
    this.previousNow = Date.now();
    this.checkForLocation();
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};

    context['GeoJson'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
      valueTypeId: RuntimeLayoutValueType.String
    });

    if (this.layoutControl?.parseRV('EventGps')) {
      context['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    return context;
  }

  private checkForLocation() {
    const eventContextValues: any = {};
    const location = this.geolocationService.getLastKnownPosition();
    if (this.geolocationService.noAccess) {
      eventContextValues['PortName'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify('NoAccess'),
        valueTypeId: RuntimeLayoutValueType.String
      });
    } else if (location) {
      eventContextValues['PortName'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify('Location'),
        valueTypeId: RuntimeLayoutValueType.String
      });
    } else if (Date.now() - this.previousNow > this.timeout) {
      eventContextValues['PortName'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify('NoFix'),
        valueTypeId: RuntimeLayoutValueType.String
      });
    } else {
      setTimeout(() => {
        this.checkForLocation();
      }, 100);
      return;
    }

    this.triggerEvent.emit({
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
  }

}

