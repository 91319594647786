<div class="height-100 flex-col center middle gap-1">
  <h2 *ngIf="layoutControl.parseRV('ControlHeadlineEnabled')"
    style="padding-left: 4px;">
    {{ layoutControl.parseRV('ControlHeadlineText') }}
  </h2>

  <div class="container"
    (click)="trackingSoundEnabled = !trackingSoundEnabled">
    <div class="radar">
      <img alt="rfid locate radar"
        [src]="'assets/img/rfid-locate.webp'" />
    </div>

    <div class="tag"
      [style.bottom]="tagBottomPosition + 'px'">
    </div>
  </div>

</div>