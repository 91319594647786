import { Injectable } from '@angular/core';
import { RingBuffer } from '../../utils/ring-buffer';
import { StorageService } from './storage.service';


export enum OpsStatsType {
  Unknown = 'Unknown',
  ApiFailedRequestsCount = 'ApiFailedRequestsCount',
  ApiRequestResponseTimeInMs = 'ApiRequestResponseTimeInMs',
  WsConnectionTimeInMs = 'WsConnectionTimeInMs',
  WsDisconnectsCount = 'WsDisconnectsCount',
  WsReconnectRetriesCount = 'WsReconnectRetriesCount',
  WsRequestResponseTimeInMs = 'WsRequestResponseTimeInMs',
  WsTimeoutsCount = 'WsTimeoutsCount',
  AppAliveStateChanges = 'AppAliveStateChanges',
  AppOnlineStateChanges = 'AppOnlineStateChanges',
  NetworkInfoDownlink = 'NetworkInfoDownlink',
  NetworkInfoRTT = 'NetworkInfoRTT',
}

export class OpsStatsResult {
  avg: number;
  count: number;
  min: number;
  max: number;
  oldestDate: Date;
  sum: number;
  stateDuration: { [key: string]: number; }
  stateDurationString: { [key: string]: string; }

  constructor(item?: Partial<OpsStatsResult>) {
    Object.assign(this, item);

    this.avg = this.avg || 0;
    this.count = this.count || 0;
    this.min = this.min || Number.MAX_SAFE_INTEGER;
    this.max = this.max || 0;
    this.oldestDate = this.oldestDate || new Date();
    this.sum = this.sum || 0;
    this.stateDuration = {};
    this.stateDurationString = {};
  }
}

export interface OpsStatsValue {
  tick: number;
  value: number | string;
}

export class OpsStats {
  [key: string]: RingBuffer<OpsStatsValue>;
}

@Injectable({
  providedIn: 'root'
})
export class OpsStatsService {

  private readonly storageKey = 'lc_opsStats';
  private readonly ringBufferSize = 100;
  private opsStats: OpsStats;

  constructor(
    private storageService: StorageService,
  ) {
    this.storageService.get(this.storageKey)
    .subscribe((opsStats: OpsStats) => {
      this.opsStats = opsStats || {};
      for (const type of Object.keys(this.opsStats)) {
        this.opsStats[type] = RingBuffer.fromPlain(this.opsStats[type] || [], this.ringBufferSize);
      }
    });
  }

  addValue(type: OpsStatsType, value: number | string) {
    this.opsStats[type] = this.opsStats[type] || new RingBuffer(this.ringBufferSize);
    this.opsStats[type].push({
      tick: Date.now(),
      value: value,
    });

    this.storageService.set(this.storageKey, this.opsStats).subscribe();
  }

  clear() {
    this.opsStats = this.opsStats || {};
    for (const type of Object.keys(this.opsStats)) {
      this.opsStats[type] = undefined;
    }
    this.storageService.set(this.storageKey, this.opsStats).subscribe();
  }

  getStatsForAllTypes(): { [type: string]: OpsStatsResult} {
    const result = {};

    for (let type of Object.keys(this.opsStats || {})) {
      result[type] = this.getStatsForType(type as OpsStatsType);
    }

    return result;
  }

  getStatsForType(type: OpsStatsType): OpsStatsResult {
    const result = new OpsStatsResult();

    if (!this.opsStats[type]?.length) {
      result.min = 0;
      return result;
    }

    result.oldestDate = new Date(this.opsStats[type][0].tick);
    if ([OpsStatsType.AppAliveStateChanges, OpsStatsType.AppOnlineStateChanges].indexOf(type) >= 0) {
      let lastState = this.opsStats[type][0].value;
      let lastTick = this.opsStats[type][0].tick;
      for (let i = 1; i < this.opsStats[type].length; i++) {
        const currentState = this.opsStats[type][i].value;
        const currentTick = this.opsStats[type][i].tick;

        result.stateDuration[lastState] = result.stateDuration[lastState] || 0;
        result.stateDuration[lastState] += currentTick - lastTick;
        lastState = currentState;
        lastTick = currentTick;
      }

      result.stateDuration[lastState] = result.stateDuration[lastState] || 0;
      result.stateDuration[lastState] += Date.now() - lastTick;

      for (const state of Object.keys(result.stateDuration)) {
        result.stateDurationString[state] = this.getRelativeTimeSpan(result.stateDuration[state]);
      }
    } else {
      for (let opsStatsValue of this.opsStats[type]) {
        result.count += 1;
        result.max = Math.max(result.max, (opsStatsValue.value as number|| 0));
        result.min = Math.min(result.min, (opsStatsValue.value as number|| 0));
        result.sum += (opsStatsValue.value as number || 0);
      }

      result.avg = ~~(result.sum / result.count);
    }

    return result;
  }

  getLastOpsStatsValueForType(type: OpsStatsType): OpsStatsValue {
    if (!this.opsStats?.[type]) return { tick: 0, value: 0 };

    return this.opsStats[type][this.opsStats[type].length - 1];
  }

  private getRelativeTimeSpan(value: number) {
    if (value == null || isNaN(value)) return '-';

    let remain = value / 1000;
    const hours = ~~(remain / (60 * 60));
    remain = remain - (hours * 60 * 60);
    const minutes = ~~(remain / 60);
    remain = remain - (minutes * 60);
    const seconds = ~~remain;

    if (value != null) {
      let result = `${hours ? hours + 'h' : ''}${minutes ? minutes + 'm' : ''}${seconds ? seconds + 's' : ''}` || '0s';
      return result;
    } else {
      return undefined;
    }
  }

}
