import { Field, Message, Type } from 'protobufjs/light';



@Type.d('RuntimeLayoutLocationPoints')
export class RuntimeLayoutLocationPoints extends Message<RuntimeLayoutLocationPoints> {

  @Field.d(20, 'int32', 'required')
  batchSequenceNr: number;
  @Field.d(21, 'int64', 'optional')
  tick: number;
  @Field.d(23, 'string', 'repeated')
  geoJSONs: string[];

}
