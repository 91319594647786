import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { BluetoothDevice, BluetoothDeviceType } from 'src/app/shared/models/bluetooth-device.model';
import { TextService, VibrationService } from 'src/app/shared/services';



@Component({
  selector: 'lc-bluetooth-type-popover',
  templateUrl: 'bluetooth-type.popover.html',
  styleUrls: ['./bluetooth-type.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BluetoothTypePopover {

  device: BluetoothDevice;

  constructor(
    private cdr: ChangeDetectorRef,
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private vibrationService: VibrationService,
  ) {
    this.device = this.navParams.get('device');
  }

  deviceTypeChanged(type: BluetoothDeviceType) {
    this.vibrationService.vibrate();

    this.device.type = type;
    this.device.settings = this.device.settings || {};

    if (this.device.type !== BluetoothDeviceType.Printer) {
      this.popoverCtrl.dismiss(this.device);
    }

    this.cdr.markForCheck();
  }

  timeoutChanged() {
    this.vibrationService.vibrate();
    this.device.settings.disconnectOnInactivityTimeoutMS = parseInt(this.device.settings.disconnectOnInactivityTimeoutMS);

    setTimeout(() => {
      this.popoverCtrl.dismiss(this.device);
      this.cdr.markForCheck();
    }, 1000);
  }
}
