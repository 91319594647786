import { Pipe, PipeTransform } from '@angular/core';
import { DesignStyleJsonItem } from 'src/app/shared/models/studio';


@Pipe({ name: 'getItemFieldValue' })
export class GetItemFieldValuePipe implements PipeTransform {
  transform (item: DesignStyleJsonItem, i: number, j: number, thisComponent: any, pipePureValueBusting?: number): any {
    if (!thisComponent) return null;
    return thisComponent.getItemFieldValue(item, i, j);
  }

}