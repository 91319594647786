import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlUpdate1Component } from './control-update1.component';


@NgModule({
  declarations: [
    ControlUpdate1Component,
  ],
  exports: [
    ControlUpdate1Component,
  ],
  imports: [
    SharedModule,

  ],
  providers: [],
})
export class ControlUpdate1Module { }
