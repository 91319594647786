import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LogUtils } from '../../utils';
import { NetworkService } from '../app/network.service';
import { OpsStatsService, OpsStatsType } from '../app/ops-stats.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';


@Injectable({
  providedIn: 'root'
})
export class PingService {
  private readonly pingByte: Uint8Array = Uint8Array.from([0x10]);
  private readonly timeoutInMs = 5 * 1000;

  private subscriptionTimeout: any;

  constructor(
    private networkService: NetworkService,
    private opsStatsService: OpsStatsService,
    private webSocketClientService: WebSocketClientService
  ) { }

  ping(): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      const subscription = this.webSocketClientService
      .getMessages$()
      .pipe(delay(10))
      .subscribe((msg: Uint8Array) => {
        this.handleIncomingMessage(msg, observer, subscription);
      }, (error: any) => {
        LogUtils.warn('Was this suppose to happen?');
      });

      this.checkForTimeout(subscription, observer);
      this.webSocketClientService.send(this.pingByte);
    });
  }

  private handleIncomingMessage(
    msg: Uint8Array, observer: Observer<void>, subscription: Subscription
  ) {
    if (this.subscriptionTimeout) clearTimeout(this.subscriptionTimeout);
    if (!subscription || subscription.closed) return;

    observer.next(null);
    observer.complete();

    subscription.unsubscribe();
    subscription = null;
  }

  private checkForTimeout(subscription: Subscription, observer: Observer<void>) {
    if (this.subscriptionTimeout) clearTimeout(this.subscriptionTimeout);
    this.subscriptionTimeout = setTimeout(() => {
      // if we didn't get a reply from the server, make sure we clear the subscription as this would keep the
      // connection open forever
      if (!subscription || subscription.closed) return;

      this.networkService.logNetworkAndSystemStatus();
      this.opsStatsService.addValue(OpsStatsType.WsTimeoutsCount, 1);

      subscription.unsubscribe();
      subscription = null;

      observer.error('Ping Timeout');
    }, this.timeoutInMs);
  }

}
