import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TextService, VibrationService } from 'src/app/shared/services';



@Component({
  selector: 'lc-enroll-menu-popover',
  templateUrl: 'enroll-menu.popover.html',
  styleUrls: ['./enroll-menu.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrollMenuPopover {

  constructor(
    private textService: TextService,
    private popoverCtrl: PopoverController,
    private vibrationService: VibrationService,
  ) {

  }

  goTo(page: string) {
    this.vibrationService.vibrate(true);

    this.popoverCtrl.dismiss(page);
  }

}
