import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalsModule } from 'src/app/modals/modals.module';
import { PopoversModule } from 'src/app/popovers/popovers.module';
import { ControlsModule } from 'src/app/shared/components/control/controls.module';
import { KeyboardModule } from 'src/app/shared/components/keyboard/keyboard.module';
import { ScannerDrawerModule } from 'src/app/shared/components/scanner-drawer/scanner-drawer.module';
import { NavigationModule } from 'src/app/shared/navigation.module';
import { SharedModule } from '../../shared/shared.module';
import { ScreenControlContainerComponent, ScreenDual1Component, ScreenFull1Component } from './components/screen';
import { MainPage } from './main.page';



@NgModule({
    declarations: [
        MainPage,
        ScreenControlContainerComponent,
        ScreenDual1Component,
        ScreenFull1Component,
    ],
    imports: [
        SharedModule,
        ControlsModule,
        KeyboardModule,
        ModalsModule,
        NavigationModule,
        PopoversModule,
        RouterModule.forChild([
            {
                path: '',
                component: MainPage
            }
        ]),
        ScannerDrawerModule,
    ]
})
export class MainPageModule { }
