import { Observable, Observer, Subject } from "rxjs";

export class BlobUtils {

  static b64ToBlob(b64: string, fileType: string, binary: boolean): Blob {
    if (binary) {
      const bytesString: string = atob(b64);
      return BlobUtils.binaryStringToBlob(bytesString, fileType);
    } else {
      const bytesString: string = BlobUtils.b64ToUtf8(b64);
      return BlobUtils.stringToBlob(bytesString, fileType);
    }
  }

  static binaryStringToBlob(bytesString: string, fileType: string, sliceSize?: number) {
    sliceSize = sliceSize || 512;

    var byteArrays = [];
    for (var offset = 0; offset < bytesString.length; offset += sliceSize) {
      var slice = bytesString.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: fileType });
  }

  static utf8ToB64(str: string) {
    return window.btoa(unescape(encodeURIComponent( str )));
  }

  static b64ToUtf8(str: string) {
    return decodeURIComponent(escape(window.atob( str )));
  }

  static stringToBlob(text: string, fileType: string, sliceSize?: number): Blob {
    return new Blob([text], { type: fileType });
  }

  static blobToB64(file: File | Blob): Observable<string> {
    const subject = new Subject<string>();

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      subject.next(reader.result as string);
      subject.complete();
    };
    reader.onerror = function (error) {
      subject.error(error);
    };

    return subject;
 }

 static blobToText(file: File | Blob): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      var reader = new FileReader();
      reader.onload = function () {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.onerror = function (error) {
        observer.error(error);
      };
      reader.readAsText(file);
    });
  }

  static blobToDataURL(blob): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const fr = new FileReader();
      fr.onload = (e) => {
        observer.next(e.target.result as string);
        observer.complete();
      };
      fr.readAsDataURL(blob);
    });
  }

  // Alternative, supposedely MDN recommended (more recent but worse support for older browsers)
  /*static utf8ToB64(str: string) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    }));
  }

  static b64ToUtf8(str: string) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''));
  }*/


}
