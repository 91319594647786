import { Field, Message, Type } from 'protobufjs/light';



@Type.d('RuntimeLayoutDebugLog')
export class RuntimeLayoutDebugLog extends Message<RuntimeLayoutDebugLog> {

  @Field.d(20, 'string', 'required')
  logDateTime: string;
  @Field.d(21, 'string', 'optional')
  method: string;
  @Field.d(22, 'string', 'optional')
  message: string;

}
