<div class="screen flex-1 flex-col"
  [class.offline]="!isConnectionToServerActive">
  <div class="flex-1 flex-col border-bottom">
    <lc-screen-control-container *ngFor="let control of controls[0]"
      [ngClass]="control.$isHead ? 'head-control' : 'flex-1'"
      [layout]="layout"
      [layoutControl]="control"
      [layoutDesigns]="layoutDesigns"
      [(layoutScreen)]="layoutScreen"
      (layoutScreenChange)="layoutScreenChange.emit($event)"
      [layoutSnapshot]="layoutSnapshot"
      [layoutTexts]="layoutTexts"
      [smartImages]="smartImages">
    </lc-screen-control-container>
  </div>

  <div class="flex-1 flex-col">
    <lc-screen-control-container *ngFor="let control of controls[1]"
      [ngClass]="control.$isHead ? 'head-control' : 'flex-1'"
      [layout]="layout"
      [layoutControl]="control"
      [layoutDesigns]="layoutDesigns"
      [(layoutScreen)]="layoutScreen"
      (layoutScreenChange)="layoutScreenChange.emit($event)"
      [layoutSnapshot]="layoutSnapshot"
      [layoutTexts]="layoutTexts"
      [smartImages]="smartImages">
    </lc-screen-control-container>
  </div>
</div>
