<div class="grid-container">
  <div class="control-external1">
    <h2 *ngIf="layoutControl.parseRV('ControlHeadlineEnabled')" style="padding-left: 4px;">
      {{ layoutControl.parseRV('ControlHeadlineText') }}
    </h2>

    <div class="external">
      <img alt="external icon"
        (click)="openExternal()"
        [src]="'assets/img/' + theme + '/icon-external-link-alt.svg'" />
    </div>
  </div>
</div>