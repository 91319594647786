import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DeviceEnrollment, DeviceRunStatus } from '../../models';
import { AppService } from '../../services';
import { TranslateService } from '../../services/app';



@Component({
  selector: 'lc-readonly-overlay',
  templateUrl: 'readonly-overlay.component.html',
  styleUrls: ['./readonly-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyOverlayComponent implements OnInit {

  deviceEnrollment: DeviceEnrollment;

  constructor(
    private appService: AppService,
    private translateService: TranslateService,
  ) {

  }

  ngOnInit() {
    this.deviceEnrollment = this.appService.getDeviceEnrollment();
  }

  getDeviceRunStatusLabel() {
    return !this.deviceEnrollment?.$deviceRunStatus ? this.translateService.instant('OBSERVER')
    : DeviceRunStatus[this.deviceEnrollment.$deviceRunStatus];
  }
}

