<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'Debug' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item *ngIf="settings">
    <ion-label>
      {{ 'Run Device Debug' | translate }}
    </ion-label>
    <ion-toggle #toggleDebug
      slot="end"
      color="danger"
      [checked]="settings.runDeviceDebug"
      (ionChange)="runDeviceDebugChange($event)">
    </ion-toggle>
  </ion-item>

  <div style="text-align: center; margin-top: 12px;">
    <ion-button
      (click)="sendAppStatus()"
      [disabled]="isSendToDevButtonDisabled">
      {{ 'Send to Developer' | translate }}&nbsp;&nbsp;
      <ion-icon name="send"></ion-icon>
    </ion-button>
  </div>

  <ion-grid>
    <ion-row>
      <ion-col>
        <h2>
          {{ 'App Logs' | translate }}:
        </h2>
        <pre>
{{ appStatus | json }}
        </pre>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="btAllowed">
      <ion-col>
        <h2>
          {{ 'Bluetooth Classic' | translate }}:
          <ion-spinner *ngIf="!btStatus || !btStatus.btClassic"></ion-spinner>
        </h2>
        <pre>
{{ btStatus.btClassic | json }}
        </pre>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="btAllowed">
      <ion-col>
        <h2>
          {{ 'Bluetooth LE' | translate }}:
          <ion-spinner *ngIf="!btStatus || !btStatus.ble"></ion-spinner>
        </h2>
        <pre>
{{ btStatus.ble | json }}
        </pre>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="cipherLabAllowed">
      <ion-col>
        <h2>
          {{ 'CipherLab' | translate }}:
          <ion-spinner *ngIf="!cipherLabStatus"></ion-spinner>
        </h2>
        <pre>
{{ cipherLabStatus | json }}
        </pre>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="honeywellAllowed">
      <ion-col>
        <h2>
          {{ 'Honeywell' | translate }}:
          <ion-spinner *ngIf="!honeywellStatus"></ion-spinner>
        </h2>
        <pre>
{{ honeywellStatus | json }}
        </pre>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="nfcAllowed">
      <ion-col>
        <h2>
          {{ 'NFC' | translate }}:
          <ion-spinner *ngIf="!nfcStatus"></ion-spinner>
        </h2>
        <pre>
{{ nfcStatus | json }}
        </pre>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="opticonAllowed">
      <ion-col>
        <h2>
          {{ 'Opticon' | translate }}:
          <ion-spinner *ngIf="!opticonStatus"></ion-spinner>
        </h2>
        <pre>
{{ opticonStatus | json }}
        </pre>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="pointMobileAllowed">
      <ion-col>
        <h2>
          {{ 'PointMobile' | translate }}:
          <ion-spinner *ngIf="!pointMobileStatus"></ion-spinner>
        </h2>
        <pre>
{{ pointMobileStatus | json }}
        </pre>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="satoAllowed">
      <ion-col>
        <h2>
          {{ 'Sato' | translate }}:
          <ion-spinner *ngIf="!satoStatus"></ion-spinner>
        </h2>
        <pre>
{{ satoStatus | json }}
        </pre>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="zebraAllowed">
      <ion-col>
        <h2>
          {{ 'Zebra' | translate }}:
          <ion-spinner *ngIf="!zebraStatus"></ion-spinner>
        </h2>
        <pre>
{{ zebraStatus | json }}
        </pre>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-item *ngIf="settings?.runDeviceDebug">
    <ion-label>
      {{ 'Test Developer' | translate }}
    </ion-label>
    <ion-toggle slot="end"
      color="danger"
      [(ngModel)]="settings.runDeviceDebugTestDeveloper">
    </ion-toggle>
  </ion-item>

</ion-content>
