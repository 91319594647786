import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutDesign, RuntimeLayoutDesignStyle } from './design';
import { RuntimeLayoutSession } from './runtime-layout-session.model';
import { RuntimeLayout } from './runtime-layout.model';
import { RuntimeLayoutScreen } from './screen';
import { RuntimeLayoutSmartImageRegion } from './smart-image/runtime-layout-smart-image-region.model';
import { RuntimeLayoutSmartImage } from './smart-image/runtime-layout-smart-image.model';


@Type.d('RuntimeLayoutSnapshot')
export class RuntimeLayoutSnapshot extends Message<RuntimeLayoutSnapshot> {

  @Field.d(1, 'int64', 'optional', 0)
  requestTick: number;
  @Field.d(2, 'int64', 'required')
  snapshotTick: number;
  @Field.d(3, 'int64', 'optional', 0)
  startedTick: number;

  @Field.d(5, RuntimeLayout, 'optional')
  runtimeLayout: RuntimeLayout;
  @Field.d(6, RuntimeLayoutSession, 'optional')
  runtimeLayoutSession: RuntimeLayoutSession;


  mergeLayoutSnapshotUpdate(layoutSnapshotUpdate: RuntimeLayoutSnapshot) {
    this.requestTick = layoutSnapshotUpdate.requestTick;
    this.snapshotTick = layoutSnapshotUpdate.snapshotTick;
    this.startedTick = layoutSnapshotUpdate.startedTick;

    this.mergeLayout(layoutSnapshotUpdate);
    this.mergeLayoutSession(layoutSnapshotUpdate);
  }

  mergeLayout(layoutSnapshotUpdate: RuntimeLayoutSnapshot) {
    if (!this.runtimeLayout) return;
    if (!layoutSnapshotUpdate.runtimeLayout) return;

    // Use the UPDATED Snapshot as base (as all the properties get updated there)
    // but keep the current screens (which will get merged)
    const mergedLayout = layoutSnapshotUpdate.runtimeLayout.toJSON() as RuntimeLayout;
    // these properties get casted to string for some unknown reason during serialize/deserialize...and that causes issues later on.
    mergedLayout.objectId = typeof mergedLayout.objectId === 'string' ? parseInt(mergedLayout.objectId) : mergedLayout.objectId;
    mergedLayout.tick = typeof mergedLayout.tick === 'string' ? parseInt(mergedLayout.tick) : mergedLayout.tick;
    mergedLayout.startedTick = typeof mergedLayout.startedTick === 'string' ? parseInt(mergedLayout.startedTick) : mergedLayout.startedTick;
    mergedLayout.activeScreen = typeof mergedLayout.activeScreen === 'string' ? parseInt(mergedLayout.activeScreen) : mergedLayout.activeScreen;
    mergedLayout.runtimeSolutionStartedTick = typeof mergedLayout.runtimeSolutionStartedTick === 'string' ? parseInt(mergedLayout.runtimeSolutionStartedTick) : mergedLayout.runtimeSolutionStartedTick;

    for (const removedLayoutScreen of mergedLayout.removedLayoutScreens || []) {
      removedLayoutScreen.objectId = typeof removedLayoutScreen.objectId === 'string' ? parseInt(removedLayoutScreen.objectId) : removedLayoutScreen.objectId;
      removedLayoutScreen.tick = typeof removedLayoutScreen.tick === 'string' ? parseInt(removedLayoutScreen.tick) : removedLayoutScreen.tick;
    }

    mergedLayout.layoutScreens = JSON.parse(JSON.stringify(this.runtimeLayout.layoutScreens || {}));

    // the texts never get deleted, they keep getting added to the stack...
    mergedLayout.layoutTexts = JSON.parse(JSON.stringify(this.runtimeLayout.layoutTexts || {}));
    for (const textId of Object.keys(layoutSnapshotUpdate.runtimeLayout.layoutTexts || {})) {
      mergedLayout.layoutTexts[textId] = layoutSnapshotUpdate.runtimeLayout.layoutTexts[textId];
    }
    for (const textId of Object.keys(mergedLayout.layoutTexts || {})) {
      // these properties get casted to string for some unknown reason during serialize/deserialize...and that causes issues later on.
      mergedLayout.layoutTexts[textId].tick = typeof mergedLayout.layoutTexts[textId].tick === 'string' ? parseInt(mergedLayout.layoutTexts[textId].tick) : mergedLayout.layoutTexts[textId].tick;
    }

    // the smartImages never get deleted, they keep getting added to the stack...
    const mergedSmartImages = JSON.parse(JSON.stringify(this.runtimeLayout.smartImages || []), this.layoutDesignsReviver);
    mergedLayout.smartImages = []; // need to fromObject this array's items as the smartImageBinary serialize/deserialize is weird in the protobuf
    for (const ld of mergedSmartImages || []) {
      mergedLayout.smartImages.push(RuntimeLayoutSmartImage.fromObject(ld));
    }
    // check for existing and update or add if new
    for (const smartImage of layoutSnapshotUpdate.runtimeLayout.smartImages || []) {
      const existingSI = mergedLayout.smartImages.find((rlsi: RuntimeLayoutSmartImage) => {
        return rlsi.smartImageGuidId === smartImage.smartImageGuidId;
      });
      if (existingSI) {
        for (const region of smartImage.regions || []) {
          const existingR = existingSI.regions.find((r: RuntimeLayoutSmartImageRegion) => {
            return r.regionGuidId === region.regionGuidId;
          })
          if (existingR) {
            Object.assign(existingR, region);
          } else {
            existingSI.regions.push(region);
          }
        }
        // Object.assign(existingLD, layoutDesign);
      } else {
        mergedLayout.smartImages.push(smartImage);
      }
    }

    // the layoutDesigns never get deleted, they keep getting added to the stack...
    const mergedLayoutDesigns = JSON.parse(JSON.stringify(this.runtimeLayout.layoutDesigns || []), this.layoutDesignsReviver);
    mergedLayout.layoutDesigns = []; // need to fromObject this array's items as the styleJsonBinary serialize/deserialize is weird in the protobuf
    for (const ld of mergedLayoutDesigns || []) {
      mergedLayout.layoutDesigns.push(RuntimeLayoutDesign.fromObject(ld));
    }
    // check for existing and update or add if new
    for (const layoutDesign of layoutSnapshotUpdate.runtimeLayout.layoutDesigns || []) {
      const existingLD = mergedLayout.layoutDesigns.find((ld: RuntimeLayoutDesign) => {
        return ld.designGuidId === layoutDesign.designGuidId;
      });
      if (existingLD) {
        for (const designStyle of layoutDesign.designStyles || []) {
          const existingDS = existingLD.designStyles.find((ds: RuntimeLayoutDesignStyle) => {
            return ds.designStyleGuidId === designStyle.designStyleGuidId;
          })
          if (existingDS) {
            Object.assign(existingDS, designStyle);
          } else {
            existingLD.designStyles.push(designStyle);
          }
        }
        // Object.assign(existingLD, layoutDesign);
      } else {
        mergedLayout.layoutDesigns.push(layoutDesign);
      }
    }

    // Start the merge by removing deleted screens...
    const removedLayoutScreens = layoutSnapshotUpdate.runtimeLayout.removedLayoutScreens;
    for (const removedLayoutScreen of removedLayoutScreens || []) {
      // these properties get casted to string for some unknown reason during serialize/deserialize...and that causes issues later on.
      removedLayoutScreen.objectId = typeof removedLayoutScreen.objectId === 'string' ? parseInt(removedLayoutScreen.objectId) : removedLayoutScreen.objectId;
      removedLayoutScreen.tick = typeof removedLayoutScreen.tick === 'string' ? parseInt(removedLayoutScreen.tick) : removedLayoutScreen.tick;
      delete mergedLayout.layoutScreens[removedLayoutScreen.objectId];
    }

    // then check for new or updated screens
    const layoutScreens = RuntimeLayout.fromObject(layoutSnapshotUpdate.runtimeLayout).layoutScreens || {};
    for (const layoutScreenId of Object.keys(layoutScreens)) {
      if (Object.keys(mergedLayout.layoutScreens || {}).indexOf(layoutScreenId) < 0) {
        // new screen -> add entire object
        mergedLayout.layoutScreens[layoutScreenId] = layoutSnapshotUpdate.runtimeLayout.layoutScreens[layoutScreenId];
        continue;
      }

      // updated screen
      // Use the updated screen as base (as all the properties get updated there)
      // but keep the current screen controls, sets and datas (which will get merged)
      mergedLayout.layoutScreens[layoutScreenId] = JSON.parse(JSON.stringify(layoutScreens[layoutScreenId]));
      mergedLayout.layoutScreens[layoutScreenId].controls = this.runtimeLayout.layoutScreens[layoutScreenId].controls;
      if (this.runtimeLayout.layoutScreens[layoutScreenId].heads && Object.keys(this.runtimeLayout.layoutScreens[layoutScreenId].heads).length) {
        mergedLayout.layoutScreens[layoutScreenId].heads = this.runtimeLayout.layoutScreens[layoutScreenId].heads;
      } else {
        mergedLayout.layoutScreens[layoutScreenId].heads = {};
      }

      // use the current layoutScreen sets/datas/variables, and merge on top of that below
      mergedLayout.layoutScreens[layoutScreenId].sets = JSON.parse(JSON.stringify(this.runtimeLayout.layoutScreens[layoutScreenId].sets || null));
      mergedLayout.layoutScreens[layoutScreenId].datas = JSON.parse(JSON.stringify(this.runtimeLayout.layoutScreens[layoutScreenId].datas || null));
      mergedLayout.layoutScreens[layoutScreenId].variables = JSON.parse(JSON.stringify(this.runtimeLayout.layoutScreens[layoutScreenId].variables || null));

      // REMOVE deleted inner stuff...
      for (const removedControl of layoutScreens[layoutScreenId].removedControls || []) {
        delete mergedLayout.layoutScreens[layoutScreenId].controls[removedControl.objectId];
      }
      for (const removedHead of layoutScreens[layoutScreenId].removedHeads || []) {
        delete mergedLayout.layoutScreens[layoutScreenId].heads[removedHead.objectId];
      }
      for (const removedSet of layoutScreens[layoutScreenId].removedSets || []) {
        delete mergedLayout.layoutScreens[layoutScreenId].sets[removedSet.objectId];
      }
      for (const removedData of layoutScreens[layoutScreenId].removedDatas || []) {
        delete mergedLayout.layoutScreens[layoutScreenId].datas[removedData.objectId];
      }
      for (const removedVariable of layoutScreens[layoutScreenId].removedVariables || []) {
        delete mergedLayout.layoutScreens[layoutScreenId].variables[removedVariable.objectId];
      }

      // and ADD new inner stuff.
      for (const controlId of Object.keys(layoutScreens[layoutScreenId].controls || {})) {
        mergedLayout.layoutScreens[layoutScreenId].controls[controlId] = layoutSnapshotUpdate.runtimeLayout.layoutScreens[layoutScreenId].controls[controlId];
      }
      for (const controlId of Object.keys(layoutScreens[layoutScreenId].heads || {})) {
        mergedLayout.layoutScreens[layoutScreenId].heads[controlId] = layoutSnapshotUpdate.runtimeLayout.layoutScreens[layoutScreenId].heads[controlId];
      }
      for (const setId of Object.keys(layoutScreens[layoutScreenId].sets || {})) {
        if (mergedLayout.layoutScreens[layoutScreenId].sets[setId]?.datas) {
          mergedLayout.layoutScreens[layoutScreenId].sets[setId].tick = layoutSnapshotUpdate.runtimeLayout.layoutScreens[layoutScreenId].sets[setId].tick;
          // if the set exists, we go one level deeper and merge the datas...
          // first REMOVE
          const removedDatas = layoutScreens[layoutScreenId].sets[setId].removedDatas;
          for (const removedData of removedDatas || []) {
            delete mergedLayout.layoutScreens[layoutScreenId].sets[setId].datas[removedData.objectId];
          }
          // then ADD
          const datas = layoutScreens[layoutScreenId].sets[setId].datas;
          for (const dataId of Object.keys(datas || {})) {
            mergedLayout.layoutScreens[layoutScreenId].sets[setId].datas[dataId] = layoutSnapshotUpdate.runtimeLayout.layoutScreens[layoutScreenId].sets[setId].datas[dataId];
          }
        } else {
          mergedLayout.layoutScreens[layoutScreenId].sets[setId] = layoutSnapshotUpdate.runtimeLayout.layoutScreens[layoutScreenId].sets[setId];
        }
      }
      for (const dataId of Object.keys(layoutScreens[layoutScreenId].datas || {})) {
        mergedLayout.layoutScreens[layoutScreenId].datas[dataId] = layoutSnapshotUpdate.runtimeLayout.layoutScreens[layoutScreenId].datas[dataId];
      }
      for (const variableId of Object.keys(layoutScreens[layoutScreenId].variables || {})) {
        mergedLayout.layoutScreens[layoutScreenId].variables[variableId] = layoutSnapshotUpdate.runtimeLayout.layoutScreens[layoutScreenId].variables[variableId];
      }
    }

    for (const key of Object.keys(mergedLayout.layoutScreens)) {
      // keep the merged copy objects as instances of layoutScreens
      mergedLayout.layoutScreens[key] = RuntimeLayoutScreen.fromObject(mergedLayout.layoutScreens[key]);
    }

    Object.assign(this.runtimeLayout, mergedLayout);
  }

  mergeLayoutSession(layoutSnapshotUpdate: RuntimeLayoutSnapshot) {
    this.runtimeLayoutSession = this.runtimeLayoutSession || {} as RuntimeLayoutSession;
    if (layoutSnapshotUpdate.runtimeLayoutSession) {
      // Use the UPDATED Snapshot as base (as all the properties get updated there)
      // but keep the current settingGroups (which will get merged)
      const mergedLayoutSession = layoutSnapshotUpdate.runtimeLayoutSession.toJSON() as RuntimeLayoutSession;
      mergedLayoutSession.settingGroups = JSON.parse(JSON.stringify(this.runtimeLayoutSession.settingGroups || {}));

      // then check for new or updated settingGroups
      const settingGroups = layoutSnapshotUpdate.runtimeLayoutSession.toJSON().settingGroups || {};
      for (const settingGroupId of Object.keys(settingGroups)) {
        mergedLayoutSession.settingGroups[settingGroupId] = settingGroups[settingGroupId];
      }

      Object.assign(this.runtimeLayoutSession, mergedLayoutSession);
    }
  }

  private layoutDesignsReviver(key: string, value: any) {
    if (key.indexOf('Binary') >= 0) {
      return new Uint8Array(atob(value).split('').map((c) => {
        return c.charCodeAt(0);
      }));
    } else {
      return value;
    }
  }

}
