import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { from } from 'rxjs';
import { BluetoothModal, DebugModal, SettingsModal } from 'src/app/modals';
import { SystemInfoModal } from 'src/app/modals/system-info/system-info.modal';
import { EnrollMenuPopover } from 'src/app/popovers';
import { BluetoothMenuPopover } from 'src/app/popovers/bluetooth-menu/bluetooth-menu.popover';
import { DeviceEnrollment, Notification, RuntimeLayoutNotifyType } from '../../models';
import { AppService, ClientAuthService, NotificationService, PluginService, PluginType, VibrationService } from '../../services';
import { TranslateService } from '../../services/app';
import { BrowserUtils } from '../../utils';
import { LinkingModal } from 'src/app/modals/linking/linking.modal';
const { version } = require('../../../../../package.json');


@Component({
  selector: 'lc-side-menu',
  templateUrl: 'side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent {

  readonly modalBluetooth = BluetoothModal;
  readonly modalDebug = DebugModal;
  readonly modalLinking = LinkingModal;
  readonly modalSettings = SettingsModal;
  readonly modalSystemInfo = SystemInfoModal;

  appVersion: string = version.replace(/-/g, ' ');
  deviceId: string;
  deviceEnrollment: DeviceEnrollment;
  isDeviceApp: boolean;
  isLocalhost: boolean;
  isRunDevice: boolean;
  showBluetoothMenu: boolean;

  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private clientAuthService: ClientAuthService,
    public menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private notificationService: NotificationService,
    private pluginService: PluginService,
    private popoverCtrl: PopoverController,
    private router: Router,
    private translateService: TranslateService,
    private vibrationService: VibrationService,
  ) {
    this.appService.deviceEnrollmentChanges()
    .subscribe((de: DeviceEnrollment) => {
      this.deviceEnrollment = de;

      this.cdr.markForCheck();
    });

    this.clientAuthService.deviceIdChanges()
    .subscribe((deviceId: string) => {
      this.deviceId = deviceId;

      this.cdr.markForCheck();
    });

    this.isDeviceApp = BrowserUtils.isDeviceApp();
    this.isLocalhost = BrowserUtils.isLocalhost();
    this.isRunDevice = !!BrowserUtils.getQueryParams()?.runDevice;

    const btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);
    this.showBluetoothMenu = btPlugin.isPluginAllowed() || this.isLocalhost;
  }

  deviceRestart(factoryReset: boolean) {
    this.vibrationService.vibrate();
    this.appService.deviceRestart(factoryReset);

    if (factoryReset) {
      this.menuCtrl.toggle();
      alert(this.translateService.instant('Settings have been factory reset...'));
    }
  }

  openModal(modalComponent: any) {
    this.vibrationService.vibrate();
    from(this.modalCtrl.create(
      {
        component: modalComponent,
      }
    ))
    .subscribe((modal: HTMLIonModalElement) => {
      modal.present();
    });
  }

  goToBluetooth() {
    if (!this.deviceEnrollment) return this.enrollFirst();

    this.vibrationService.vibrate();
    from(this.popoverCtrl.create({
      component: BluetoothMenuPopover,
      cssClass: `popover-bluetooth-menu`,
      backdropDismiss: true,
      showBackdrop: true
    }))
    .subscribe((popover: HTMLIonPopoverElement) => {
      from(popover.onDidDismiss())
      .subscribe((result: OverlayEventDetail<string>) => {
        const page = result.data;
        if (page) {
          this.vibrationService.vibrate();
          this.router.navigate([page], { replaceUrl: true });
        } else {
          this.openModal(this.modalBluetooth);
        }
      });

      popover.present();
    });
  }

  goToEnrollment() {
    this.vibrationService.vibrate();
    from(this.popoverCtrl.create({
      component: EnrollMenuPopover,
      cssClass: `popover-enroll-menu`,
      backdropDismiss: true,
      showBackdrop: true
    }))
    .subscribe((popover: HTMLIonPopoverElement) => {
      from(popover.onDidDismiss())
      .subscribe((result: OverlayEventDetail<string>) => {
        const page = result.data;
        if (page) {
          this.vibrationService.vibrate();
          this.router.navigate([page, true], { queryParamsHandling: 'preserve', replaceUrl: true })
        }
      });

      popover.present();
    });
  }

  enrollFirst() {
    this.vibrationService.vibrate();
    this.notificationService.showNotification(new Notification({
      title: this.translateService.instant('Notification'),
      text: this.translateService.instant('Please enroll your device first.'),
      type: RuntimeLayoutNotifyType.VerificationAlert,
    }));
  }

}

