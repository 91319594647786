<ion-toolbar class="ion-no-padding">
  <nav class="lc-footer"
    [class.border-bottom]="showBottomBorder">
		<ul>
      <li [ngClass]="showConfirmButtons ? 'cancel' : 'back'"
        [class.disabled]="disableButtons || !layoutScreen?.backButton">
        <a href="javascript:void(0);"
          (click)="back()">
          Back
        </a>
      </li>

      <li class="action"
        [class.active]="layoutScreen?.actionButton"
        [class.disabled]="disableButtons || !layoutScreen?.actionButton">
        <a href="javascript:void(0);"
          (click)="action()">
          Action
        </a>
      </li>

      <li [ngClass]="showConfirmButtons ? 'confirm' : 'forward'"
        [class.disabled]="disableButtons || !layoutScreen?.forwardButton">
        <a href="javascript:void(0);"
          (click)="forward()">
          Forward
        </a>
      </li>
		</ul>
	</nav>
</ion-toolbar>