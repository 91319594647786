import { Field, MapField, Message, Type } from 'protobufjs/light';
import { DictNumber } from '../dict.model';
import { RuntimeLayoutSettingGroup } from './setting/runtime-layout-setting-group.model';


@Type.d('RuntimeLayoutSession')
export class RuntimeLayoutSession extends Message<RuntimeLayoutSession> {
  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(4, 'int64', 'required')
  startedTick: number;
  @Field.d(5, 'int64', 'optional')
  lastResetTick: number;

  @MapField.d(60, 'int64', RuntimeLayoutSettingGroup)
  settingGroups: DictNumber<RuntimeLayoutSettingGroup>;

}
