import { Field, Message, Type } from 'protobufjs/light';
const pako = require('pako');

@Type.d('RuntimeLayoutResourceUploadRequest')
export class RuntimeLayoutResourceUploadRequest extends Message<RuntimeLayoutResourceUploadRequest> {

  // @Field.d(1, 'int64', 'required')
  // objectId: number;
  // @Field.d(2, 'bool', 'optional')
  // active: boolean;
  // @Field.d(3, 'int64', 'required')
  // tick: number;

  @Field.d(1, 'string', 'optional')
  contentType: string;
  @Field.d(2, 'int64', 'optional')
  totalSize: number;

  @Field.d(21, 'string', 'optional')
  guidId: string;

  @Field.d(40, 'int32', 'optional')
  chunkId: number;
  @Field.d(41, 'int64', 'optional')
  chunkSize: number;
  @Field.d(42, 'bytes', 'optional')
  chunkBinary: Uint8Array;

  set chunk(c: Uint8Array) {
    this.chunkBinary = c ? pako.gzip(c) : undefined;
  }

}
