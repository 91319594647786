import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { GeoJSON } from 'src/app/shared/models/geojson.model';
import { Resource } from 'src/app/shared/models/resource.model';
import { RuntimeLayoutResourceResponse } from 'src/app/shared/models/runtime-layout/resource/runtime-layout-resource-response.model';
import { RuntimeLayoutSmartImageRegion } from 'src/app/shared/models/runtime-layout/smart-image/runtime-layout-smart-image-region.model';
import { RuntimeLayoutSmartImage } from 'src/app/shared/models/runtime-layout/smart-image/runtime-layout-smart-image.model';
import { JsPluginLoaderService } from 'src/app/shared/services/js-plugin-loader/js-plugin-loader.service';
import { LayoutResourceService } from 'src/app/shared/services/protobuf/layout-resource.service';
import { CaseUtils } from 'src/app/shared/utils';
import { BlobUtils } from 'src/app/shared/utils/blob.utils';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import { DictString, Notification, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutNotifyType, RuntimeLayoutValue, RuntimeLayoutValueType } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
import { SmartImageData } from './models/smart-image-data.model';

declare var L: any;

@Component({
  selector: 'lc-control-smartimage1',
  templateUrl: 'control-smartimage1.component.html',
  styleUrls: ['./control-smartimage1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlSmartImage1Component extends ControlBaseComponent implements OnInit {

  readonly mapId = Date.now().toString();

  @Input() smartImages: RuntimeLayoutSmartImage[];

  private allowFreePointSelection: boolean;
  private baseLayer: any;
  private clickedSmartRegion: RuntimeLayoutSmartImageRegion;
  private featureGroupSmartRegions: any;
  private featureGroupSmartObjectPoints: any;
  private freePointGeoJSON: GeoJSON;
  private map: any;
  private originalBackButton: boolean;
  private popupButtonClick: Function;
  private previousSmartImageGuidIds: string[];
  private smartImage: RuntimeLayoutSmartImage;
  private smartImageData: SmartImageData;
  private smartImageObjectPointGuidId: string;
  private smartRegions: RuntimeLayoutSmartImageRegion[];

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private jsPluginLoaderService: JsPluginLoaderService,
    private layoutResourceService: LayoutResourceService,
  ) {
    super(injector);

    this.previousSmartImageGuidIds = [];
  }

  ngOnInit() {
    if (this.layoutControl && this.layoutScreen) {
      this.originalBackButton = this.layoutScreen.backButton;

      this.jsPluginLoaderService.load('leaflet')
      .subscribe((isPluginLoaded: boolean) => {
        if (isPluginLoaded) {
          this.allowFreePointSelection = this.layoutControl.parseRV('AllowFreePointSelection');

          this.smartImageData = this.layoutControl.parseRV('SmartImageData'); // this is a json string (not json object), so it requires the extra parsing step below
          this.smartImageData = typeof this.smartImageData === 'string' ? CaseUtils.toCamel(JSON.parse(this.smartImageData)) : CaseUtils.toCamel(this.smartImageData);
          // console.log(this.smartImageData);

          const smartImageGuidId = this.layoutControl.parseRV('SmartImageGuidId');
          this.loadSmartImageAndInitMap(smartImageGuidId);
        }
      });
    }
  }

  backButtonOverride(): boolean {
    if (this.previousSmartImageGuidIds?.length) {
      this.loadSmartImageAndInitMap(this.previousSmartImageGuidIds.pop(), true);

      this.cdr.markForCheck();
      return true;
    }

    return false;
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};
    if (this.smartImage) {
      context['SmartImageObjectGuidId'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.smartImage.smartImageGuidId),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    if (this.clickedSmartRegion) {
      context['SmartImageRegionObjectGuidId'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.clickedSmartRegion.regionGuidId),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }
    if (this.freePointGeoJSON) {
      context['SmartImageFreePointGeoJSON'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.freePointGeoJSON)),
        valueTypeId: RuntimeLayoutValueType.String,
        extendedValueType: 'json',
      });
    }
    if (this.smartImageObjectPointGuidId) {
      context['SmartImageObjectPointObjectGuidId'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.smartImageObjectPointGuidId),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }
    if (this.layoutControl?.parseRV('EventGps')) {
      context['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }
    return context;
  }

  private loadSmartImageAndInitMap(smartImageGuidId: string, isPop?: boolean) {
    if (this.smartImage) {
      const exists = this.previousSmartImageGuidIds.indexOf(this.smartImage.smartImageGuidId) >= 0;
      if (!isPop && !exists) {
        this.previousSmartImageGuidIds.push(this.smartImage.smartImageGuidId);
      }

      this.layoutScreen.backButton = this.previousSmartImageGuidIds?.length > 0 ? true : this.originalBackButton;
      this.layoutScreenChange.emit(this.layoutScreen);
    }

    this.smartImage = (this.smartImages || []).find((rlsi: RuntimeLayoutSmartImage) => {
      return GuidUtils.isEqual(rlsi.smartImageGuidId, smartImageGuidId);
    });
    if (!this.smartImage) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: 'Couldn\'t find smartImage with guidId: ' + smartImageGuidId,
        type: RuntimeLayoutNotifyType.CriticalAlert,
        blocking: true
      }));

      this.initMap(); // init an empty map (no smartImage)
      return;
    }

    this.smartRegions = this.smartImage.regions || [];

    this.layoutResourceService.get(this.smartImage.resourceGuidId, this.smartImage.resourceTick)
    .pipe(
      mergeMap((resource: Resource) => {
        const blob: Blob = new Blob([resource.content], { type: resource.contentType });
        return BlobUtils.blobToDataURL(blob);
      })
    )
    .subscribe((dataUrl: string) => {
      const img = new Image();
      img.onload = () => {
        this.initMap(img.src, img.width, img.height);
      };
      img.src = dataUrl;
      this.cdr.markForCheck();
    });
  }

  private initMap(imageUrl?: string, smartImageWidth?: number, smartImageHeight?: number) {
    if (this.map) {
      this.map.off();
      this.map.remove();
    }

    setTimeout(() => {
      const tileSize = 256;
      const imgSize = [smartImageWidth, smartImageHeight];
      this.map = L.map(
        this.mapId,
        { minZoom: 0, maxZoom: 5 },
      );

      this.map.on('popupopen', (e) => {
        setTimeout(() => {
          document.querySelector('.button-select')
          .addEventListener('click', (ev: Event) => {
            (ev.target as HTMLButtonElement).disabled = true;
            if (this.popupButtonClick) this.popupButtonClick(e);
          });
        }, 250);
      });

      if (this.allowFreePointSelection) {
        this.addClickEventToMap();
      }

      if (imageUrl) {
        // assign map and image dimensions
        var rc = new L.RasterCoords(this.map, imgSize, tileSize);

        this.baseLayer = L.imageOverlay(
          imageUrl,
          [
            rc.unproject([0, 0]),
            rc.unproject([imgSize[0], imgSize[1]]),
          ],
        );
        this.baseLayer.addTo(this.map);

        this.featureGroupSmartRegions = new L.FeatureGroup();
        this.addLayersFromSmartRegions(this.featureGroupSmartRegions);
        this.map.addLayer(this.featureGroupSmartRegions);

        this.featureGroupSmartObjectPoints = new L.FeatureGroup();
        this.addLayersFromSmartObjectPoints(this.featureGroupSmartObjectPoints);
        this.map.addLayer(this.featureGroupSmartObjectPoints);

        if (this.smartRegions?.length) {
          this.map.setView(rc.unproject([0, 0]), 0);
          this.cdr.markForCheck();

          setTimeout(() => {
            // due to some weird L.circle leaflet bug, getBounds() can only be called after the map is rendered (with setView)
            this.map.fitBounds(this.featureGroupSmartRegions.getBounds());
            this.cdr.markForCheck();
          }, 1);
        } else {
          this.map.fitBounds([
            rc.unproject([50, 50]),
            rc.unproject([imgSize[0] - 50, imgSize[1] - 50]),
          ]);
        }
      }
      this.cdr.markForCheck();
    }, 100);
  }

  private addLayersFromSmartRegions(featureGroupSmartRegions: any) {
    featureGroupSmartRegions.clearLayers();

    for (const smartRegion of this.smartRegions || []) {
      const geoJSON = JSON.parse(smartRegion.geoJson);
      L.geoJSON(geoJSON, {
        pointToLayer: (feature, latlng) => {
          if (feature.properties.radius) {
            return new L.Circle(latlng, feature.properties.radius);
          } else {
            return new L.Marker(latlng);
          }
        },
        onEachFeature: (feature, layer) => {
          this.addClickEventToSmartRegionLayer(layer);
          featureGroupSmartRegions.addLayer(layer);

          if (this.smartImageData?.dataRegions && this.smartImageData.dataRegions[geoJSON.properties?.smartRegionGuidId]) {
            const bounds = layer.getBounds();
            const latLng = bounds.getCenter();
            const marker = L.marker(latLng, {
              icon: L.divIcon({
                  className: 'count-marker',
                  html: this.smartImageData.dataRegions[geoJSON.properties.smartRegionGuidId].dataCount,
              }),
            });

            this.featureGroupSmartRegions.addLayer(marker);
            this.addClickEventToSmartRegionLayer(marker, geoJSON.properties?.smartRegionGuidId);
          }
        },
        style: {
          // color: '#ff7800',
          weight: 1,
          opacity: 0.65,
        },
      });
    };
  }

  private addLayersFromSmartObjectPoints(featureGroupSmartObjectPoints: any) {
    featureGroupSmartObjectPoints.clearLayers();

    const smartImageGuidId = GuidUtils.addDashes(this.smartImage.smartImageGuidId);
    if (this.smartImageData?.dataSmartImages && this.smartImageData.dataSmartImages[smartImageGuidId]) {
      for (const dataObject of this.smartImageData.dataSmartImages[smartImageGuidId].dataObjects || []) {
        if (dataObject.isFreePoint && dataObject.freePointGeoJSON) {
          const geoJSON = new GeoJSON(JSON.parse(dataObject.freePointGeoJSON));

          const marker = new L.Marker(L.latLng(geoJSON.geometry.coordinates[1], geoJSON.geometry.coordinates[0]));
          this.featureGroupSmartObjectPoints.addLayer(marker);
          this.addClickEventToSmartObjectPointLayer(marker, dataObject.smartImageObjectPointGuidId);
        }
      }
    }
  }

  private addClickEventToMap() {
    this.map.on('click', (e) => {
      if (this.baseLayer.getBounds().contains(e.latlng)) {
        L.DomEvent.stopPropagation(e);
        this.vibrationService.vibrate();


        const popup = new L.Popup();
        popup.setLatLng(e.latlng);
        popup.setContent(
          `<h4>${this.translateService.instant('Free Point')}:</h4>
          <button class="button-select">${this.translateService.instant('Create')}</button>`
        );

        this.popupButtonClick = (e) => {
          this.createFreePointClick(e.popup._latlng);
        };

        this.map.panTo(e.latlng);
        this.map.openPopup(popup);

        this.cdr.markForCheck();
      }
    });
  }

  private addClickEventToSmartObjectPointLayer(layer: any, smartImageObjectPointGuidId: string) {
    layer.on('click', (e) => {
      L.DomEvent.stopPropagation(e);
      this.vibrationService.vibrate();

      const popup = new L.Popup({ offset: new L.Point(0,-28) });
      popup.setLatLng(e.latlng);
      popup.setContent(
        `<h4>${this.translateService.instant('Object Point')}:</h4>
        <button class="button-select">${this.translateService.instant('Select')}</button>`
      );

      this.popupButtonClick = (e) => {
        this.selectObjectPointClick(smartImageObjectPointGuidId);
      };

      this.map.panTo(e.latlng);
      this.map.openPopup(popup);

      this.cdr.markForCheck();
    });
  }

  private addClickEventToSmartRegionLayer(layer: any, smartRegionGuidId?: string) {
    layer.on('click', (e) => {
      const geoJSON = this.transformLayerToGeoJSON(layer);
      smartRegionGuidId = smartRegionGuidId || geoJSON.properties?.smartRegionGuidId;
      this.clickedSmartRegion = (this.smartRegions || []).find(sr => GuidUtils.isEqual(sr.regionGuidId, smartRegionGuidId));
      if (!this.clickedSmartRegion) return;

      L.DomEvent.stopPropagation(e);
      this.vibrationService.vibrate();

      if (this.clickedSmartRegion.hasChildSmartImage && this.clickedSmartRegion.childSmartImageGuidId) {
        this.loadSmartImageAndInitMap(this.clickedSmartRegion.childSmartImageGuidId);
      } else {
        const popup = new L.Popup({ offset: new L.Point(0,-28) });
        popup.setLatLng(e.latlng);
        popup.setContent(
          `<h4>${this.translateService.instant('Region')}:</h4>
          <h3>${this.clickedSmartRegion.name}</h3>
          <button class="button-select">${this.translateService.instant('Select')}</button>`
        );

        this.popupButtonClick = (e) => {
          this.selectRegionClick();
        };

        this.map.panTo(e.latlng);
        this.map.openPopup(popup);
      }

      this.cdr.markForCheck();
    });
  }

  private createFreePointClick(latlng: { lat: number, lng: number}) {
    this.freePointGeoJSON = GeoJSON.fromLatLng(latlng.lat, latlng.lng);

    const eventContextValues: any = {};
    eventContextValues['EventCode'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify('SmartImageObject|SmartImageFreePointGeoJSON'),
      valueTypeId: RuntimeLayoutValueType.String
    });
    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify('FreePoint'),
      valueTypeId: RuntimeLayoutValueType.String
    });
    this.triggerEvent.emit({
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
  }

  private selectObjectPointClick(smartImageObjectPointGuidId: string) {
    this.smartImageObjectPointGuidId = smartImageObjectPointGuidId;

    const eventContextValues: any = {};
    eventContextValues['EventCode'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify('SmartImageObject|SmartImageObjectPointObject'),
      valueTypeId: RuntimeLayoutValueType.String
    });
    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify('ObjectPoint'),
      valueTypeId: RuntimeLayoutValueType.String
    });
    this.triggerEvent.emit({
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
  }

  private selectRegionClick() {
    const eventContextValues: any = {};
    eventContextValues['EventCode'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify('SmartImageObject|SmartImageRegionObject'),
      valueTypeId: RuntimeLayoutValueType.String
    });
    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify('Region'),
      valueTypeId: RuntimeLayoutValueType.String
    });
    this.triggerEvent.emit({
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
  }

  private transformLayerToGeoJSON(layer: any): GeoJSON {
    const geoJson = layer.toGeoJSON();

    if (layer instanceof L.Circle) {
      geoJson.properties.radius = layer.getRadius();
    }

    return geoJson;
  }

}

