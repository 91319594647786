import { Field, Message, Type } from 'protobufjs/light';

export enum RuntimeLayoutNotifyType {
  Unknown = 0,
  Confirmation = 1,
  VerificationAlert = 2, 
  Alert = 3,
  CriticalAlert = 4,

  CriticalServerError = 99,
}

@Type.d('RuntimeLayoutNotify')
export class RuntimeLayoutNotify extends Message<RuntimeLayoutNotify> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'optional', 0)
  tick: number;


  @Field.d(50, 'int64', 'optional')
  controlObjectId: number;
  @Field.d(51, 'string', 'optional')
  notifyGuidId: string;
  @Field.d(52, 'int64', 'optional', RuntimeLayoutNotifyType.Unknown)
  notifyType: RuntimeLayoutNotifyType;
  @Field.d(53, 'bool', 'optional')
  blocking: boolean;
  @Field.d(54, 'string', 'optional')
  text: string;
  @Field.d(55, 'float', 'optional')
  showTimeInSeconds: number;

}
