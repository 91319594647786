import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ActionPopoverModule } from './action/action.popover.module';
import { BluetoothMenuPopoverModule } from './bluetooth-menu/bluetooth-menu.popover.module';
import { BluetoothTypePopoverModule } from './bluetooth-type/bluetooth-type.popover.module';
import { ChecklistMenuPopoverModule } from './checklist-menu/checklist-menu.module';
import { ConfirmPopoverModule } from './confirm/confirm.popover.module';
import { EnrollMenuPopoverModule } from './enroll-menu/enroll-menu.popover.module';
import { SelectPrinterPopoverModule } from './select-printer/select-printer.popover.module';
import { TestPrinterPopoverModule } from './test-printer/test-printer.popover.module';


@NgModule({
  exports: [
    ActionPopoverModule,
    BluetoothMenuPopoverModule,
    BluetoothTypePopoverModule,
    ChecklistMenuPopoverModule,
    ConfirmPopoverModule,
    EnrollMenuPopoverModule,
    SelectPrinterPopoverModule,
    TestPrinterPopoverModule,
  ],
  imports: [
    ActionPopoverModule,
    BluetoothMenuPopoverModule,
    BluetoothTypePopoverModule,
    ChecklistMenuPopoverModule,
    ConfirmPopoverModule,
    EnrollMenuPopoverModule,
    SelectPrinterPopoverModule,
    TestPrinterPopoverModule,
    SharedModule,
  ],
})
export class PopoversModule { }
