import { Message, Type, Field } from 'protobufjs/light';


@Type.d('RuntimeLayoutObjectPointer')
export class RuntimeLayoutObjectPointer extends Message<RuntimeLayoutObjectPointer> {
  
  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'int64', 'required')
  tick: number;
  
}
