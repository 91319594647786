import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { DictString, RuntimeLayoutValue, RuntimeLayoutValueType } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';



@Component({
  selector: 'lc-control-sog1',
  templateUrl: 'control-sog1.component.html',
  styleUrls: ['./control-sog1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlSog1Component extends ControlBaseComponent implements OnInit, AfterViewInit {

  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;

  agreementText: string;
  b64Image: string;
  helpText: string;
  options: any;
  required: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.options = { // passed through to szimek/signature_pad constructor
      // dotSize: 1,
      minWidth: 1,
      canvasWidth: window.innerWidth - 2,
      canvasHeight: window.innerHeight - 220,
      // backgroundColor: 'white', // don't use background here as it makes the resulting image unecessarily big
      // penColor: 'black'
    };
  }

  ngOnInit(): void {
    if (!this.layoutScreen) return;

    this.agreementText = this.layoutControl.parseRV('BackgroundAgreementText');
    this.helpText = this.layoutControl.parseRV('BackgroundHelpText', this.translateService.instant('Signature goes here...'));
    this.required = this.layoutControl.parseRV('SoGRequired', false);
    if (!this.required) return;

    this.updateForwardButton();
  }

  ngAfterViewInit() {
    // this should be required to adjust to diff DPIs...but I couldn't really figure out how to use it.
    // this.signaturePad.resizeCanvas();
  }

  private updateForwardButton() {
    this.layoutScreen.forwardButton = !!this.b64Image;
    this.layoutScreenChange.emit(this.layoutScreen);
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};
    if (this.b64Image) {
      const b64 = this.b64Image.split(';base64,')[1];
      const mimeType = (this.b64Image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/) || [''])[0];
      context['SoG'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(b64),
        valueTypeId: RuntimeLayoutValueType.String
      });
      context['SoGEncoding'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(mimeType),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    context['SoGCaptured'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(!!this.b64Image),
      valueTypeId: RuntimeLayoutValueType.Bool
    });

    if (this.layoutControl?.parseRV('EventGps')) {
      context['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }
    return context;
  }

  refreshCanvas() {
    this.signaturePad.set('canvasWidth', window.innerWidth - 2);
    this.signaturePad.set('canvasHeight', window.innerHeight - 220);
    this.deleteSignature();
  }

  drawStart() {

  }

  drawStop() {
    this.b64Image = this.getCroppedSignatureDataURL();
    this.updateForwardButton();
    this.cdr.markForCheck();
  }

  deleteSignature() {
    this.b64Image = undefined;
    this.signaturePad.clear();
    this.updateForwardButton();
    this.cdr.markForCheck();
  }

  private getCroppedSignatureDataURL() {
    const canvas: HTMLCanvasElement = document.getElementsByTagName('canvas')[0];
    const croppedCanvas: HTMLCanvasElement = document.createElement('canvas');
    const croppedCtx: CanvasRenderingContext2D = croppedCanvas.getContext('2d');

    croppedCanvas.width = canvas.width;
    croppedCanvas.height = canvas.height;
    croppedCtx.drawImage(canvas, 0, 0);

    let w = croppedCanvas.width;
    let h = croppedCanvas.height;
    const pix = { x:[], y:[] };
    const imageData = croppedCtx.getImageData(0, 0, w, h);
    let index = 0;

    for (let y = 0; y < h; y++) {
      for (let x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index+3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        }
      }
    }

    pix.x.sort((a,b) => a-b);
    pix.y.sort((a,b) => a-b);
    const n = pix.x.length-1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

    croppedCanvas.width = w;
    croppedCanvas.height = h;
    croppedCtx.putImageData(cut, 0, 0);

    return croppedCanvas.toDataURL();
  }

}

