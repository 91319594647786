<div *ngIf="!(device.type === 'printer' || device.type === 'printer-sato')"
  class="popover-container">
  <h2>
    {{ 'Device Type' | translate }}
  </h2>

  <div>
    <button class="button"
      (click)="deviceTypeChanged('printer')">
      {{ 'Printer' | translate }}
    </button>

    <button class="button"
      (click)="deviceTypeChanged('printer-sato')">
      {{ 'Printer (SATO)' | translate }}
    </button>

    <button class="button"
      (click)="deviceTypeChanged('scanner')">
      {{ 'Scanner' | translate }}
    </button>

    <button class="button"
      (click)="deviceTypeChanged('thermometer')">
      {{ 'Temp Sensor' | translate }}
    </button>
  </div>
</div>

<div *ngIf="device.type === 'printer' || device.type === 'printer-sato'"
  class="popover-container smaller-padding">
  <h2>
    {{ 'Printer Settings' | translate }}
  </h2>

  <ion-item>
    <ion-label>
      {{ 'Disconnect on Inactivity?' | translate }}
    </ion-label>
    <ion-select
      interface="popover"
      [(ngModel)]="device.settings.disconnectOnInactivityTimeoutMS"
      (ngModelChange)="timeoutChanged()">
      <ion-select-option value="0">
        {{ 'Don\'t disconnect' | translate }}
      </ion-select-option>
      <ion-select-option value="3000">
        {{ 'After 3 seconds' | translate }}
      </ion-select-option>
      <ion-select-option value="10000">
        {{ 'After 10 seconds' | translate }}
      </ion-select-option>
      <ion-select-option value="60000">
        {{ 'After 60 seconds' | translate }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</div>