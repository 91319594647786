import { MapField, Message, Type, Field } from 'protobufjs/light';
import { RuntimeLayout } from './runtime-layout.model';
import { RuntimeLayoutControl } from './control/runtime-layout-control.model';
import { DictNumber, DictString } from '../dict.model';


@Type.d('RuntimeLayoutSolution')
export class RuntimeLayoutSolution extends Message<RuntimeLayoutSolution> {

  @Field.d(1, 'int64', 'required')
  currentObjectId: number;
  @MapField.d(2, 'int64', RuntimeLayoutControl)
  runtimeLayoutControls: DictNumber<RuntimeLayoutControl>;
  @MapField.d(3, 'string', RuntimeLayout)
  runtimeLayouts: DictString<RuntimeLayout>;
  @Field.d(4, 'int64', 'required')
  tick: number;

}
