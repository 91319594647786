import { Field, Message, Type } from 'protobufjs/light';
import { DeviceRunStatus } from '../../device-run-status.enum';

@Type.d('ClientAuthenticationReplyMessage')
export class ClientAuthReplyMessage extends Message<ClientAuthReplyMessage> {

  @Field.d(20, 'bool', 'optional', false)
  notAllowedClientVersion: boolean;
  @Field.d(21, 'double', 'optional')
  deviceDateTimeMillisecondsDiff: number;
  @Field.d(22, DeviceRunStatus, 'optional')
  status: DeviceRunStatus;

  @Field.d(50, 'string', 'optional')
  deviceId: string;
  @Field.d(51, 'bool', 'optional', false)
  readOnly: boolean;

}