<div class="popover-confirm-wrapper">
  <h2 class="title">
    {{ title }}
  </h2>

  <p class="text"
    [innerHTML]="text">
  </p>

  <br>

  <div *ngIf="noText && yesText"
    class="flex-row">
    <ion-button class="flex-1 ion-no-margin"
      (click)="close(false)"
      fill="clear">
      {{ noText }} {{ noTimeoutInSec ? ' (' + noTimeoutInSec + ')' : '' }}
    </ion-button>
    <ion-button class="flex-1 ion-no-margin"
      (click)="close(true)"
      fill="clear">
      {{ yesText }}
    </ion-button>
  </div>

  <ion-toolbar *ngIf="!noText && !yesText"
    class="ion-no-padding toolbar">
    <nav class="flex-row">
      <a class="flex-1 cancel"
        (click)="close(false)"
        href="javascript:void(0);">
        {{ 'No' | translate }}
      </a>
      <a class="flex-1 confirm"
        (click)="close(true)"
        href="javascript:void(0);">
        {{ 'Yes' | translate }}
      </a>
    </nav>
  </ion-toolbar>
</div>
