<div class="popover-grid-container">
  <h2>
    {{ 'Bluetooth' | translate }}
  </h2>

  <div>
    <button class="button"
      (click)="goTo('bluetooth-connect')">
      {{ 'Connect' | translate }}
    </button>

    <button class="button"
      (click)="openModal()">
      {{ 'List' | translate }}
    </button>
  </div>
</div>