import { MapField, Message, Type, Field } from 'protobufjs/light';
import { RuntimeLayoutEventSourceType } from './runtime-layout-event-source-type.enum';
import { RuntimeLayoutEventPlatformObjectType } from './runtime-layout-event-platform-object-type.enum';
import { RuntimeLayoutEventContext } from './runtime-layout-event-context.model';
import { DictNumber } from '../../dict.model';



@Type.d('RuntimeLayoutEvent')
export class RuntimeLayoutEvent extends Message<RuntimeLayoutEvent> {

  @Field.d(50, 'int64', 'required')
  snapshotTick: number;
  @Field.d(51, RuntimeLayoutEventSourceType, 'required')
  sourceType: RuntimeLayoutEventSourceType;
  @Field.d(52, 'int64', 'required')
  layoutObjectId: number;
  @Field.d(53, 'int64', 'optional')
  screenObjectId: number;
  @Field.d(54, 'int64', 'optional')
  screenObjectTick: number;
  @Field.d(55, 'int64', 'optional')
  controlObjectId: number;
  @Field.d(56, 'int64', 'optional')
  controlObjectTick: number;
  @Field.d(57, 'int64', 'optional')
  objectId: number;
  @Field.d(58, 'int64', 'optional')
  eventObjectId: number;
  @Field.d(59, RuntimeLayoutEventSourceType, 'required')
  platformObjectType: RuntimeLayoutEventPlatformObjectType;
  @Field.d(60, 'string', 'optional')
  platformObjectGuidId: string; // set equal to ActionGuidId when clicking on center button
  @Field.d(61, RuntimeLayoutEventContext, 'optional')
  eventContext: RuntimeLayoutEventContext; // to be used for barcode events
  @MapField.d(62, 'int64', RuntimeLayoutEventContext)
  controlContexts: DictNumber<RuntimeLayoutEventContext>;
  @Field.d(63, 'string', 'optional')
  eventDateTime: string;

}
