<div class="grid-container">
    <div class="control-scale1">
      <h2 *ngIf="layoutControl.parseRV('ControlHeadlineEnabled')" style="padding-left: 4px;">
        {{ layoutControl.parseRV('ControlHeadlineText') }}
      </h2>

      <div class="scale-value">
        {{ currentScaleData }}
      </div>

      <div class="scale">
        <img alt="scale"
          [src]="'assets/img/' + theme + '/scale.svg'" />
      </div>

    </div>
  </div>