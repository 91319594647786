import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LogUtils } from '../../utils';
import { OpsStatsService, OpsStatsType } from '../app/ops-stats.service';
import { StorageService } from '../app/storage.service';
import { BusyService } from '../busy/busy.service';
import { ApiResponse } from './api-response.model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private readonly storageKey = 'lc_apiUrl';

  private apiUrl: string;

  constructor(
    private busyService: BusyService,
    private http: HttpClient,
    private opsStatsService: OpsStatsService,
    private storageService: StorageService,
  ) {

  }

  init(): Observable<void> {
    return this.storageService.get(this.storageKey)
    .pipe(
      map((apiUrl: string) => {
        this.apiUrl = apiUrl || environment.apiUrl;
        return;
      })
    );
  }

  setApiUrl(apiUrl: string, dontStore?: boolean): void {
    this.apiUrl = apiUrl;

    if (dontStore) {
      this.storageService.remove(this.storageKey).subscribe();
    } else {
      this.storageService.set(this.storageKey, this.apiUrl).subscribe();
    }
  }

  get<T>(endpoint: string): Observable<T> {
    this.busyService.setBusy(true);
    const previousNow = performance.now();
    return this.http.get(this.apiUrl + endpoint)
    .pipe(
      map((response: ApiResponse<T>) => {
        this.opsStatsService.addValue(OpsStatsType.ApiRequestResponseTimeInMs, ~~(performance.now() - previousNow));
        return this.handleResponse(response);
      }),
      catchError((error: any) => {
        this.opsStatsService.addValue(OpsStatsType.ApiFailedRequestsCount, 1);
        return this.handleError(error);
      })
    );
  }

  getRaw(endpoint: string): Observable<any> {
    const previousNow = performance.now();
    return this.http.get(this.apiUrl + endpoint)
    .pipe(
      map((response: any) => {
        this.opsStatsService.addValue(OpsStatsType.ApiRequestResponseTimeInMs, ~~(performance.now() - previousNow));
        return response;
      }),
      catchError((error: any) => {
        this.opsStatsService.addValue(OpsStatsType.ApiFailedRequestsCount, 1);
        return this.handleError(error);
      })
    );
  }

  post<T>(endpoint: string, body: any, baseUrl?: string): Observable<T> {
    this.busyService.setBusy(true);

    baseUrl = baseUrl || this.apiUrl;
    const previousNow = performance.now();
    return this.http.post(baseUrl + endpoint, body)
    .pipe(
      map((response: ApiResponse<T>) => {
        this.opsStatsService.addValue(OpsStatsType.ApiRequestResponseTimeInMs, ~~(performance.now() - previousNow));
        return this.handleResponse(response);
      }),
      catchError((error: any) => {
        this.opsStatsService.addValue(OpsStatsType.ApiFailedRequestsCount, 1);
        return this.handleError(error);
      })
    );
  }

  private handleResponse<T>(response: ApiResponse<T>) {
    this.busyService.setBusy(false);
    if (response.success) {
      return response.value;
    } else {
      response.exceptionString = response.exceptionString || '';
        const errorMsg
          = response.exceptionString === ''
          || response.exceptionString.startsWith('LogicCenter.Diagnostics.LogException')
          || response.exceptionString.startsWith('System.Exception')
          ? 'Server Error' : response.exceptionString;
        LogUtils.error(response.exceptionString);
        throw new Error(errorMsg);
    }
  }

  private handleError(error: any): Observable<any> {
    this.busyService.setBusy(false);
    return throwError((error.json ? error.json().message : error.message) || 'Server error');
  }

}
