
export class ChecklistCheckValue {

  checklistCheckGuidId: string;
  value: string;
  description: string;

  constructor(item?: Partial<ChecklistCheckValue>) {
    Object.assign(this, item);
  }

}
