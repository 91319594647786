<div *ngIf="staticControl?.scannerEnabledType"
  class="barcode-drawer-wrapper"
  [class.open]="isDrawerOpen">
  <div class="background"
    (click)="toggleDrawer(false)">
  </div>

  <div class="barcode-drawer-content-shadow"></div>

  <div class="barcode-drawer-button"
    (click)="toggleDrawer()">
    <img #barcodeButton
      src="./assets/img/icon-barcode-header-button.png" />
  </div>

  <div class="barcode-drawer-content">
    <lc-control-input1
      class="flex-1"
      [disabled]="!isDrawerOpen"
      [layout]="layout"
      [staticControl]="staticControl"
      [isScannerEmulator]="true"
      (triggerEvent)="toggleDrawer(false)">
    </lc-control-input1>
  </div>
</div>
