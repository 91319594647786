import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DictNumber, EventObject, RuntimeLayoutDesign, RuntimeLayoutEventContext, RuntimeLayoutText, RuntimeLayoutScreen, RuntimeLayout, RuntimeLayoutSnapshot } from 'src/app/shared/models';
import { RuntimeLayoutSmartImage } from 'src/app/shared/models/runtime-layout/smart-image/runtime-layout-smart-image.model';
import { AppService } from 'src/app/shared/services';
import { ScreenBaseComponent } from '../base/screen-base.component';
import { ScreenControlContainerComponent } from '../control-container/screen-control-container.component';
import { Observable, of } from 'rxjs';
import { NotificationService, TranslateService } from 'src/app/shared/services/app';



@Component({
  selector: 'lc-screen-full1',
  templateUrl: 'screen-full1.component.html',
  styleUrls: ['./screen-full1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenFull1Component extends ScreenBaseComponent {

  @Input() layout: RuntimeLayout;
  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutSnapshot: RuntimeLayoutSnapshot;
  @Input() layoutTexts: DictNumber<RuntimeLayoutText>;
  @Input() smartImages: RuntimeLayoutSmartImage[];
  @Output() layoutScreenChange = new EventEmitter<RuntimeLayoutScreen>();
  @Output() triggerEvent = new EventEmitter<EventObject>();

  isConnectionToServerActive: boolean;

  constructor(
    appService: AppService,
    cdr: ChangeDetectorRef,
    notificationService: NotificationService,
    translateService: TranslateService,
  ) {
    super(appService, cdr, notificationService, translateService);

    this.subscriptions.push(
      this.appService.isConnectionToServerActive()
      .subscribe((isConnectionToServerActive: boolean) => {
        this.isConnectionToServerActive = isConnectionToServerActive;
        this.cdr.markForCheck();
      })
    );
  }

  getControlsContext(): DictNumber<RuntimeLayoutEventContext> {
    if (!this.controlComponents?.length) return {};

    const result: DictNumber<RuntimeLayoutEventContext> = {};

    this.controlComponents.forEach((controlComponent: ScreenControlContainerComponent) => {
      const controlContext = controlComponent.getControlContext();
      if (controlContext) {
        result[controlComponent.layoutControl.objectId] = new RuntimeLayoutEventContext({
          values: controlContext
        });
      }
    });

    return result;
  }

  onTriggerEvent(platformObject: EventObject) {
    this.triggerEvent.emit(platformObject);
  }

  backButtonOverride(): boolean {
    if (!this.controlComponents?.length) return false;

    const activeControlComponent = this.controlComponents?.last;
    return activeControlComponent?.backButtonOverride();
  }

  forwardButtonOverride(): boolean {
    if (!this.controlComponents?.length) return false;

    const activeControlComponent = this.controlComponents?.last;
    return activeControlComponent?.forwardButtonOverride();
  }

  preActionTrigger(): Observable<void> {
    if (!this.controlComponents?.length) return of(null);

    const activeControlComponent = this.controlComponents?.last;
    return activeControlComponent?.preActionTrigger();
  }

}

