<div class="screen flex-1 flex-col"
  [class.offline]="!isConnectionToServerActive">
  <div class="flex-1 flex-col"
    [style.max-height]="controls?.[0]?.length > 1 ? 'unset' : ''">
    <lc-screen-control-container *ngFor="let control of controls[0]; let i = index"
      [ngStyle]="{ 'marginLeft': ((i/2) + 'rem') }"
      [ngClass]="control.headDesignStyleObjectId ? 'head-control' : 'flex-1'"
      [layout]="layout"
      [layoutControl]="control"
      [layoutDesigns]="layoutDesigns"
      [(layoutScreen)]="layoutScreen"
      (layoutScreenChange)="layoutScreenChange.emit($event)"
      [layoutSnapshot]="layoutSnapshot"
      [layoutTexts]="layoutTexts"
      [smartImages]="smartImages"
      (triggerEvent)="onTriggerEvent($event)">
    </lc-screen-control-container>
  </div>
</div>
