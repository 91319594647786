import { NgModule } from '@angular/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlSog1Component } from './control-sog1.component';


@NgModule({
  declarations: [
    ControlSog1Component,
  ],
  exports: [
    ControlSog1Component,
  ],
  imports: [
    SharedModule,
    SignaturePadModule,
  ],
  providers: [],
})
export class ControlSog1Module { }
