import { NgModule } from '@angular/core';
import { PluckPipe } from './pluck.pipe';
import { TranslatePipe } from './translate.pipe';




@NgModule({
  declarations: [
    PluckPipe,
    TranslatePipe,
  ],
  exports: [
    PluckPipe,
    TranslatePipe,
  ],
  imports: [
  ],
})
export class SharedPipesModule { }
