
export class BrowserUtils {

  private static readonly HIGHEST_POSSIBLE_Z_INDEX = 2147483647;

  static getQueryParams(): any {
    return window.location.search.substr(1).split('&').reduce((q, query) => {
      const chunks = query.split('=');
      const key = chunks[0];
      const value = chunks[1];
      return (q[key] = value, q);
    }, {});
  }

  static isDeviceApp(): boolean {
    return window.location.href.indexOf('http://android') >= 0 ||
      window.location.href.indexOf('ionic://ios') >= 0 ||
      window.location.href.indexOf('ms-appx-web://') >= 0;
  }

  static isLocalhost(): boolean {
    return window.location.href.indexOf('localhost') >= 0 ||
      window.location.href.indexOf('127.0.0.1') >= 0 ||
      window.location.href.indexOf('192.168.0') >= 0;
  }

  static getChromeVersionString(): any {
    const cv = BrowserUtils.getChromeVersion();
    if (cv) {
      return `${cv.major}.${cv.minor}.${cv.build}.${cv.patch}`;
    } else {
      return 'N/A';
    }
  }

  static getChromeVersion(): any {
    let regExMatches = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/);
    if (regExMatches == null || regExMatches.length != 5) {
        return undefined;
    }
    const pieces = regExMatches.map(piece => parseInt(piece, 10));
    return {
        major: pieces[1],
        minor: pieces[2],
        build: pieces[3],
        patch: pieces[4]
    };
  }

  static getPicture (successCallback, errorCallback, options) {
    options = options || {};

    const quality = options.quality || 50;
    const destinationType = options.destinationType || 1;
    const sourceType = options.sourceType || 1;
    const targetWidth = options.targetWidth || -1;
    const targetHeight = options.targetHeight || -1;
    const encodingType = options.encodingType || 0;
    const mediaType = options.mediaType || 0;
    const allowEdit = !!options.allowEdit;
    const correctOrientation = !!options.correctOrientation;
    const saveToPhotoAlbum = !!options.saveToPhotoAlbum;
    const popoverOptions = options.popoverOptions || null;
    const cameraDirection = options.cameraDirection || 0;

    const args = [quality, destinationType, sourceType, targetWidth, targetHeight, encodingType,
        mediaType, allowEdit, correctOrientation, saveToPhotoAlbum, popoverOptions, cameraDirection];

    BrowserUtils.takePicture(successCallback, errorCallback, args)
  };

  private static takePicture(success, error, opts) {
    if (opts && opts[2] === 1) {
        BrowserUtils.capture(success, error, opts);
    } else {
        var input = document.createElement('input');
        input.style.position = 'relative';
        input.style.zIndex = BrowserUtils.HIGHEST_POSSIBLE_Z_INDEX.toString();
        input.className = 'camera-select';
        input.type = 'file';
        input.name = 'files[]';

        input.onchange = function (inputEvent) {
            var reader = new FileReader(); /* eslint no-undef : 0 */
            reader.onload = function (readerEvent) {
                input.parentNode.removeChild(input);

                var imageData = (readerEvent.target as any).result;

                return success(imageData.substr(imageData.indexOf(',') + 1));
            };

            reader.readAsDataURL((inputEvent.target as any).files[0]);
        };

        document.body.appendChild(input);
    }
  }

  private static capture(success, errorCallback, opts) {
    var localMediaStream;
    var targetWidth = opts[3];
    var targetHeight = opts[4];

    targetWidth = targetWidth === -1 ? 320 : targetWidth;
    targetHeight = targetHeight === -1 ? 240 : targetHeight;

    var video = document.createElement('video');
    var button = document.createElement('button');
    var parent = document.createElement('div');
    parent.style.position = 'relative';
    parent.style.zIndex = BrowserUtils.HIGHEST_POSSIBLE_Z_INDEX.toString();
    parent.className = 'camera-capture';
    parent.appendChild(video);
    parent.appendChild(button);

    video.width = targetWidth;
    video.height = targetHeight;
    button.innerHTML = 'Capture!';

    button.onclick = function () {
        // create a canvas and capture a frame from video stream
        var canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, targetWidth, targetHeight);

        // convert image stored in canvas to base64 encoded image
        var imageData = canvas.toDataURL('image/jpeg');
        imageData = imageData.replace('data:image/jpeg;base64,', '');

        // stop video stream, remove video and button.
        // Note that MediaStream.stop() is deprecated as of Chrome 47.
        if (localMediaStream.stop) {
            localMediaStream.stop();
        } else {
            localMediaStream.getTracks().forEach(function (track) {
                track.stop();
            });
        }
        parent.parentNode.removeChild(parent);

        return success(imageData);
    };

    (navigator as any).getUserMedia = (navigator as any).getUserMedia ||
                            (navigator as any).webkitGetUserMedia ||
                            (navigator as any).mozGetUserMedia ||
                            (navigator as any).msGetUserMedia;

    var successCallback = function (stream) {
        localMediaStream = stream;
        if ('srcObject' in video) {
            video.srcObject = localMediaStream;
        } else {
            (video as any).src = window.URL.createObjectURL(localMediaStream);
        }
        video.play();
        document.body.appendChild(parent);
    };

    if ((navigator as any).getUserMedia) {
      (navigator as any).getUserMedia({video: { facingMode: 'environment' }, audio: false}, successCallback, errorCallback);
    } else {
      alert('Browser does not support camera :(');
    }
  }

}
