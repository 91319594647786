
export class EnumUtils {

  static toSelectItemArray(enumerator: any, translateService?: any) {
    const result: { label: string, value: any }[] = [];
    for (const key in enumerator) {
      if (typeof enumerator[key] === 'number') {
        result.push({
          label: translateService ? translateService.instant(key.replace(/_/g, ' ')) : key.replace(/_/g, ' '),
          value: enumerator[key],
        });
      }
    }

    if (!result.length) {
      // if no results were returned from the above for loop, it means the enum value is a string...
      // so just return all
      for (const key in enumerator) {
        result.push({
          label: translateService ? translateService.instant(key.replace(/_/g, ' ')) : key.replace(/_/g, ' '),
          value: enumerator[key],
        });
      }
    }
    return result;
  }

  static toStringArray(enumerator: any) {
    const result: string[] = [];
    for (const key in enumerator) {
      if (typeof enumerator[key] === 'number') {
        result.push(enumerator[key]);
      }
    }

    if (!result.length) {
      // if no results were returned from the above for loop, it means the enum value is a string...
      // so just return all
      for (const key in enumerator) {
        result.push(enumerator[key]);
      }
    }
    return result;
  }

}
