import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { RuntimeLayoutControl } from 'src/app/shared/models';
import { RuntimeLayoutHead } from 'src/app/shared/models/runtime-layout/head';
import { LogUtils } from 'src/app/shared/utils';
import { DateTimeIndexUtils } from 'src/app/shared/utils/date-time-index.utils';
import { AppService } from '../../../../services';
import { BaseComponent } from '../../../base/base.component';

@Component({
  selector: 'lc-control-input1-datetime',
  templateUrl: 'control-input1-datetime.component.html',
  styleUrls: ['./control-input1-datetime.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlInput1DateTimeComponent extends BaseComponent implements OnInit {

  @Input() layoutControl: RuntimeLayoutControl | RuntimeLayoutHead;
  @Input() presentation: string;
  @Input() staticControl: any;
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  isoDate: string;
  isIsoFormat: boolean;

  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.isoDate = this.toIonicDate(this.value);
    this.valueChanged(this.isoDate); // nothing changed but we want to force the output value even if nothing changed and in case we have to change the format according to type (input -> output)
    // console.log(this.isoDate)
  }

  private fromIonicDate(ionicDate: string): string {
    try {
      const dateString = ionicDate?.length >= 10
      ? ionicDate.slice(0, 10)
      : format(new Date(), 'yyyy-MM-dd');
      const timeString = ionicDate?.indexOf(':') >= 2
      ? ionicDate.slice(ionicDate.indexOf(':') - 2, ionicDate.indexOf(':') + 3)
      : this.presentation === 'time' ? format(new Date(), 'HH:mm') : '00:00';
      switch (this.presentation) {
        case 'date':
          return dateString;
        case 'month-year':
          return dateString.substring(0, 7);
        case 'time':
          return timeString;
        case 'date-time':
        default:
          return `${dateString}${this.isIsoFormat ? 'T' : ' '}${timeString}${this.isIsoFormat ? ':00.000Z' : ''}`;
      }
    } catch (error) {
      LogUtils.warn('Failed to parse date string: ' + ionicDate);
      return ionicDate;
    }
  }

  private toIonicDate(value: string): string {
    if (!value) return undefined;

    try {
      let result;
      let parsedResult;
      // value can be anything: tick, dayIndex, timeIndex, dateString, timeString, dateTimeISO, dateTimeLocal
      const intValue = parseInt(value);
      // console.log('in', this.presentation, value, !/[-:TZ]/g.test(value) ? intValue : 'NaN');
      const dateString = value.length >= 10 ? value.slice(0, 10) : format(new Date(), 'yyyy-MM-dd');
      const timeString = value.indexOf(':') >= 2 ? value.slice(value.indexOf(':') - 2, value.indexOf(':') + 3) : format(new Date(), 'HH:mm');
      this.isIsoFormat = value.indexOf('T') >= 0 && value.indexOf('Z') >= 0;
      if (!isNaN(intValue) && !/[-:TZ]/g.test(value)) {
        if (intValue > 9999999) { // tick
          throw new Error();
        } else if (this.presentation === 'time' ) { // timeIndex...maybe
          result = DateTimeIndexUtils.decodeSecondsIndexToDate(new Date(), intValue).toISOString();
          parsedResult = parseISO(result).toISOString();
          if (result === parsedResult) return parsedResult;
          throw new Error();
        } else if (this.presentation === 'date' ) { // dateIndex...maybe
          result = DateTimeIndexUtils.decodeDayIndexToDate(intValue).toISOString();
          parsedResult = parseISO(result).toISOString();
          if (result === parsedResult) return parsedResult;
          throw new Error();
        } else {
          throw new Error();
        }
      }

      result = `${dateString}T${timeString}:00.000Z`;
      parsedResult = parseISO(result).toISOString();
      if (result === parsedResult) return parsedResult;
      throw new Error();
    } catch (error) {
      LogUtils.warn(`Failed to parse ${this.presentation} string: ${value}`);
      return undefined;
    }

    // case 'month-year':
    //   result = `${value}${format(new Date(), "-dd'T'HH:mm:ss.SSS'Z'")}`;
    //   parsedResult = parseISO(result).toISOString();
    //   if (result === parsedResult) return parsedResult;
    //   throw new Error();
  }

  valueChanged(ionicDate: string): void {
    this.value = this.fromIonicDate(ionicDate);
    // console.log('out', ionicDate, this.value);
    this.valueChange.emit(this.value);
  }

}

