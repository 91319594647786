import { NgModule } from '@angular/core';
import { GridsterModule } from 'angular-gridster2';
import { ListPipesModule } from 'src/app/shared/pipes/list/list-pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BarcodeScannerLivestreamOverlayModule } from '../../barcode-scanner/barcode-scanner-livestream-overlay/barcode-scanner-livestream-overlay.module';
import { ControlList1MapModule } from './control-list1-map/control-list1-map.module';
import { ControlList1Component } from './control-list1.component';


@NgModule({
  declarations: [
    ControlList1Component,
  ],
  exports: [
    ControlList1Component,
  ],
  imports: [
    SharedModule,

    BarcodeScannerLivestreamOverlayModule,
    ControlList1MapModule,
    GridsterModule,
    ListPipesModule,
  ],
  providers: [],
})
export class ControlList1Module { }
