import { v4 as uuidv4, validate, NIL } from 'uuid';

export class GuidUtils {

  static clean(guid: string) {
    return (guid || '').replace(/-/g, '')
  }

  static addDashes(guid: string) {
    if (guid?.length !== 32) return guid;

    return guid.substring(0, 8) + '-' + guid.substring(8, 12) + '-' + guid.substring(12, 16) + '-' + guid.substring(16, 20) + '-' + guid.substring(20, 32)
  }
  
  static emptyGuid() {
    return NIL;
  }
  
  static isNullOrEmpty(value: string) {
    return !value || value === NIL;
  }

  static isEqual(guid1: string, guid2: string) {
    return GuidUtils.clean(guid1) === GuidUtils.clean(guid2);
  }

  static isValid(guid: string) {
    return validate(guid);
  }

  static new() {
    return uuidv4();
  }

}