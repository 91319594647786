import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { Resource } from 'src/app/shared/models/resource.model';
import { RuntimeLayoutHead } from 'src/app/shared/models/runtime-layout/head';
import { DesignStyleJson } from 'src/app/shared/models/studio';
import { LayoutResourceService } from 'src/app/shared/services/protobuf/layout-resource.service';
import { LogUtils } from 'src/app/shared/utils';
import { BlobUtils } from 'src/app/shared/utils/blob.utils';
import { DictNumber, DictString, RuntimeLayoutData, RuntimeLayoutDesign, RuntimeLayoutDesignStyle, RuntimeLayoutText, RuntimeLayoutValue, RuntimeLayoutVariableValueType } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
import { GridsterConfig } from 'angular-gridster2';

@Component({
  selector: 'lc-control-visual1',
  templateUrl: 'control-visual1.component.html',
  styleUrls: ['./control-visual1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlVisual1Component extends ControlBaseComponent implements OnInit {

  readonly LABEL_GUID: string = '1af7e2dc159a4b01b346db0734a1f502';

  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutTexts: DictNumber<RuntimeLayoutText>;

  gridsterOptions: GridsterConfig;

  visualDefinition: any;
  visualData: RuntimeLayoutData[];

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
    private layoutResourceService: LayoutResourceService,
  ) {
    super(injector);

    this.gridsterOptions = {
      displayGrid: 'none',
      gridType: 'fit',
      margin: 0,
      mobileBreakpoint: 0,
    };
  }

  ngOnInit() {
    this.visualData = [];

    if (!this.layoutControl || !this.layoutScreen) return;

    // Get visual definition (for the GRID CSS)
    if (this.layoutControl instanceof RuntimeLayoutHead) {
      this.visualDefinition = this.getHeadDesignStyle(this.layoutControl.headDesignStyleObjectId);
      if (this.visualDefinition) {
        this.visualDefinition.style.minHeight = (this.convertRemToPixels(2) * (this.visualDefinition.screenRows || 1)) + 10 /*padding*/ + 'px';
      }
    } else {
      const designGuidId = this.layoutControl.parseRV('DesignGuidId');
      const designStyleGuidId = this.layoutControl.parseRV('DesignStyleGuidId');
      this.visualDefinition = this.getDesignStyle(designGuidId, designStyleGuidId);
    }

    this.populateVisualData();
  }

  private getHeadDesignStyle(headDesignStyleObjectId: number) {
    if (headDesignStyleObjectId) {
      // loop through all designs to find the desired designStyle
      for (const design of this.layoutDesigns || []) {
        const designStyle = design.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
          return lds.objectId === headDesignStyleObjectId;
        });
        if (designStyle && designStyle.styleJson) {
          return DesignStyleJson.parseDesignStyleJson(designStyle.styleJson);
        }
      }
    }

    return undefined;
  }

  private getDesignStyle(designGuidId: string, designStyleGuidId: string): any {
    if (!designGuidId || !designStyleGuidId) return undefined;

    const design = (this.layoutDesigns || []).find((ld: RuntimeLayoutDesign) => {
      return (ld.designOriginalGuidId || ld.designGuidId) === designGuidId.replace(/-/g, '');
    });
    if (!design) return undefined;

    const designStyle = design.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
      return (lds.designStyleOriginalGuidId || lds.designStyleGuidId) === designStyleGuidId.replace(/-/g, '');
    });
    if (!designStyle?.styleJson) return undefined;

    return DesignStyleJson.parseDesignStyleJson(designStyle.styleJson);
  }

  private populateVisualData(): void {
    if (!this.visualDefinition) return;

    for (const item of this.visualDefinition.items || []) {
      if (!item.field) continue;

      if (item.field.originalVariableGuidId) {
        for (const objectId of Object.keys(this.layoutScreen.variables)) {
          if (this.layoutScreen.variables[objectId].originalVariableGuidId !== item.field.originalVariableGuidId) continue;

          if (this.layoutScreen.variables[objectId].valueType === RuntimeLayoutVariableValueType.Value) {
            item.field.$value = this.layoutScreen.variables[objectId].value;
          } else {
            const data = this.layoutScreen.datas[this.layoutScreen.variables[objectId].value];
            if (data) {
              if (data.isResource) {
                item.field.$type = 'image';
                this.layoutResourceService.get(data.resourceGuidId, data.resourceTick)
                .pipe(
                  mergeMap((resource: Resource) => {
                    const blob: Blob = new Blob([resource.content], { type: resource.contentType });
                    return BlobUtils.blobToDataURL(blob);
                  })
                )
                .subscribe((dataUrl: string) => {
                  item.field.$value = dataUrl;
                  this.cdr.markForCheck();
                });
              } else {
                item.field.$value = data.parseRV(item.field.subVariableMemberGuidId.replace(/-/g, ''));
              }
            } else {
              item.field.$value = '- Missing Data -';
              LogUtils.warn(`populateVisualData() - layoutScreen.datas not found for ${this.layoutScreen.variables[objectId].value}...`);
            }
          }
        }
      } else if (item.field.textId) {
        if (this.layoutTexts[item.field.textId]) {
          item.field.$value = this.layoutTexts[item.field.textId].text;
        } else {
          item.field.$value = '- Missing Text -';
          LogUtils.warn(`populateVisualData() - layoutTexts not found for ${item.field.textId}...`);
        }
      } else {
        item.field.$value = item.field.staticValue;
      }

      item.field.$value = item.field.$value != null ? item.field.$value : (item.valueIfNull || '');
      item.field.$value = item.field.$value.toString().replace(/\[\[([^\]]+)\]\]/g, '<i class="$1"></i>'); // replace font awesome icons in the format [[fad fa-check]]

      if (!item.cellSyntax) continue;

      // lets try to parse and execute the cellSyntax...
      let cellSyntaxResult = item.cellSyntax.slice(0);

      cellSyntaxResult = cellSyntaxResult
      .replace(/{{value}}/g, '[[value]]') // regression since the placeholder brackets changed from the original {{value}} for consistency and not conflict with @if() { }...
      .replace(/\[\[value\]\]/g, item.field.$value) // replace actual value
      .replace(/"/g, '') // remove quotes
      .replace(/'/g, '') // remove quotes
      .replace(/\[\[([^\]]+)\]\]/g, '<i class="$1"></i>'); // replace font awesome icons in the format [[fad fa-check]]

      let ifMatches = null;
      let found = false;
      do {
        ifMatches = /@(if|else if)\s\(([^)]+)\)\s{([^}]+)}/gi.exec(cellSyntaxResult);
        if (!ifMatches) break;

        if (!found && ifMatches?.[2].trim() == item.field.$value) {
          cellSyntaxResult = cellSyntaxResult.replace(ifMatches[0], ifMatches[3]);

          found = true;
        }
        else cellSyntaxResult = cellSyntaxResult.replace(ifMatches[0], '');
      } while (ifMatches?.length);

      const elseMatch = /@else\s{([^}]+)}/gi.exec(cellSyntaxResult);
      if (elseMatch) {
        if (!found && elseMatch.length > 1) cellSyntaxResult = cellSyntaxResult.replace(elseMatch[0], elseMatch[1]);
        else cellSyntaxResult = cellSyntaxResult.replace(elseMatch[0], '');
      }

      item.field.$value = cellSyntaxResult.trim();
    }
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    return null;
  }

  private convertRowHeightPercentageToPixels(rowHeightPercentage: number | string) {
    const mainElement = document.querySelector('main');
    return (parseFloat(rowHeightPercentage.toString()) / 100) * parseFloat(getComputedStyle(mainElement).height);
  }

  private convertRemToPixels(rem: number | string) {
    return parseFloat(rem.toString()) * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

}

