import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DictNumber, DictString, LanguagesResponse, TextsResponse } from '../../models';
import { LogUtils } from '../../utils';
import { ApiService } from '../api/api.service';
import { StorageService } from '../app/storage.service';
const { version } = require('../../../../../package.json');



@Injectable({
  providedIn: 'root'
})
export class TextService {

  private readonly urlSuffixPlaceholder = 'solution/text/{what}';
  private readonly storageKeyTexts = 'lc_texts';
  private readonly storageKeyLanguageGuidId = 'lc_languageGuidId';
  private readonly storageKeySystemLanguageGuidId = 'lc_systemLanguageGuidId';
  private readonly storageKeyLanguageName = 'lc_languageName';

  set languageGuidId(value: string) {
    this._languageGuidId = value;

    this.storageService.set(this.storageKeyLanguageGuidId, this._languageGuidId).subscribe();
  }
  get languageGuidId(): string {
    return this._languageGuidId;
  }
  private _languageGuidId: string;

  platformTextIdMap: DictString<number>;

  set languageName(value: string) {
    this._languageName = value;

    this.storageService.set(this.storageKeyLanguageName, this._languageName).subscribe();
  }
  get languageName(): string {
    return this._languageName;
  }
  private _languageName: string;

  set texts(value: DictNumber<string>) {
    this._texts = value;

    this.storageService.set(this.storageKeyTexts, this.texts).subscribe();
  }
  get texts(): DictNumber<string> {
    return this._texts;
  }
  private _texts: DictNumber<string>;

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private storageService: StorageService,
  ) {
    this.platformTextIdMap = {};

    setTimeout(() => {
      this.storageService.get(this.storageKeyTexts)
      .subscribe((texts: DictNumber<string>) => {
        this.texts = texts || {};
      });
      this.storageService.get(this.storageKeyLanguageGuidId)
      .subscribe((languageGuidId: string) => {
        this._languageGuidId = languageGuidId;
      });
      this.storageService.get(this.storageKeyLanguageName)
      .subscribe((languageName: string) => {
        this._languageName = languageName;
      });

      this.httpClient.get(
        'assets/l10n/platform-text-map.json?r=' + version.toString(),
      ).subscribe((response: any) => {
        this.platformTextIdMap = response || {};
      }, (error: any) => {
        LogUtils.error('Failed to get platform-text-map.json file:', error);
      });
    });
  }

  clearLocalCache(): void {
    this.languageGuidId = undefined;
    this.texts = {};
  }

  listLanguages(enrollmentGuidId: string): Observable<LanguagesResponse> {
    return this.apiService.post<LanguagesResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'language/list'),
      {
        enrollmentGuidId: enrollmentGuidId,
      }
    );
  }

  get(enrollmentGuidId: string, languageGuidId: string): Observable<TextsResponse> {
    return this.apiService.post<TextsResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
      {
        enrollmentGuidId: enrollmentGuidId,
        languageGuidId: languageGuidId,
      }
    ).pipe(
      map((tr: TextsResponse) => {
        return this.handleTextsResponse(tr);
      })
    );
  }

  setLanguage(enrollmentGuidId: string, languageGuidId: string, languageName: string): Observable<TextsResponse> {
    this.languageName = languageName;

    return this.apiService.post<TextsResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'language/set'),
      {
        enrollmentGuidId: enrollmentGuidId,
        languageGuidId: languageGuidId,
      }
    ).pipe(
      map((tr: TextsResponse) => {
        return this.handleTextsResponse(tr);
      })
    );
  }

  private handleTextsResponse(tr: TextsResponse) {
    if (tr?.platformTexts) {
      this.languageGuidId = tr.platformTexts.languageGuidId;
      this.texts = tr.platformTexts.texts;
    } else {
      this.languageGuidId = undefined;
      this.texts = {};
    }

    return tr;
  }

  getTranslation(text: string): Observable<string> {
    if (this.texts) return of(this.getTextOrDefault(text));

    return this.storageService.get(this.storageKeyTexts)
    .pipe(
      map((texts: DictNumber<string>) => {
        this.texts = texts || {};

        return this.getTextOrDefault(text);
      })
    );
  }

  instantTranslation(text: string): string {
    return this.getTextOrDefault(text);
  }

  private getTextOrDefault(text: string): string {
    const textId = this.platformTextIdMap[text];
    if (this.texts && this.texts[textId]) {
      return this.texts[textId];
    } else {
      return text;
    }
  }

}
