import { Field, MapField, Message, Type } from 'protobufjs/light';
import { DictNumber, DictString } from '../../dict.model';
import { RuntimeLayoutControl } from '../control/runtime-layout-control.model';
import { RuntimeLayoutData } from '../data/runtime-layout-data.model';
import { RuntimeLayoutSet } from '../data/runtime-layout-set.model';
import { RuntimeLayoutHead } from '../head';
import { RuntimeLayoutLocalEvent } from '../local-event/runtime-layout-local-event.model';
import { RuntimeLayoutNotify } from '../local-event/runtime-layout-notify.model';
import { RuntimeLayoutObjectPointer } from '../runtime-layout-object-pointer.model';
import { RuntimeLayoutValue } from '../runtime-layout-value.model';
import { RuntimeLayoutVariable } from '../runtime-layout-variable.model';
import { RuntimeLayoutScreenCode } from './runtime-layout-screen-codes.type';
import { RuntimeLayoutScreenControlCollection } from './runtime-layout-screen-control-collection.model';

export enum RuntimeLayoutScreenFlowSolutionType {

  Logon = 1,
  Logoff = 2,
  Menu = 3,
  Normal = 4,
  Trigger = 5,

}

export enum RuntimeLayoutScreenAttentionType {

  Unknown = 0,

}

@Type.d('RuntimeLayoutScreen')
export class RuntimeLayoutScreen extends Message<RuntimeLayoutScreen> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(50, 'string', 'optional')
  solutionLayoutScreenGuidId: string;
  @Field.d(51, 'string', 'optional')
  layoutScreenGuidId: string;
  @Field.d(52, 'int64', 'repeated')
  runtimeFlowObjectIds: number[];
  @Field.d(53, 'string', 'optional')
  layoutScreenCode: RuntimeLayoutScreenCode;
  @MapField.d(54, 'string', RuntimeLayoutValue)
  renderValues: DictString<RuntimeLayoutValue>;
  @Field.d(55, 'bool', 'optional', false)
  backButton: boolean;
  @Field.d(56, 'bool', 'optional', false)
  actionButton: boolean;
  @Field.d(57, 'bool', 'optional', false)
  forwardButton: boolean;
  @Field.d(58, 'int32', 'optional', false)
  scannerEnabled: boolean;
  @Field.d(60, 'int64', 'optional')
  primaryLayoutControlObjectId: number;
  @MapField.d(61, 'string', RuntimeLayoutScreenControlCollection)
  screenControlCollections: DictString<RuntimeLayoutScreenControlCollection>;
  @MapField.d(62, 'int64', RuntimeLayoutControl)
  controls: DictNumber<RuntimeLayoutControl>;
  @Field.d(63, RuntimeLayoutObjectPointer, 'repeated')
  removedControls: RuntimeLayoutObjectPointer[];
  @Field.d(64, 'int64', 'optional')
  controlTick: number;
  @MapField.d(65, 'int64', RuntimeLayoutHead)
  heads: DictNumber<RuntimeLayoutHead>;
  @Field.d(66, RuntimeLayoutObjectPointer, 'repeated')
  removedHeads: RuntimeLayoutObjectPointer[];
  @MapField.d(70, 'int64', RuntimeLayoutSet)
  sets: DictNumber<RuntimeLayoutSet>;
  @Field.d(71, RuntimeLayoutObjectPointer, 'repeated')
  removedSets: RuntimeLayoutObjectPointer[];
  @MapField.d(72, 'int64', RuntimeLayoutData)
  datas: DictNumber<RuntimeLayoutData>;
  @Field.d(73, RuntimeLayoutObjectPointer, 'repeated')
  removedDatas: RuntimeLayoutObjectPointer[];
  @MapField.d(80, 'int64', RuntimeLayoutVariable)
  variables: DictNumber<RuntimeLayoutVariable>;

  @Field.d(150, RuntimeLayoutLocalEvent, 'repeated')
  localEvents: RuntimeLayoutLocalEvent[];
  @MapField.d(151, 'int64', RuntimeLayoutNotify)
  notifys: DictNumber<RuntimeLayoutNotify>;

  @Field.d(200, 'int32', 'optional')
  flowSolutionType: RuntimeLayoutScreenFlowSolutionType;
  @Field.d(210, 'int32', 'optional')
  attentionType: RuntimeLayoutScreenAttentionType;



  parseRV(key: string, defaultValue?: any): any {
    let value = null;
    if (this.renderValues && this.renderValues[key]) {
      value = this.renderValues[key].parse();
    }
    return (value !== null && value !== undefined) ? value : defaultValue;
  }

}
