<div [id]="id || 'keyboard'"
  class="keyboard-{{theme}} align-{{align}}"
  [class.uppercase]="uppercaseOnly"
  [style.width]="width">
  <ng-content select="ion-toolbar"></ng-content>

  <div *ngFor="let row of keys?.[activeLanguage]?.[activeKeySet] || []"
    class="keyboard-row"
    [style.zoom]="zoom">
    <div *ngFor="let key of row"
      class="keyboard-key-wrapper"
      [ngClass]="(key.value || key) === ' ' ? 'keyboard-key-space' : ''">
      <div class="keyboard-key"
        (click)="btnClick($event, key && key.value ? key.value : key)">
        <i *ngIf="key && key.icon" [class]="key.icon" style="width: 100%;"></i>
        <div *ngIf="!key || !key.icon"
          class="keyboard-key-char"
          [ngClass]="(key.label || key).length > 1 ? 'keyboard-key-special' : ''">
          {{ (key.label || key) }}
        </div>
      </div>
    </div>
  </div>

</div>
