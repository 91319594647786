import { Injectable } from '@angular/core';
import { BackgroundGeolocation, BackgroundGeolocationAuthorizationStatus, BackgroundGeolocationConfig, BackgroundGeolocationEvents, BackgroundGeolocationLocationProvider, BackgroundGeolocationResponse } from '@awesome-cordova-plugins/background-geolocation/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DeviceEnrollment, Notification, RuntimeLayoutNotifyType } from '../../models';
import { GeoJSON } from '../../models/geojson.model';
import { BrowserUtils, LogUtils } from '../../utils';
import { EnrollService } from '../api';
import { NotificationService } from './notification.service';
import { StorageService } from './storage.service';
import { TranslateService } from './translate.service';

enum BackgroundGeolocationAccuracy {
  HIGH = 0,
  MEDIUM = 10,
  LOW = 100,
  PASSIVE = 1000
}

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  private readonly defaultRetryTimeoutInMs = 10 * 1000;
  private readonly storageKey = 'lc_androidDisclosure';

  private alertSubscription: Subscription;
  private lastErrorMessage: string;
  private lastKnownPosition: GeoJSON;
  noAccess: boolean;
  private numOfRetries: number;
  private positionSubject = new BehaviorSubject<GeoJSON>(null);
  private stopAfterGpsFix: boolean;
  private watchId: number;
  private watchSubscription: Subscription;

  constructor(
    private backgroundGeolocation: BackgroundGeolocation,
    private enrollService: EnrollService,
    private notificationService: NotificationService,
    private platform: Platform,
    private storageService: StorageService,
    private translateService: TranslateService,
  ) {
    this.numOfRetries = 0;
  }

  getLastKnownPosition(): GeoJSON {
    return this.lastKnownPosition ? new GeoJSON(this.lastKnownPosition) : undefined;
  }

  getErrorMessage(): string {
    return this.lastErrorMessage;
  }

  watchPosition(): Observable<GeoJSON> {
    return this.positionSubject as Observable<GeoJSON>;
  }

  start(stopAfterGpsFix?: boolean): void {
    this.stopAfterGpsFix = stopAfterGpsFix;

    if (!BrowserUtils.isDeviceApp() && !navigator.geolocation) {
      this.noAccess = true;
      this.notificationService.showAlert(
        'GPS Location Error',
        'This browser / device does not support geolocation tracking',
      ).subscribe();
      return;
    }

    if (this.numOfRetries > 3) {
      this.noAccess = true;
      this.alertSubscription = this.alertSubscription || this.notificationService.showAlert(
        'GPS Location Error',
        `Failed to get this device's location in the background.<br><br>
Check the device location permission, make sure you are outside with a clear view of the sky and try restarting the device if nothing else works.<br><br>
${'<b>Error:</b> ' + (this.lastErrorMessage || 'No extra info...')}`,
      ).subscribe();
      return;
    }

    if (!BrowserUtils.isDeviceApp()) {
      this.startHtmlTracking();
      return;
    }

    if (!this.platform.is('android')) {
      this.startDeviceTracking();
      return;
    }

    this.storageService.get(this.storageKey)
    .subscribe((androidDisclosure: boolean) => {
      if (androidDisclosure) {
        this.startDeviceTracking();
        return;
      }

      this.enrollService.getLocalEnrollment()
      .subscribe((de: DeviceEnrollment) => {
        this.alertSubscription = this.alertSubscription || this.notificationService.showAlert(
          this.translateService.instant('Location Access'),
`${this.translateService.instant('LogicCenter collects location data according to the configuration for')} "${de.deviceSolutionName}".<br>
${this.translateService.instant('This may be used to capture the device\'s location when an issue is found on the field, a service order is completed, or to track progress, during the day\'s work route, while driving for example.')}<br>
${this.translateService.instant('It may do so, even when the app is in the background / not in use.')}`,
        ).subscribe(() => {
          this.storageService.set(this.storageKey, true);
          this.startDeviceTracking();
        })
      });
    });
  }

  private startDeviceTracking() {
    if (this.watchSubscription && !this.watchSubscription.closed) return;

    LogUtils.log('Starting background geolocation service...');
    const config: BackgroundGeolocationConfig = {
      debug: false, //  enable this hear sounds for background-geolocation life-cycle.
      desiredAccuracy: BackgroundGeolocationAccuracy.HIGH,
      distanceFilter: 20,
      fastestInterval: 5000,
      interval: 5000,
      locationProvider: BackgroundGeolocationLocationProvider.DISTANCE_FILTER_PROVIDER,
      maxLocations: 1000,
      notificationTitle: this.translateService.instant('Device location'),
      notificationText: this.translateService.instant('Background tracking is active'),
      saveBatteryOnBackground: true, // this is not ideal but was needed to pass iOS store review
      startForeground: true, // this needs to be true, otherwise, no tracking occurr while the app is active
      stationaryRadius: 20,
      stopOnTerminate: true, // enable this to clear background location settings when the app terminates
    };
    this.backgroundGeolocation.configure(config)
    .then(() => {
      this.watchSubscription = this.backgroundGeolocation.on(BackgroundGeolocationEvents.location)
      .subscribe((location: BackgroundGeolocationResponse) => {
        this.backgroundGeolocation.startTask()
        .then((taskKey: number) => {
          this.numOfRetries = 0;
          this.lastErrorMessage = undefined;
          this.lastKnownPosition = GeoJSON.fromBackgroundGeolocationResponse(location);
          this.lastKnownPosition.properties.deviceDateTime = (new Date()).toISOString();
          this.lastKnownPosition.properties.provider = location.provider;

          this.positionSubject.next(this.lastKnownPosition);

          if (this.stopAfterGpsFix) {
            this.stop();
          }

          this.backgroundGeolocation.endTask(taskKey);
        });
      }, (error: any) => {
        this.stop();

        this.showErrorAndScheduleRestart(error?.message || error);
      });

      this.backgroundGeolocation.on(BackgroundGeolocationEvents.authorization)
      .subscribe((status: any) => {
        LogUtils.log('BackgroundGeolocation authorization status: ' + status);
        if (status !== BackgroundGeolocationAuthorizationStatus.NOT_AUTHORIZED) {
          this.noAccess = false;
          return;
        }

        this.noAccess = true;
        // we need to set delay or otherwise alert may not be shown
        setTimeout(() => {
          this.notificationService.showConfirm(
            this.translateService.instant('Permissions'),
            this.translateService.instant('LogicCenter would like to be able to access this device\'s location. Would you like to open app settings?'),
            this.translateService.instant('Yes'),
            this.translateService.instant('No'),
          ).pipe(
            mergeMap((confirm: HTMLIonAlertElement) => {
              return (confirm as any).$result;
            })
          ).subscribe((result: boolean) => {
            if (!result) return;
            return this.backgroundGeolocation.showAppSettings();
          });
        }, 100);
      });

      // start recording location
      this.backgroundGeolocation.start();
    });
  }

  private startHtmlTracking() {
    if (this.watchId) return;

    LogUtils.log('Starting background geolocation service...');
    this.watchId = navigator.geolocation.watchPosition(
      (position: any) => {
        this.numOfRetries = 0;
        this.lastErrorMessage = undefined;
        this.lastKnownPosition = GeoJSON.fromGeoposition(position);
        this.lastKnownPosition.properties.deviceDateTime = (new Date()).toISOString();
        this.lastKnownPosition.properties.provider = 'gps';

        this.positionSubject.next(this.lastKnownPosition);

        if (this.stopAfterGpsFix) {
          this.stop();
        }
      }, (error: any) => {
        this.stop();

        this.showErrorAndScheduleRestart(error?.message || error);
      }
    );
  }

  stop(onlyIfNoOneIsWatching?: boolean): void {
    if (onlyIfNoOneIsWatching && this.positionSubject?.observers?.length) return;

    if (BrowserUtils.isDeviceApp() && this.watchSubscription && !this.watchSubscription.closed) {
      LogUtils.log('Stopping background geolocation service...');
      this.backgroundGeolocation.stop();
      this.backgroundGeolocation.removeAllListeners();

      this.watchSubscription.unsubscribe();
      this.watchSubscription = null;
    } else if (this.watchId) {
      LogUtils.log('Stopping background geolocation service...');
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = null;
    }
  }

  private showErrorAndScheduleRestart(error: string) {
    this.lastErrorMessage = error;

    if ((this.lastErrorMessage || '').toLowerCase().indexOf('user denied') >= 0) {
      this.noAccess = true;
      LogUtils.warn(this.lastErrorMessage);
    }

    this.notificationService.showNotification(new Notification({
      title: this.translateService.instant('GPS Location'),
      text: this.lastErrorMessage || '-',
      type: RuntimeLayoutNotifyType.Alert,
    }));

    setTimeout(() => {
      this.numOfRetries++;
      this.start(this.stopAfterGpsFix);
    }, this.defaultRetryTimeoutInMs);
  }

}
