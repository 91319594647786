export enum RuntimeLayoutControlCode {
  CameraControl1 = 'CameraControl1',
  ChecklistControl1 = 'ChecklistControl',
  DimensionControl1 = 'DimensionControl1',
  ExternalControl1 = 'ExternalControl1',
  InputControl1 = 'InputControl1',
  ListControl1 = 'ListControl1',
  LocationControl1 = 'LocationControl1',
  MenuControl1 = 'MenuControl1',
  PlatformMainMenu1 = 'PlatformMainMenu1',
  PrintControl1 = 'PrintControl1',
  QuantityList1 = 'QuantityList1',
  RequestClientSetting1 = 'RequestClientSetting1',
  RfidInventory = 'Rfid1Inventory',
  RfidLocate = 'Rfid1Locate',
  RfidScan = 'Rfid1Scan',
  SmartImageControl1 = 'SmartImageControl1',
  SogControl1 = 'SoGControl1',
  SwishQrControl1 = 'SwishQr1',
  VisualControl1 = 'VisualControl1',
  ScaleControl1 = 'ScaleControl1',
  Temperature1 = 'Temperature1',
  UpdateESL1 = 'UpdateESL1',
}
