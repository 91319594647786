export enum LayoutMessageType {
  Ping = 1,

  CriticalError = 10,

  ClientAuthentication = 20,
  SolutionChange = 21,
  ChangeEnrollment = 22,
  SolutionInfo = 23,

  Snapshot = 30,
  SnapshotRequest = 31,
  Load = 32,
  ResourceRequest = 33,
  ResourceResponse = 34,
  ResourceUploadRequest = 35,
  ResourceUploadResponse = 36,

  LayoutEvent = 50,
  SetSetting = 51,
  LayoutChangeActiveScreen = 52,
  InLayoutEvent = 53,

  DebugLog = 60,
  DebugMail = 61,

  BluetoothList = 70,

  LocationPoints = 80,

}
