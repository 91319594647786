import { ChecklistCheckValue } from './checklist-check-value.model';

export enum ChecklistCheckAnswerType {
  NotDefined = 0,
  YesNo = 1,
  SingleValue = 2,
  MultiValues = 3,
  NumberValue = 4,
}

export class ChecklistCheck {

  guidId: string;
  name: string;
  description: string;
  required: boolean;
  answerType: number;
  defaultValue: any;
  // supportDefaultValue: boolean;
  allowFreeText: boolean;
  allowPicture: boolean;
  checkValues: ChecklistCheckValue[];

  allowNotApplicable: boolean; // this is missing from the center type

  $answeredDateTime: string;
  $collapsed: boolean;
  $freeText: string;
  $pictureResourceGuidId: string;
  $pictureB64: string;
  $checkResult: ChecklistCheckValue[];

  constructor(item?: Partial<ChecklistCheck>) {
    Object.assign(this, item);
  }

}
