<div class="height-100 flex-col gap-1 center middle">
  <h2 *ngIf="staticControl?.controlHeadlineEnabled || layoutControl?.parseRV('ControlHeadlineEnabled')">
    {{ staticControl?.controlHeadlineText || layoutControl?.parseRV('ControlHeadlineText') }}
  </h2>

  <div class="flex-row center">
    <img class="image"
      alt="dimensions example"
      src="assets/img/dimensions.gif"/>
  </div>

  <lc-control-input1 #controlWidthComponent
    [disabled]="controlWidthComponent?.inputRef?.nativeElement !== thisDocument.activeElement"
    [staticControl]="staticControlWidth"
    (triggerEvent)="tryParseDimensionCipherlabScan(controlWidthComponent)">
  </lc-control-input1>

  <lc-control-input1 #controlHeightComponent
    [disabled]="controlHeightComponent?.inputRef?.nativeElement !== thisDocument.activeElement"
    [staticControl]="staticControlHeight"
    (triggerEvent)="tryParseDimensionCipherlabScan(controlHeightComponent)">
  </lc-control-input1>

  <lc-control-input1 #controlLengthComponent
    [disabled]="controlLengthComponent?.inputRef?.nativeElement !== thisDocument.activeElement"
    [staticControl]="staticControlLength"
    (triggerEvent)="tryParseDimensionCipherlabScan(controlLengthComponent)">
  </lc-control-input1>
</div>