import { MapField, Message, Type } from 'protobufjs/light';
import { DictString } from '../../dict.model';
import { RuntimeLayoutValue } from '../runtime-layout-value.model';



@Type.d('RuntimeLayoutEventContext')
export class RuntimeLayoutEventContext extends Message<RuntimeLayoutEventContext> {

  @MapField.d(50, 'string', RuntimeLayoutValue)
  values: DictString<RuntimeLayoutValue>;



  parseRV(key: string, defaultValue?: any): any {
    let value = null;
    if (this.values && this.values[key]) {
      value = this.values[key].parse();
    }
    return (value !== null && value !== undefined) ? value : defaultValue;
  }

}
