import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlCamera1Component } from './control-camera1.component';


@NgModule({
  declarations: [
    ControlCamera1Component,
  ],
  exports: [
    ControlCamera1Component,
  ],
  imports: [
    SharedModule,

  ],
  providers: [],
})
export class ControlCamera1Module { }
