import { Message, Type, Field } from 'protobufjs/light';

@Type.d('CriticalErrorMessage')
export class CriticalErrorMessage extends Message<CriticalErrorMessage> {

  @Field.d(20, 'string', 'required')
  errorMessage: string;
  @Field.d(21, 'bool', 'optional')
  causeDisconnect: boolean;

}