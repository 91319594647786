<div class="height-100 flex-col middle">
  <h2 *ngIf="!isManual && layoutControl.parseRV('ControlHeadlineEnabled')"
    style="padding-left: 4px;">
    {{ layoutControl.parseRV('ControlHeadlineText') }}
  </h2>

  <div *ngIf="!isManual"
    class="value {{ theme }}">
    {{
      currentTempData?.value != null
      ? (currentTempData.value | number:'1.1-2') + 'ºC'
      : 'N/A'
    }}
  </div>

  <div *ngIf="!isManual"
    class="icon {{ theme }}">
    <i class="fad fa-temperature-half"
      (click)="start()">
    </i>
  </div>

  <lc-control-input1 *ngIf="isManual"
    #controlManualComponent
    [layout]="layout"
    [staticControl]="staticControlManual">
  </lc-control-input1>

  <a *ngIf="allowManual"
    class="link"
    href="javascript:void(0);"
    (click)="toggleManual()">
    {{ isManual ? ('Read bluetooth device' | translate) : ('Enter manual measurement' | translate) }}
  </a>
</div>