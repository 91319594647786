import { Injectable } from '@angular/core';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';
import { LayoutCoreMessage, LayoutMessageType } from '../../models';
import { Resource } from '../../models/resource.model';
import { RuntimeLayoutResourceRequest } from '../../models/runtime-layout/resource/runtime-layout-resource-request.model';
import { RuntimeLayoutResourceResponse } from '../../models/runtime-layout/resource/runtime-layout-resource-response.model';
import { LogUtils } from '../../utils';
import { HeartbeatService } from '../app/heartbeat.service';
import { StorageService } from '../app/storage.service';
import { BusyService } from '../busy/busy.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';


@Injectable({
  providedIn: 'root'
})
export class LayoutResourceService {

  private readonly chunkSize = 1024 * 100;
  private readonly storageKeyResources = 'lc_resources';
  private readonly timeoutInMs = 5 * 1000;


  constructor(
    private busyService: BusyService,
    private heartbeatService: HeartbeatService,
    private storageService: StorageService,
    private webSocketClientService: WebSocketClientService
  ) { }

  get(
    resourceGuidId: string,
    resourceTick: number,
    chunkId = 0,
    content?: Uint8Array,
  ): Observable<Resource> {
    return this.storageService.get(this.storageKeyResources + '_' + resourceGuidId)
    .pipe(
      mergeMap((resource: any) => {
        if (resource && resource.tick >= resourceTick) {
          return of(new Resource(resource));
        } else {
          return new Observable((observer: Observer<any>) => {
            // this.busyService.setBusy(true, 'Requesting resource...');

            const msgContent = new RuntimeLayoutResourceRequest({
              guidId: resourceGuidId,
              tick: resourceTick,
              chunkSize: this.chunkSize,
              chunkId: chunkId,
              useChunks: true,
            });
            LogUtils.log('Requesting resource:', msgContent);
            const msgContentBuffer = RuntimeLayoutResourceRequest.encode(msgContent).finish();

            const coreMsg = new LayoutCoreMessage({
              messageSequenceNr: this.webSocketClientService.getSequenceNumber(),
              messageType: LayoutMessageType.ResourceRequest,
              messageContent: msgContentBuffer,
            });
            const coreMsgBuffer  = LayoutCoreMessage.encode(coreMsg).finish();

            const subscription = this.webSocketClientService
            .getMessages$(coreMsg.messageSequenceNr)
            .pipe(delay(10))
            .subscribe((msg: LayoutCoreMessage) => {
              content = content || new Uint8Array();
              this.handleIncomingMessage(msg, observer, subscription, content, chunkId+1);
            }, (error: any) => {
              this.handleError(subscription, observer, error);
            });

            this.heartbeatService.scheduleNextHeartbeat(this.timeoutInMs);
            this.webSocketClientService.send(coreMsgBuffer);
          });
        }
      })
    );
  }

  private handleIncomingMessage(
    msg: LayoutCoreMessage,
    observer: Observer<any>,
    subscription: Subscription,
    content: Uint8Array,
    chunkId: number,
  ) {
    if (!subscription || subscription.closed) return;

    if (!msg) {
      this.busyService.setBusy(false);
      observer.next(null);
      observer.complete();

      subscription.unsubscribe();
      subscription = null;
      return;
    }

    const response: RuntimeLayoutResourceResponse = RuntimeLayoutResourceResponse.decode(msg.messageContent);
    if (response?.success) {
      content = this.concatBuffers(content, response.chunk);
      if (content.length >= response.totalSize) {
        this.busyService.setBusy(false);

        const resource = new Resource({
          guidId: response.guidId,
          content: content,
          contentType: response.contentType,
          tick: response.tick,
        });
        this.storageService.get(this.storageKeyResources)
        .pipe(
          mergeMap((resourceGuidIds: string[]) => {
            resourceGuidIds = resourceGuidIds || [];
            if (resourceGuidIds.indexOf(resource.guidId) < 0) {
              resourceGuidIds.push(resource.guidId);
            }

            return this.storageService.set(this.storageKeyResources, resourceGuidIds);
          }),
          mergeMap(() => {
            return this.storageService.set(this.storageKeyResources + '_' + resource.guidId, resource);
          })
        )
        .subscribe(() => {
          observer.next(resource);
          observer.complete();
        });
      } else {
        this.get(response.guidId, response.tick, chunkId, content)
        .subscribe((resource: Resource) => {
          observer.next(resource);
          observer.complete();
        });
      }
    } else {
      observer.next(null);
      observer.complete();
    }

    subscription.unsubscribe();
    subscription = null;
  }

  private handleError(subscription: Subscription, observer: Observer<any>, error: any) {
    if (!subscription || subscription.closed) return;

    this.busyService.setBusy(false);

    subscription.unsubscribe();
    subscription = null;

    observer.error(error);
  }

  private concatBuffers(a, b) {
    return this.concatTypedArrays(
        new Uint8Array(a.buffer || a),
        new Uint8Array(b.buffer || b)
    );
  }

  private concatTypedArrays(a, b) { // a, b TypedArray of same type
    var c = new (a.constructor)(a.length + b.length);
    c.set(a, 0);
    c.set(b, a.length);
    return c;
  }

}
