<!-- having listDefinition.style?.width set + listDefinition.style?.display == 'inline-grid' is what distinguishes between list / grid -->
<ion-content *ngIf="listDefinition"
  class="quantity-list-control"
  [style.opacity]="listReady ? 1 : 0"
  shadowCss
  shadowCustomCss=".inner-scroll.scroll-y { scroll-padding-top: 4px }">

  <div class="wrapper">
    <h2 *ngIf="layoutControl.parseRV('ControlHeadlineEnabled')" style="padding-left: 4px;">
      {{ layoutControl.parseRV('ControlHeadlineText') }}
    </h2>

    <div class="flex-1 {{ listDefinition.style?.width ? 'flex-row wrap' : 'flex-col gridster-gap' }}">
      <gridster *ngIf="headerDefinition?.items?.length"
        class="gridster background"
        [ngStyle]="listDefinition.header?.style"
        [style.maxHeight]="listDefinition.header?.style?.width ? listDefinition.header?.style?.height || listDefinition.header?.style?.minHeight : undefined"
        [style.minHeight]="listDefinition.header?.style?.minHeight ? getMinRowHeight(listDefinition.header?.style?.minHeight, '3.5rem') : ''"
        [style.flex]="!listDefinition.header?.style?.width ? 0 : undefined"
        [options]="gridsterOptions1">
        <gridster-item *ngFor="let item of headerDefinition?.items || []; let i = index;"
          class="gridster-item pointer"
          [ngStyle]="item.itemStyle"
          [hidden]="!listData[0]"
          [item]="item">
          <span *ngIf="item.field && (item.field.staticValue || item.field.textId || item.field.originalVariableGuidId)"
            class="label"
            [ngStyle]="item.labelStyle">{{
              item.field.staticValue ? item.field.staticValue :
              item.field.textId ? layoutTexts[item.field.textId]?.text :
              listData[0] ? listData[0].parseRV(item.field.subVariableMemberGuidId || item.field.originalVariableGuidId) :
              ''
            }}
          </span>
        </gridster-item>
      </gridster>

      <div *ngFor="let repeat of getNgForArray(listData.length / listDefinition.rows); let i = index;"
        style="position: relative;"
        [style.margin]="listData[(i * listDefinition.rows)]?.$uiGrouping ? 'unset' : listData[0]?.$uiGrouping ? '0 3rem 0 3.5rem' : '0 3rem'">
        <gridster class="gridster gridSize"
          [ngStyle]="listDefinition.style"
          [style.flex]="!listDefinition.style?.width ? 0 : undefined"
          [style.maxHeight]="listDefinition.style?.width ? listDefinition.style?.height || listDefinition.style?.minHeight : undefined"
          [style.minHeight]="listData[(i * listDefinition.rows)]?.$uiGrouping ? ('calc(' + (rowMinHeight || 0) + ' / 2)') : rowMinHeight"
          [style.maxWidth]="listDefinition.style?.width ? 'calc(' + listDefinition.style.width + ' - 1px)' : ''"
          [options]="gridsterOptions1">
          <gridster-item *ngFor="let item of listDefinition?.items || []; let j = index;"
            [id]="listData[(i * listDefinition.rows) + (j + headerAddToIndex)]?.$uiGrouping ? undefined : 'objectId_' + listData[(i * listDefinition.rows) + (j + headerAddToIndex)]?.objectId"
            class="gridster-item pointer"
            [ngStyle]="item.itemStyle"
            [hidden]="!listData[(i * listDefinition.rows) + (j + headerAddToIndex)]"
            [item]="item"
            (click)="itemClick(listData[(i * listDefinition.rows) + (j + headerAddToIndex)], getDesignStyleWithMapping(i, j))">
            <gridster *ngIf="i | getDesignStyle:j:thisComponent:pipePureValueBusting"
              class="gridster background"
              [ngStyle]="(i | getDesignStyle:j:thisComponent:pipePureValueBusting).style?.style"
              [options]="gridsterOptions2">
              <gridster-item *ngFor="let item of (i | getDesignStyle:j:thisComponent:pipePureValueBusting).style?.items || []"
                class="gridster-item"
                [ngStyle]="item.itemStyle"
                [item]="item">

                <div class="gridster-item-content">
                  <img *ngIf="(item | getItemData:i:j:thisComponent:pipePureValueBusting)?.isResource"
                    class="image"
                    [ngStyle]="item.labelStyle"
                    [src]="resourceMap[(item | getItemData:i:j:thisComponent:pipePureValueBusting)?.resourceGuidId] ? resourceMap[(item | getItemData:i:j:thisComponent:pipePureValueBusting)?.resourceGuidId] : 'assets/img/loading.gif'"
                    alt="picture" />

                  <span *ngIf="!(item | getItemData:i:j:thisComponent:pipePureValueBusting)?.isResource"
                    class="label"
                    [ngStyle]="item.labelStyle">{{ item | getItemFieldValue:i:j:thisComponent:pipePureValueBusting }}
                  </span>
                </div>
              </gridster-item>
            </gridster>

          </gridster-item>
        </gridster>

        <div *ngIf="!listData[(i * listDefinition.rows)]?.$uiGrouping">
          <div class="quantity-picker button flex-row center middle"
            (click)="setComplexDataValueAutoQuantityChange(listData[(i * listDefinition.rows)])">
            <i [ngClass]="lineBehaviour === 1 ? 'fas fa-minus' : 'fas fa-plus'"></i>
          </div>
          <div class="quantity-picker value flex-row center middle"
            [style.background-color]="activeKeyboardForData === listData[(i * listDefinition.rows)] ? '#C9C9C9' : undefined"
            [style.border]="activeKeyboardForData === listData[(i * listDefinition.rows)] ? '2px solid var(--lc-primary-color)' : undefined"
            (click)="setComplexDataValueManualQuantityChange(listData[(i * listDefinition.rows)])">
            {{ getSetComplexDataValue(listData[(i * listDefinition.rows)]) }}
          </div>
        </div>
      </div>

      <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </div>
  </div>

  <div *ngIf="undoStackLayoutSet?.length"
    class="action-undo {{ uiSummaryLocation === 'top-center' ? 'top-right' : 'top-center'}}"
    (click)="undo()">
    {{ 'Undo' | translate }}
  </div>

  <div *ngIf="uiSummaryValue != null"
    class="ui-summary {{ uiSummaryLocation }}">
    {{ uiSummaryValue }}
  </div>

</ion-content>

<div class="barcode-scanner-container">
  <lc-barcode-scanner-livestream-overlay
    [layout]="layout"
    [layoutControl]="layoutControl"
    [types]="barcodeTypes"
    (valueChanges)="onCameraScan($event)">
  </lc-barcode-scanner-livestream-overlay>
</div>
