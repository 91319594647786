export enum SolutionDeviceControlScannerEnabledFlagType {

  NotActive = 0,
  LegacySimpleBarcode = 1, // Legacy
  LegacyAdvancedBarcode= 2, // Legacy

  Simple = 4,
  Advanced = 8,

  BuiltInScanner = 16,
  BluetoothScanner = 32,
  BuiltInNFC = 64,

}
