import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CriticalErrorMessage, LayoutCoreMessage, LayoutMessageResult, LayoutMessageType } from '../../models';
import { GeoJSON } from '../../models/geojson.model';
import { RuntimeLayoutLocationPoints } from '../../models/runtime-layout/runtime-layout-location-points.model';
import { LogUtils } from '../../utils';
import { HeartbeatService } from '../app/heartbeat.service';
import { BusyService } from '../busy/busy.service';
import { WebSocketDataService } from '../web-socket/web-socket-data.service';


@Injectable({
  providedIn: 'root'
})
export class LayoutLocationPointsService {

  private readonly timeoutInMs = 5 * 1000;

  constructor(
    private busyService: BusyService,
    private heartbeatService: HeartbeatService,
    private webSocketDataService: WebSocketDataService,
  ) { }

  trigger(
    geoJSONs: GeoJSON[],
  ): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      const sequenceNr = this.webSocketDataService.getSequenceNumber();
      const msgContent = new RuntimeLayoutLocationPoints({
        batchSequenceNr: sequenceNr,
        geoJSONs: (geoJSONs || []).map(g => JSON.stringify(g)),
      });
      LogUtils.log(`Sending ${(geoJSONs || []).length} location points to server...`);
      const msgContentBuffer = RuntimeLayoutLocationPoints.encode(msgContent).finish();

      const coreMsg = new LayoutCoreMessage({
        messageSequenceNr: sequenceNr,
        messageType: LayoutMessageType.LocationPoints,
        messageContent: msgContentBuffer,
      });
      const coreMsgBuffer  = LayoutCoreMessage.encode(coreMsg).finish();

      const subscription = this.webSocketDataService
      .getMessages$(coreMsg.messageSequenceNr)
      .pipe(delay(10))
      .subscribe((msg: LayoutCoreMessage) => {
        this.handleIncomingMessage(msg, observer, subscription);
      }, (error: any) => {
        this.handleError(subscription, observer, error);
      });

      // this.heartbeatService.scheduleNextHeartbeat(this.timeoutInMs);
      this.webSocketDataService.send(coreMsgBuffer);
    });
  }

  private handleIncomingMessage(
    msg: LayoutCoreMessage, observer: Observer<any>, subscription: Subscription
  ) {
    if (!subscription || subscription.closed) return;

    if (msg?.messageType === LayoutMessageType.CriticalError) {
      const error = CriticalErrorMessage.decode(msg.messageContent);
      observer.error(error);
    } else {
      observer.next(msg?.messageResult === LayoutMessageResult.Success);
      observer.complete();
    }

    subscription.unsubscribe();
    subscription = null;
  }

  private handleError(subscription: Subscription, observer: Observer<any>, error: any) {
    if (!subscription || subscription.closed) return;

    this.busyService.setBusy(false);

    subscription.unsubscribe();
    subscription = null;

    observer.error(error);
  }

}
