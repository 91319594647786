import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LocalSettingsService, VibrationService } from 'src/app/shared/services';
import { BrowserUtils } from 'src/app/shared/utils';




@Component({
  selector: 'lc-test-printer-popover',
  templateUrl: 'test-printer.popover.html',
  styleUrls: ['./test-printer.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestPrinterPopover implements AfterViewInit {

  isDeviceApp: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private localSettingsService: LocalSettingsService,
    private popoverCtrl: PopoverController,
    private vibrationService: VibrationService,
  ) {
    this.isDeviceApp = BrowserUtils.isDeviceApp();
  }

  ngAfterViewInit() {
    this.cdr.markForCheck();
  }

  success() {
    this.vibrationService.vibrate();

    this.popoverCtrl.dismiss({
      printed: true,
      failedPrintReason: undefined,
    });
  }

  failure() {
    this.vibrationService.vibrate();

    this.popoverCtrl.dismiss({
      printed: false,
      failedPrintReason: 'Some failure reason...',
    });
  }

  skip() {
    this.vibrationService.vibrate();

    this.popoverCtrl.dismiss();
  }

}
