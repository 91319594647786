import { Field, Message, Type } from 'protobufjs/light';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import encoding from 'text-encoding';
import { DesignStyleJson } from '../../studio';
const pako = require('pako');


@Type.d('RuntimeLayoutDesignStyle')
export class RuntimeLayoutDesignStyle extends Message<RuntimeLayoutDesignStyle> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'optional', 0)
  tick: number;

  @Field.d(10, 'string', 'optional')
  designStyleGuidId: string;
  @Field.d(11, 'string', 'optional')
  name: string;
  @Field.d(12, 'string', 'optional')
  designStyleOriginalGuidId: string;
  @Field.d(13, 'bool', 'optional')
  notClickable: boolean;

  // @Field.d(50, 'string', 'optional')
  // styleJson: string;
  @Field.d(50, 'bytes', 'optional')
  styleJsonBinary: Uint8Array;


  get styleJson(): string {
    if (this._styleJson) return this._styleJson;
    if (!this.styleJsonBinary?.length) return this._styleJson;

    const styleJsonUint8Array = pako.ungzip(this.styleJsonBinary);
    const decoder = new encoding.TextDecoder('utf8');
    this._styleJson = decoder.decode(styleJsonUint8Array);

    return this._styleJson;
  }
  private _styleJson: string;

  get style(): DesignStyleJson {
    if (this._style) return this._style;

    this._style = DesignStyleJson.parseDesignStyleJson(this.styleJson);
    if (!this._style) return this._style;

    this._style.items = (this._style.items || [])
    .map((i: any) => {
      if (i.field?.subVariableMemberGuidId) i.field.subVariableMemberGuidId = GuidUtils.clean(i.field.subVariableMemberGuidId);
      return i;
    });

    return this._style;
  }
  private _style: DesignStyleJson;
}
