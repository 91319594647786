import { Message, Type, Field } from 'protobufjs/light';

export enum RuntimeLayoutControlRenderType {
  Unknown = 0,
  Normal = 1,
  Head = 2,
  Simplified = 3,
// Normal = render the input you have today
// Head = render a value that I've added but not completed "HeadLayoutDesignControlObjectId"
//        - a DesignControl (the grid systems we were talking about)
// Simplifed = you will get a new design on this - but to just let you understand - it's a
// smaller version of the Normal. Don't have all controls on the object, will not care about the renderValues as much
}

@Type.d('RuntimeLayoutControlPointer')
export class RuntimeLayoutControlPointer extends Message<RuntimeLayoutControlPointer> {
  
  @Field.d(50, 'int64', 'required')
  objectId: number;
  @Field.d(51, 'bool', 'optional', false)
  activeControl: boolean;
  @Field.d(52, 'int32', 'optional')
  level: number;
  @Field.d(53, 'bool', 'optional')
  locked: boolean
  @Field.d(54, RuntimeLayoutControlRenderType, 'optional', RuntimeLayoutControlRenderType.Normal)
  controlRenderType: RuntimeLayoutControlRenderType;
  @Field.d(55, 'int64', 'optional', 0)
  tick: number;
  
}
