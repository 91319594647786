import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutSettingGroupSetType } from './runtime-layout-setting-group-set-type.enum';
import { RuntimeLayoutSetting } from './runtime-layout-setting.model';



@Type.d('RuntimeLayoutSettingGroup')
export class RuntimeLayoutSettingGroup extends Message<RuntimeLayoutSettingGroup> {

  @Field.d(1, 'int64', 'optional')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'optional')
  tick: number;

  @Field.d(50, 'string', 'optional')
  guidId: string;
  @Field.d(51, 'string', 'optional')
  name: string;
  @Field.d(52, 'string', 'optional')
  path: string;
  @Field.d(53, 'int32', 'optional')
  setType: RuntimeLayoutSettingGroupSetType;
  @Field.d(54, 'string', 'optional')
  settingGroupDateTime: string;

  @Field.d(60, RuntimeLayoutSetting, 'repeated')
  settings: RuntimeLayoutSetting[];

}
