import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeviceEnrollment } from 'src/app/shared/models';
import { DeviceLicense } from 'src/app/shared/models/device-license.enum';
import { ApiService, AppService, ClientAuthService, VibrationService } from 'src/app/shared/services';
import { OpsStatsService, OpsStatsType } from 'src/app/shared/services/app/ops-stats.service';
import { LogUtils } from 'src/app/shared/utils';
const { version } = require('../../../../package.json');



@Component({
  selector: 'lc-system-info-modal',
  templateUrl: 'system-info.modal.html',
  styleUrls: ['./system-info.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemInfoModal implements OnInit {

  activeView: string;
  additionalDeviceLicenses: string[];
  appVersion: string;
  deviceId: string;
  deviceEnrollment: DeviceEnrollment;
  ipAddress: string;
  networkInfo: any;
  status: any;
  stats: any;

  constructor(
    private apiService: ApiService,
    private appService: AppService,
    private clientAuthService: ClientAuthService,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private opsStatsService: OpsStatsService,
    private vibrationService: VibrationService,
  ) {
    this.appVersion = version.replace(/-/g, ' ');

    this.deviceId = this.clientAuthService.deviceId;
  }

  ngOnInit() {
    this.activeView = 'system';
    this.networkInfo = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
    this.ipAddress = undefined;

    try {
      // Trying to get the Local IP Address
      window.RTCPeerConnection = (window as any).RTCPeerConnection || (window as any).mozRTCPeerConnection || (window as any).webkitRTCPeerConnection;   //compatibility for firefox and chrome
      const pc = new RTCPeerConnection({iceServers:[]});
      const noop = () => {};
      pc.createDataChannel('');    //create a bogus data channel
      (pc as any).createOffer(pc.setLocalDescription.bind(pc), noop);    // create offer and set local description
      pc.onicecandidate = (ice) => {  //listen for candidate events
          if (!ice?.candidate?.candidate) return;

          const regExResult = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate);
          if (regExResult?.length) {
            this.ipAddress = regExResult[1];
            this.cdr.markForCheck();
          }

          pc.onicecandidate = noop;
      };
    } catch (error) {
      LogUtils.warn(error);
    }

    this.refresh();
  }

  refresh() {
    this.deviceEnrollment = this.appService.getDeviceEnrollment();
    this.stats = this.opsStatsService.getStatsForAllTypes();

    const layoutSnapshot = this.appService.getLayoutSnapshot();

    const deviceLicenseMap = {};
    for (const key in DeviceLicense) {
      deviceLicenseMap[DeviceLicense[key]] = key;
    }
    this.additionalDeviceLicenses = [];
    for (const deviceLicense of layoutSnapshot?.runtimeLayout?.deviceLicenses || []) {
      this.additionalDeviceLicenses.push(deviceLicense.name);
    }

    this.apiService.getRaw('status')
    .subscribe((status: any) => {
      Object.assign(status, { requestResponseTimeInMs: this.opsStatsService.getLastOpsStatsValueForType(OpsStatsType.ApiRequestResponseTimeInMs).value });
      this.status = status;
      this.cdr.markForCheck();
    });

    this.cdr.markForCheck();
  }

  dismiss() {
    this.vibrationService.vibrate(true);

    this.modalCtrl.dismiss();
  }

  clearOpsStats() {
    this.opsStatsService.clear();
    this.opsStatsService.addValue(OpsStatsType.AppAliveStateChanges, 'alive');
    this.opsStatsService.addValue(OpsStatsType.AppOnlineStateChanges, this.appService.isAppOnline ? 'online' : 'offline');
    this.refresh();
  }

  refreshDeviceEnrollment() {
    this.appService.refreshDeviceEnrollment()
    .subscribe((deviceEnrollment: DeviceEnrollment) => {
      setTimeout(() => {
        this.refresh();
      }, 250);
    });
  }
}
