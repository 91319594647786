<div class="popover-grid-container">
  <h2>
    {{ 'Loading' | translate }}
  </h2>

  <p class="text">
    {{ text }}
  </p>

  <br>
</div>

<ng-progress></ng-progress>
