import { Field, Message, Type } from 'protobufjs/light';



@Type.d('RuntimeLayoutDebugMail')
export class RuntimeLayoutDebugMail extends Message<RuntimeLayoutDebugMail> {

  @Field.d(20, 'string', 'required')
  email: string;
  @Field.d(21, 'string', 'required')
  subject: string;
  @Field.d(22, 'string', 'required')
  content: string;

}
