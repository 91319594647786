import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlInput1Module } from '../input1/control-input1.module';
import { ControlPrint1Component } from './control-print1.component';


@NgModule({
  declarations: [
    ControlPrint1Component,
  ],
  exports: [
    ControlPrint1Component,
  ],
  imports: [
    SharedModule,

    ControlInput1Module,
  ],
  providers: [],
})
export class ControlPrint1Module { }
