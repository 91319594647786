import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BarcodeScannerLivestreamOverlayModule } from '../../barcode-scanner/barcode-scanner-livestream-overlay/barcode-scanner-livestream-overlay.module';
import { ControlCamera1Module } from '../camera1/control-camera1.module';
import { ControlInput1Module } from '../input1/control-input1.module';
import { ControlSog1Module } from '../sog1/control-sog1.module';
import { ControlChecklist1Component } from './control-checklist1.component';


@NgModule({
  declarations: [
    ControlChecklist1Component,
  ],
  exports: [
    ControlChecklist1Component,
  ],
  imports: [
    SharedModule,

    BarcodeScannerLivestreamOverlayModule,
    ControlCamera1Module,
    ControlInput1Module,
    ControlSog1Module,
  ],
  providers: [],
})
export class ControlChecklist1Module { }
