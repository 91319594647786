import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, NgZone, OnInit, Output } from '@angular/core';
import { Gesture } from '@ionic/core';
import { GestureController } from '@ionic/core/dist/collection/utils/gesture/gesture-controller';
import { KeyboardType } from 'src/app/shared/models/keyboard-type.enum';
import { LocalSettingsService, TextService, VibrationService } from 'src/app/shared/services';
import { KeyboardDefinition } from './keyboard-definition.data';
import { LogUtils } from 'src/app/shared/utils';
declare var Hammer: any;


@Component({
  selector: 'lc-keyboard',
  templateUrl: 'keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
})
export class KeyboardComponent implements OnInit {

  private alignOptions = Object.freeze([
    'left',
    'center',
    'right',
  ]);
  private themeOptions = Object.freeze([
    'light',
    'dark',
    'ionic',
    'opaque-black',
    'opaque-white',
    'messenger'
  ]);
  private animationOptions = Object.freeze([
    'slide',
    'pop'
  ]); // @TODO
  private keyboardTypes = Object.freeze([
    KeyboardType.Numeric,
    KeyboardType.Alpha,
    KeyboardType.AlphaNumeric,
    KeyboardType.AlphaNumericWithEnter,
  ]);

  @Input() set align(value: string) { this.setInputField(value, 'align', this.alignOptions); }
  get align() { return this._align; }
  private _align: string = 'left';

  // TODO: animations
  @Input() set animation(value: string) { this.setInputField(value, 'animation', this.animationOptions); }
  get animation() { return this._animation; }
  private _animation: string = 'default';

  @Input() set keyboardType(value: string) { this.setInputField(value, 'keyboardType', this.keyboardTypes); }
  get keyboardType() { return this._keyboardType; }
  private _keyboardType: string = KeyboardType.Numeric;

  @Input() set theme(value: string) { this.setInputField(value, 'theme', this.themeOptions); }
  get theme() { return this._theme; }
  private _theme: string = 'ionic';

  @Input() set width(value: any) {
    const isPercent = String(value).indexOf('%') >= 0 ? true : false;
    this._width = parseInt(value) + (isPercent ? '%' : 'px');
  }
  get width() { return this._width; }
  private _width: string;

  @Input() swipeToHide: boolean = true;
  @Input() id: string;
  @Input() uppercaseOnly: boolean;

  @HostBinding('class.visible') @Input() visible: boolean = false;

  @Output() afterShow: EventEmitter<void> = new EventEmitter();
  @Output() afterHide: EventEmitter<void> = new EventEmitter();
  @Output() keyClick: EventEmitter<any> = new EventEmitter();

  zoom: number = 1;
  keys = KeyboardDefinition[this.keyboardType];
  activeKeySet: 'keySet1' | 'keySet2' | 'keySet3' | 'keySet4' = 'keySet1';
  activeLanguage: string = 'Swedish';

  // Swipe gesture
  private _swipeGesture: GestureController;
  private _isSwiping: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private ngZone: NgZone,
    private textService: TextService,
    private vibrationService: VibrationService,
  ) {

  }

  ngOnInit(): void {
    this.initSwipeGesture();
  }

  /**
   * Adjust the keyboard zoom level.
   * Helps maintain proper visual.
   */
  private adjustZoomLevel(): void {
    const referenceHeight = 400;
    const currentHeight = document.body.clientHeight;
    this.zoom = Math.min(2, currentHeight / referenceHeight);
  }

  /**
   * Init the swipe top to bottom gesture.
   */
  private initSwipeGesture(): void {
    this._swipeGesture = new Hammer.Manager(this.el.nativeElement, {
      recognizers: [
        [Hammer.Swipe, { direction: Hammer.DIRECTION_VERTICAL }]
      ],
    });
    // this._swipeGesture.listen();
    this._swipeGesture.on('swipedown', (e) => { this.onSwipe(e); });
  }

  /**
   * Called when the user swipe the keyboard down.
   */
  private onSwipe(event: Gesture): void {
    if (!this.swipeToHide) return;

    this._isSwiping = true;
    this.hide();
    setTimeout(() => {
      this._isSwiping = false;
    }, event['deltaTime'] || 250);
  }

  /**
   * Called when any keyboard button is clicked
   *
   * @param {any} event
   * @param {*} key
   */
  public btnClick(event, key: any): void {
    // Prevent click on keyboard swip
    if (this.swipeToHide && this._isSwiping) return;

    if (key === 'shift') {
      this.activeKeySet = this.activeKeySet === 'keySet2' ? 'keySet1' : 'keySet2';
    } else if (key === '123') {
      this.activeKeySet = 'keySet3';
    } else if (key === 'abc') {
      this.activeKeySet = 'keySet1';
    } else if (key === '!&') {
      this.activeKeySet = 'keySet4';
    }

    this.keyClick.emit(key);

    this.vibrationService.vibrate(true);
  }

  /**
   * Call this method to hide the keyboard.
   */
  hide(): void {
    if (!this.visible) return;

    this.ngZone.run(() => {
      this.visible = false;
      this.cdr.markForCheck();

      // setTimeout(() => {
        this.afterHide.emit();
      // }, this.getTransitionDuration(this.el.nativeElement));
    });
  }

  /**
   * Call this method to show the keyboard.
   */
  public show(
    keyboardType: KeyboardType,
    useLanguageKeyboard: boolean,
    startNumeric?: boolean,
  ): void {
    this.ngZone.run(() => {
      this.keys = KeyboardDefinition[keyboardType || KeyboardType.Numeric];
      if (!this.keys) {
        LogUtils.error('Invalid keyboardType: ' + keyboardType);
        return;
      }

      if (useLanguageKeyboard) {
        this.activeLanguage = this.textService.languageName || 'Swedish';
        if (!this.keys[this.activeLanguage]) this.activeLanguage = 'Swedish';
      } else {
        this.activeLanguage = 'Swedish';
      }

      if (!this.visible) {
        this.adjustZoomLevel();

        if (startNumeric && (keyboardType === KeyboardType.AlphaNumeric || keyboardType === KeyboardType.AlphaNumericWithEnter)) {
          this.activeKeySet = 'keySet3';
        } else if (!this.keys[this.activeLanguage]?.[this.activeKeySet]) {
          this.activeKeySet = 'keySet1';
        }
        this.visible = true;
        this.cdr.markForCheck();

        setTimeout(() => {
          this.afterShow.emit();
        }, this.getTransitionDuration(this.el.nativeElement));
      } else {
        if (startNumeric && (keyboardType === KeyboardType.AlphaNumeric || keyboardType === KeyboardType.AlphaNumericWithEnter)) {
          this.activeKeySet = 'keySet3';
        } else if (!this.keys[this.activeLanguage]?.[this.activeKeySet]) {
          this.activeKeySet = 'keySet1';
        }
        this.cdr.markForCheck();
      }
    });
  }

  /**
   * Return the transition duration of an HTMLElement if exists.
   */
  private getTransitionDuration(el: HTMLElement): number {
    const ms = window.getComputedStyle(el, null).getPropertyValue("transition-duration").split(',')[0];
    const multiplier = ms.indexOf('ms') >= 0 ? 1 : ms.indexOf('s') >= 0 ? 1000 : 1;
    return parseFloat(ms) * multiplier;
  }

  private setInputField(value: string, fieldKey: string, options: ReadonlyArray<string>) {
    if (options.indexOf(value) >= 0) {
      this['_' + fieldKey] = value;
    } else {
      this.log(`Invalid [${fieldKey}] value: ${value}.`, 'error');
    }
  }

  /**
   * Log utility
   *
   * @private
   * @param {string} message
   * @param {string} [type='log | warning | error']
   */
  private log(message: string, type: string = 'log'): void {
    if (!console) return;

    let c = '#3690CB';
    if (type === 'error') c = '#e74c3c';
    if (type === 'warning') c = '#f39c12';
    console.log('Keyboard: ' + message, 'font-weight: bold; color: ' + c + ';', '');
  }

}