export const DEFAULT_CONFIG_QUAGGA = {
    numOfWorkers: navigator.hardwareConcurrency,
    locate: true,
    inputStream: {
        name: 'Live',
        type: 'LiveStream',
        target: null,
        constraints: {
            size: 1024,
            width: 640,
            height: 480,
            aspectRatio: { min: 1, max: 100 },
            facingMode: 'environment', // or 'user'
        },
        singleChannel: false // true: only the red color-channel is read
    },
    locator: {
        patchSize: 'medium',
        halfSample: false,
    },
    decoder: {
        readers: ['code_128_reader'],
        multiple: false,
    },
};
