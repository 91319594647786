import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { RuntimeLayoutDebugLog, RuntimeLayoutDebugLogMessage } from 'src/app/shared/models/runtime-layout/debug';
import { CriticalErrorMessage, LayoutCoreMessage, LayoutMessageResult, LayoutMessageType } from '../../models';
import { HeartbeatService } from '../app/heartbeat.service';
import { BusyService } from '../busy/busy.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';


@Injectable({
  providedIn: 'root'
})
export class LayoutDebugLogService {

  private readonly timeoutInMs = 5 * 1000;

  constructor(
    private busyService: BusyService,
    private heartbeatService: HeartbeatService,
    private webSocketClientService: WebSocketClientService
  ) { }

  trigger(
    logs: RuntimeLayoutDebugLog[],
  ): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      const msgContent = new RuntimeLayoutDebugLogMessage({
        logs: logs,
      });
      const msgContentBuffer = RuntimeLayoutDebugLogMessage.encode(msgContent).finish();

      const coreMsg = new LayoutCoreMessage({
        messageSequenceNr: this.webSocketClientService.getSequenceNumber(),
        messageType: LayoutMessageType.DebugLog,
        messageContent: msgContentBuffer,
      });
      const coreMsgBuffer  = LayoutCoreMessage.encode(coreMsg).finish();

      const subscription = this.webSocketClientService
      .getMessages$(coreMsg.messageSequenceNr)
      .pipe(delay(10))
      .subscribe((msg: LayoutCoreMessage) => {
        this.handleIncomingMessage(msg, observer, subscription);
      }, (error: any) => {
        this.handleError(subscription, observer, error);
      });

      // this.heartbeatService.scheduleNextHeartbeat(this.timeoutInMs);
      this.webSocketClientService.send(coreMsgBuffer);
    });
  }

  private handleIncomingMessage(
    msg: LayoutCoreMessage, observer: Observer<any>, subscription: Subscription
  ) {
    if (!subscription || subscription.closed) return;

    if (msg?.messageType === LayoutMessageType.CriticalError) {
      const error = CriticalErrorMessage.decode(msg.messageContent);
      observer.error(error);
    } else {
      observer.next(msg?.messageResult === LayoutMessageResult.Success);
      observer.complete();
    }

    subscription.unsubscribe();
    subscription = null;
  }

  private handleError(subscription: Subscription, observer: Observer<any>, error: any) {
    if (!subscription || subscription.closed) return;

    this.busyService.setBusy(false);

    subscription.unsubscribe();
    subscription = null;

    observer.error(error);
  }

}
