import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { VibrationService } from 'src/app/shared/services';
import { TranslateService } from 'src/app/shared/services/app';




@Component({
  selector: 'lc-confirm-popover',
  templateUrl: 'confirm.popover.html',
  styleUrls: ['./confirm.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPopover implements OnInit {

  title: string;
  text: string;
  noText: string;
  yesText: string;
  noTimeoutInSec: number;

  isClosed: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private translateService: TranslateService,
    private vibrationService: VibrationService,
  ) {
    this.title = this.navParams.get('title');
    this.text = this.navParams.get('text');
    this.noText = this.navParams.get('noText');
    this.yesText = this.navParams.get('yesText');
    this.noTimeoutInSec = this.navParams.get('noTimeoutInSec');
  }

  ngOnInit() {
    this.vibrationService.vibrate();

    if (this.noTimeoutInSec) {
      this.executeTimeoutSequence(false);
    }
  }

  private executeTimeoutSequence(result: boolean) {
    setTimeout(() => {
      if (!this.isClosed) {
        this.noTimeoutInSec = this.noTimeoutInSec - 1;
        this.cdr.markForCheck();

        if (this.noTimeoutInSec > 0) {
          this.executeTimeoutSequence(result);
        } else {
          this.close(result);
        }
      }
    }, 1000);
  }

  dismiss() {
    this.isClosed = true;
    this.popoverCtrl.dismiss();
  }

  close(result: boolean) {
    this.isClosed = true;
    this.popoverCtrl.dismiss(result);
  }

}
