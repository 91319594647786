import { Message, Type, Field } from 'protobufjs/light';
import { LayoutMessageType } from './layout-message-type.enum';
import { LayoutMessageResult } from './layout-message-result.enum';


@Type.d('LayoutCoreMessage')
export class LayoutCoreMessage extends Message<LayoutCoreMessage> {

  @Field.d(1, 'int64', 'optional', 0)
  messageOrigin: number;
  @Field.d(2, 'int64', 'required')
  messageSequenceNr: number;
  @Field.d(3, 'int32', 'required')
  messageType: LayoutMessageType;
  @Field.d(4, 'bool', 'optional', false)
  replyMessage: boolean;
  @Field.d(5, LayoutMessageResult, 'optional', LayoutMessageResult.Success)
  messageResult: LayoutMessageResult;
  @Field.d(6, 'bytes', 'optional')
  messageContent: Uint8Array;
  @Field.d(7, 'string', 'optional')
  errorMessage: string;

}