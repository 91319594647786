import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoadingComponent } from './ngx-loading.component';
import { ILoadingConfig } from './ngx-loading.config';


@NgModule({
    imports: [CommonModule],
    exports: [LoadingComponent],
    declarations: [LoadingComponent],
    providers: [],
})
export class LoadingModule {
    static forRoot(loadingConfig: ILoadingConfig): ModuleWithProviders<LoadingModule> {
        return {
            ngModule: LoadingModule,
            providers: [{ provide: 'loadingConfig', useValue: loadingConfig }]
        };
    }
}
