import { NgModule } from '@angular/core';
import { GridsterModule } from 'angular-gridster2';
import { ListPipesModule } from 'src/app/shared/pipes/list/list-pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BarcodeScannerLivestreamOverlayModule } from '../../barcode-scanner/barcode-scanner-livestream-overlay/barcode-scanner-livestream-overlay.module';
import { ControlQuantityList1Component } from './control-quantity-list1.component';



@NgModule({
  declarations: [
    ControlQuantityList1Component,
  ],
  exports: [
    ControlQuantityList1Component,
  ],
  imports: [
    SharedModule,

    BarcodeScannerLivestreamOverlayModule,
    GridsterModule,
    ListPipesModule,
  ],
  providers: [],
})
export class ControlQuantityList1Module { }
