import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutValueType } from './runtime-layout-value-type.enum';


@Type.d('RuntimeLayoutValue')
export class RuntimeLayoutValue extends Message<RuntimeLayoutValue> {

  @Field.d(50, 'int32', 'optional')
  valueTypeId: RuntimeLayoutValueType;

  @Field.d(51, 'string', 'optional')
  valueJson: string;

  @Field.d(52, 'string', 'optional')
  extendedValueType: string;

  // sometimes patrik sends this as PascalCase....
  ValueJson: string;


  static parse(rlv: RuntimeLayoutValue): any {
    if (
      rlv && (rlv.valueJson != null || rlv.ValueJson != null)
    ) {
      return JSON.parse(rlv.valueJson || rlv.ValueJson || null);
    } else {
      return undefined;
    }
  }

  parse(): any {
    return RuntimeLayoutValue.parse(this);
  }

}
