import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrowserUtils, LogUtils } from 'src/app/shared/utils';
const { version } = require('../../../../../package.json');


@Injectable({
  providedIn: 'root'
})
export class CheckVersionService {

  static alreadyChecked = false;

  constructor(
    private http: HttpClient,
  ) { }

  check(): void {
    if (CheckVersionService.alreadyChecked) return;

    if (BrowserUtils.isDeviceApp() || BrowserUtils.isLocalhost()) {
      CheckVersionService.alreadyChecked = true;
      LogUtils.log('Running locally - not checking for version.');
      return;
    }

    this.http.get(
      'assets/' + version.toString().replace(/\./g, '_') + '.version?r=' + Date.now(),
      {
        responseType: 'text'
      }
    )
    .subscribe((response: string) => {
      CheckVersionService.alreadyChecked = true;
      if (!response) { // we are expecting an empty file...
        LogUtils.log('Running on latest version: ' + version);
      } else { // if it isn't empty we probably got redirected
        const separator = window.location.href.indexOf('?') >= 0 ? '&' : '?';
        window.location.href = window.location.href + separator + 'r=' + Date.now();
      }
    }, (error: any) => {
      LogUtils.warn('Not latest version. Refreshing page...');
      const separator = window.location.href.indexOf('?') >= 0 ? '&' : '?';
      window.location.href = window.location.href + separator + 'r=' + Date.now();
    });
  }

}
