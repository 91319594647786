<div class="grid-container">
  <div class="control-location1">
    <h2 *ngIf="layoutControl.parseRV('ControlHeadlineEnabled')" style="padding-left: 4px;">
      {{ layoutControl.parseRV('ControlHeadlineText') }}
    </h2>

    <div class="location">
      <img alt="location icon"
        [src]="'assets/img/' + theme + '/icon-location.svg'" />
    </div>
  </div>
</div>