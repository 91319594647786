import { ChecklistAnswerCheckResult } from './checklist-answer-check-result.model';



export class ChecklistAnswer {

  guidId: string;
  checklistResult: string;
  startDateTime: string;
  endDateTime: string;
  confirmedFreeText: string;
  signatureResourceGuidId: string;
  checkResults: ChecklistAnswerCheckResult[];

  constructor(item?: Partial<ChecklistAnswer>) {
    Object.assign(this, item);
  }

}
