<ion-toolbar class="ion-no-padding main-header">
  <div *ngIf="activeScreen"
    slot="start"
    class="tab-select no_alert">
    <a *ngIf="layout && layout?.menuSettings !== 2"
      (click)="!layout || layout?.menuSettings || layoutScreens.length < 2 ? null : isTabMenuOpened = !isTabMenuOpened;"
      href="javascript:void(0);"
      [ngClass]="!layout || layout?.menuSettings || layoutScreens.length < 2 ? 'disabled' : isTabMenuOpened === true ? 'tm-open' : 'tm-closed'">
      {{ getScreenTitle(activeScreen) }}
    </a>
  </div>

  <div slot="secondary">
    {{ environmentName }}
  </div>

  <ion-buttons slot="end">
    <ion-menu-button *ngIf="!hideMenu"
      [autoHide]="false"
      class="menu-button"
      menu="menu">
      <ion-icon name="menu"></ion-icon>
    </ion-menu-button>
  </ion-buttons>
</ion-toolbar>

<ion-toolbar *ngIf="!isConnectionToServerActive || isBatteryLow || statusBarMessage"
  class="status-bar offline">
  <ion-title>
    {{ !isConnectionToServerActive ? ('No Connection' | translate) : '' }}
    {{ !isConnectionToServerActive && isBatteryLow ? ' & ' : '' }}
    {{ isBatteryLow ? ('Battery Low' | translate) : '' }}
    {{ isBatteryLow && statusBarMessage ? ' & ' : '' }}
    {{ statusBarMessage }}
  </ion-title>
</ion-toolbar>

<div *ngIf="!layout?.menuSettings"
  class="tab-menu overlay"
  [class.show]="isTabMenuOpened === true">
  <div class="background"
    (click)="isTabMenuOpened = false">
  </div>
  <nav>
    <ul>
      <li *ngFor="let layoutScreen of layoutScreens"
        [hidden]="layoutScreen.objectId === activeScreen?.objectId">
        <a href="javascript:void(0);"
          (click)="setActiveScreen(layoutScreen.objectId)">
          {{ getScreenTitle(layoutScreen) }}
        </a>
      </li>
    </ul>
  </nav>
</div>