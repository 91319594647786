import { Field, Message, Type } from 'protobufjs/light';
import encoding from 'text-encoding';
const pako = require('pako');

@Type.d('RuntimeLayoutSmartImageRegion')
export class RuntimeLayoutSmartImageRegion extends Message<RuntimeLayoutSmartImageRegion> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(10, 'string', 'optional')
  regionGuidId: string;
  @Field.d(11, 'string', 'optional')
  name: string;

  @Field.d(50, 'bytes', 'optional')
  geoJsonBinary: Uint8Array;
  @Field.d(51, 'bool', 'optional')
  hasChildSmartImage: boolean;
  @Field.d(52, 'string', 'optional')
  childSmartImageGuidId: string;

  get geoJson(): any {
    if (!this._geoJson) {
      const uint8Array = pako.ungzip(this.geoJsonBinary);
      const decoder = new encoding.TextDecoder('utf8');
      this._geoJson = decoder.decode(uint8Array);
    }
    return this._geoJson;
  }
  private _geoJson: any;
}
