import { Injectable } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import * as json2xml from 'json2xml';
import { Observable, from, of, zip } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BrowserUtils, LogUtils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { DeviceEnrollment, HostNewRequest, HostNewResponse, HostOemRequest, HostRefreshResponse } from '../../../models';
import { ClientType } from '../../../models/manager/client-type.enum';
import { StorageService } from '../../app/storage.service';
import { ApiService } from '../api.service';


@Injectable({
  providedIn: 'root'
})
export class EnrollService {

  private readonly urlSuffixPlaceholder = 'enroll/{how}';
  private readonly storageKey = 'lc_deviceEnrollment';
  private readonly storageKeyResources = 'lc_resources';

  constructor(
    private apiService: ApiService,
    private device: Device,
    private file: File,
    private storageService: StorageService,
  ) { }

  oem(deviceOemGuidId: string, runClientType: ClientType): Observable<DeviceEnrollment> {
    return this.apiService.post<DeviceEnrollment>(
      this.urlSuffixPlaceholder.replace('{how}', 'oem'),
      {
        runClientType: runClientType,
        hardwareOs: this.device.platform || '1',
        uUID: this.device.uuid || '',
        deviceOemGuidId: deviceOemGuidId,
      } as HostOemRequest
    )
    .pipe(
      mergeMap((response: any) => {
        const deviceEnrollment = response ? response.runDeviceEnrollment : null;
        if (!deviceEnrollment) return of(null);

        deviceEnrollment.$runCode = undefined;
        deviceEnrollment.$runDevice = undefined;
        deviceEnrollment.$deviceOemGuidId = deviceOemGuidId;
        deviceEnrollment.$enrollmentKey = undefined;

        return this.storageService.get(this.storageKeyResources)
        .pipe(
          mergeMap((resourceGuidIds: string[]) => {
            resourceGuidIds = resourceGuidIds || [];
            return zip(
              this.storageService.remove(this.storageKeyResources),
              ...resourceGuidIds.map((resourceGuidId: string) => {
                return this.storageService.remove(this.storageKeyResources + '_' + resourceGuidId)
              })
            );
          }),
          map(() => {
            return deviceEnrollment;
          })
        );
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment) => {
        return this.writeEnrollmentToFile(deviceEnrollment);
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment) => {
        return this.localUpdate(deviceEnrollment);
      })
    );
  }

  new(
    enrollmentRunUri: string,
    enrollmentKey: string,
    enrollmentPassword: string,
    enrollmentSubKey: string,
    runClientType: ClientType,
  ): Observable<HostNewResponse> {
    return this.apiService.post<HostNewResponse>(
      this.urlSuffixPlaceholder.replace('{how}', 'new'),
      {
        runClientType: runClientType,
        hardwareOs: this.device.platform || '1',
        uUID: this.device.uuid || BrowserUtils.getQueryParams()?.runDevice || '',
        enrollmentKey: enrollmentKey,
        enrollmentPassword: enrollmentPassword,
        enrollmentSubKey: enrollmentSubKey,
      } as HostNewRequest,
      enrollmentRunUri
    )
    .pipe(
      mergeMap((response: HostNewResponse) => {
        const deviceEnrollment = response ? response.runDeviceEnrollment : null;
        if (!deviceEnrollment || !response?.enrolled) return of(response);

        this.apiService.setApiUrl(enrollmentRunUri, enrollmentRunUri === environment.apiUrl);

        deviceEnrollment.$runCode = undefined;
        deviceEnrollment.$runDevice = BrowserUtils.getQueryParams()?.runDevice;
        deviceEnrollment.$deviceOemGuidId = undefined;
        deviceEnrollment.$enrollmentKey = enrollmentKey;

        return this.storageService.get(this.storageKeyResources)
        .pipe(
          mergeMap((resourceGuidIds: string[]) => {
            resourceGuidIds = resourceGuidIds || [];
            return zip(
              this.storageService.remove(this.storageKeyResources),
              ...resourceGuidIds.map((resourceGuidId: string) => {
                return this.storageService.remove(this.storageKeyResources + '_' + resourceGuidId)
              })
            );
          }),
          map(() => {
            return response;
          })
        );
      }),
      mergeMap((response: HostNewResponse) => {
        const deviceEnrollment = response ? response.runDeviceEnrollment : null;
        if (!deviceEnrollment || !response?.enrolled) return of(response);

        return this.writeEnrollmentToFile(deviceEnrollment)
        .pipe(
          map(() => {
            return response;
          })
        );
      }),
      mergeMap((response: HostNewResponse) => {
        const deviceEnrollment = response ? response.runDeviceEnrollment : null;
        if (!deviceEnrollment || !response?.enrolled) return of(response);

        return this.localUpdate(deviceEnrollment)
        .pipe(
          map(() => {
            return response;
          })
        );
      }),
    );
  }

  list(): Observable<DeviceEnrollment> {
    return this.apiService.post<DeviceEnrollment>(
      this.urlSuffixPlaceholder.replace('{how}', 'list'),
      {}
    );
  }

  refresh(currentDeviceEnrollment: DeviceEnrollment): Observable<DeviceEnrollment> {
    return this.apiService.post<HostRefreshResponse>(
      this.urlSuffixPlaceholder.replace('{how}', 'refresh'),
      {
        enrollmentGuidId: currentDeviceEnrollment.enrollmentGuidId
      }
    )
    .pipe(
      mergeMap((response: any) => {
        const deviceEnrollment = response ? response.runDeviceEnrollment : null;

        deviceEnrollment.$deviceRunStatus = currentDeviceEnrollment.$deviceRunStatus;
        deviceEnrollment.$deviceOemGuidId = currentDeviceEnrollment.$deviceOemGuidId;
        deviceEnrollment.$enrollmentKey = currentDeviceEnrollment.$enrollmentKey;
        deviceEnrollment.$runCode = currentDeviceEnrollment.$runCode;
        deviceEnrollment.$runSet = currentDeviceEnrollment.$runSet;
        deviceEnrollment.$runSetSubKey = currentDeviceEnrollment.$runSetSubKey;

        return this.storageService.get(this.storageKeyResources)
        .pipe(
          mergeMap((resourceGuidIds: string[]) => {
            resourceGuidIds = resourceGuidIds || [];
            return zip(
              this.storageService.remove(this.storageKeyResources),
              ...resourceGuidIds.map((resourceGuidId: string) => {
                return this.storageService.remove(this.storageKeyResources + '_' + resourceGuidId)
              })
            );
          }),
          map(() => {
            return deviceEnrollment;
          })
        );
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment) => {
        return this.writeEnrollmentToFile(deviceEnrollment);
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment) => {
        return this.localUpdate(deviceEnrollment);
      })
    );
  }

  localUpdate(deviceEnrollment: DeviceEnrollment): Observable<DeviceEnrollment> {
    return this.storageService.set(this.storageKey, deviceEnrollment)
    .pipe(
      map(() => {
        return deviceEnrollment;
      })
    );
  }

  getLocalEnrollment(): Observable<DeviceEnrollment> {
    return this.storageService.get(this.storageKey);
  }

  private writeEnrollmentToFile(de: DeviceEnrollment): Observable<DeviceEnrollment> {
    if (!BrowserUtils.isDeviceApp()) return of(de);
    if (!de) return of(de);
    if (!de?.deviceIndustrialMode) return of(de);

    const rootDir = 'file:///sdcard/';
    const dirName = 'Documents/';
    const filePath = rootDir + dirName;
    const fileName = 'enrollment.xml';


    LogUtils.log('Writing enrollment info to: ' + filePath + fileName);
    const enrollmentXml = json2xml(
      {
        enrollment: {
          deviceGuidId: de.deviceGuidId,
          deviceId: de.deviceId,
          deviceIndustrialMode: de.deviceIndustrialMode,
          deviceLicensePaused: de.deviceLicensePaused,
          deviceSolutionGuidId: de.deviceSolutionGuidId,
          deviceSolutionName: de.deviceSolutionName,
          deviceSolutionSetGuidId: de.deviceSolutionSetGuidId,
          deviceSolutionSetName: de.deviceSolutionSetName,
          enrollmentGuidId: de.enrollmentGuidId,
          hardwareGuidId: de.hardwareGuidId,
          hasDeviceLicense: de.hasDeviceLicense,
          siteName: de.siteName,
          siteGuidId: de.siteGuidId,
          sitePath: de.sitePath,
          sotiSiteKey: de.sotiSiteKey,
          solutionAgreementGuidId: de.solutionAgreementGuidId,
          solutionAgreementName: de.solutionAgreementName,
          solutionEnvironmentGuidId: de.solutionEnvironmentGuidId,
          solutionEnvironmentName: de.solutionEnvironmentName,
          solutionProfileName: de.solutionProfileName,
        }
      },
      { header: true }
    );

    return from(
      this.file.checkFile(filePath, fileName)
    ).pipe(
      catchError((error: any) => {
        // The Documents directory only exists, by default, in Android 11+
        return from(this.file.checkDir(rootDir, dirName))
        .pipe(
          catchError((error: any) => {
            return from(this.file.createDir(rootDir, dirName, false));
          }),
          map((result: any) => {
            return true;
          })
        );
      }),
      mergeMap((result: boolean) => {
        return this.file.writeFile(filePath, fileName, enrollmentXml, { replace: result });
      }),
      map(() => {
        LogUtils.log('File enrollment.xml updated successfully.');
        return de;
      }),
      catchError((error: any) => {
        alert('Failed to write to ' + filePath + fileName + '. Please delete the file manually and refresh the enrollment in System Info.');
        return of(de);
      }),
    );
  }

}
