import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { ChecklistCheck } from 'src/app/shared/models/checklist/checklist-check.model';
import { VibrationService } from 'src/app/shared/services';
import { TranslateService } from 'src/app/shared/services/app';




@Component({
  selector: 'lc-checklist-menu-popover',
  templateUrl: 'checklist-menu.popover.html',
  styleUrls: ['./checklist-menu.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistMenuPopover {

  check: ChecklistCheck;
  menuItems: any[];

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private translateService: TranslateService,
    private vibrationService: VibrationService,
  ) {
    this.check = this.navParams.get('check');

    this.menuItems = [
      { disabled: !this.check?.description, label: this.translateService.instant('Description'), value: 'description' },
      { disabled: !this.check?.allowFreeText, label: this.translateService.instant('Free Text'), value: 'free-text' },
      { disabled: !this.check?.allowPicture, label: this.translateService.instant('Picture'), value: 'picture' },
    ].filter(x => x);
  }

  buttonClick(action: string) {
    this.vibrationService.vibrate();

    this.popoverCtrl.dismiss(action);
  }

}
