import { Field, Message, Type } from 'protobufjs/light';


@Type.d('RuntimeLayoutText')
export class RuntimeLayoutText extends Message<RuntimeLayoutText> {
  @Field.d(1, 'int32', 'required')
  textId: number;
  @Field.d(2, 'string', 'required')
  text: string;
  @Field.d(3, 'int64', 'required')
  tick: number;
}
