export class PlatformResource {

  static readonly resourceMap = {
    1: 'fad fa-right-from-bracket',
    2: 'fad fa-right-to-bracket',
    35: 'fad fa-circle-exclamation',
    36: 'fad fa-alarm-clock',
    37: 'fad fa-light-emergency',
    38: 'fad fa-light-emergency-on',
    39: 'fad fa-business-time',
    40: 'fad fa-glasses',
    41: 'fad fa-scale-balanced',
    42: 'fad fa-table',
    43: 'fad fa-tags',
    44: 'fad fa-wallet',
    46: 'fad fa-fire',
    48: 'fad fa-magnifying-glass-plus',
    50: 'fad fa-wrench',
    51: 'fad fa-comment',
    52: 'fad fa-check',
    53: 'fad fa-circle-check',
    54: 'fad fa-circle-xmark',
    55: 'fad fa-xmark',
    56: 'fat fa-xmark',

    3: 'fad fa-barcode-read',
    4: 'fat fa-barcode-read',
    5: 'fad fa-barcode-scan',
    17: 'fad fa-scanner-gun',

    6: 'fad fa-box',
    7: 'fad fa-box-check',
    8: 'fad fa-box-circle-check',
    9: 'fad fa-boxes-stacked',
    14: 'fad fa-pallet-box',
    15: 'fad fa-pallet',
    18: 'fad fa-shelves',
    19: 'fad fa-shelves-empty',
    49: 'fad fa-route',

    13: 'fad fa-hand-holding-box',
    16: 'fad fa-person-carry-box',
    10: 'fad fa-clipboard-check',
    11: 'fad fa-clipboard-list',
    25: 'fat fa-clipboard-list',
    29: 'fat fa-person-dolly',
    30: 'fad fa-person-dolly-empty',

    12: 'fad fa-forklift',
    20: 'fad fa-truck',
    21: 'fad fa-truck-clock',
    22: 'fad fa-truck-fast',
    26: 'fat fa-forklift',
    28: 'fat fa-truck-fast',
    31: 'fat fa-truck',
    34: 'fad fa-truck-bolt',

    23: 'fad fa-warehouse',
    24: 'fad fa-warehouse-full',
    27: 'fat fa-warehouse',
    45: 'fad fa-industry-windows',
    47: 'fad fa-house',

    32: 'fad fa-starfighter',
    33: 'fad fa-starship',

    60: 'fad fa-print',
    61: 'fad fa-map-location-dot',
    62: 'fad fa-phone',

    63: 'fad fa-mug-hot',
    64: 'fad fa-thumbs-up',
    65: 'fad fa-thumbs-down',

    66: 'fad fa-credit-card',
    67: 'fad fa-images',

    68: 'fad fa-truck-ramp-box',
    69: 'fad fa-trophy-star',

    70: 'fad fa-traffic-light-stop',
    71: 'fad fa-tire-flat',

    72: 'fad fa-temperature-list',
    73: 'fad fa-temperature-arrow-up',
    74: 'fad fa-temperature-arrow-down',

    75: 'fad fa-stairs',
    76: 'fad fa-shop-lock',
    77: 'fad fa-lock-keyhole',
    78: 'fad fa-face-head-bandage',
  };

  resourceId: number;
  description: string;
  uri: string;

  $icon: string;

  constructor(item?: Partial<PlatformResource>) {
    Object.assign(this, item);

    this.$icon = PlatformResource.resourceMap[this.resourceId];
  }
}
