import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { from, Observable, Observer, Subject } from 'rxjs';
import { Notification, RuntimeLayoutNotifyType, RuntimeLayoutScreen } from '../../models';
import { LogUtils, RingBuffer } from '../../utils';
import { TranslateService } from '.';
import { StorageService } from './storage.service';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly storageKeyOccurredNotifys = 'lc_occurredNotifys';
  private readonly storageKeyRuntimeSolutionStartedTick = 'lc_runtimeSolutionStartedTick';
  private readonly storageRingBufferSize = 150;

  private runtimeSolutionStartedTick: number;

  private occurredNotifys: RingBuffer<number>;
  private blockingSubject: Subject<boolean>;
  private notificationSubject: Subject<Notification>;
  private blockingCount: number;

  constructor(
    private alertCtrl: AlertController,
    private storageService: StorageService,
    private toastCtrl: ToastController,
    private translateService: TranslateService,
  ) {
    this.storageService.get(this.storageKeyRuntimeSolutionStartedTick)
      .subscribe((layoutStartedTick: number) => {
        this.runtimeSolutionStartedTick = layoutStartedTick || 0;
      });
    this.storageService.get(this.storageKeyOccurredNotifys)
      .subscribe((localEvents: number[]) => {
        this.occurredNotifys =  RingBuffer.fromPlain(localEvents || [], this.storageRingBufferSize);
      });

    this.blockingSubject = new Subject();
    this.notificationSubject = new Subject();
    this.blockingCount = 0;
  }

  consumeLocalEvents(layoutScreen: RuntimeLayoutScreen, runtimeSolutionStartedTick: number) {
    if (layoutScreen) {
      this.checkRuntimeSolutionStartedTick(runtimeSolutionStartedTick);

      for (const localEvent of layoutScreen.localEvents || []) {
        const notifyObject = !!layoutScreen.notifys && !!layoutScreen.notifys[localEvent.targetObjectId] ?
          layoutScreen.notifys[localEvent.targetObjectId] :
          undefined;
        if (
          //to check if it has been consumed we look at the notify controlObjectId
          notifyObject && !this.hasBeenConsumed(notifyObject.controlObjectId)
        ) {
          this.showNotification({
            id: notifyObject.objectId,
            title: this.getNotificationTitle(notifyObject.notifyType),
            text: notifyObject.text,
            type: notifyObject.notifyType,
            blocking: notifyObject.blocking,
            showTimeInSeconds: notifyObject.showTimeInSeconds,
          });

          this.markAsConsumed(notifyObject.controlObjectId);
        }
      }
    }
  }

  private checkRuntimeSolutionStartedTick(runtimeSolutionStartedTick: number) {
    if (!runtimeSolutionStartedTick || this.runtimeSolutionStartedTick != runtimeSolutionStartedTick) {
      this.runtimeSolutionStartedTick = runtimeSolutionStartedTick;
      this.storageService.set(this.storageKeyRuntimeSolutionStartedTick, this.runtimeSolutionStartedTick).subscribe();

      this.occurredNotifys.length = 0;
      this.storageService.set(this.storageKeyOccurredNotifys, this.occurredNotifys).subscribe();
    }
  }

  private getNotificationTitle(notifyType: RuntimeLayoutNotifyType) {
    switch (notifyType.toString()) {
      case RuntimeLayoutNotifyType.Unknown.toString():
      case RuntimeLayoutNotifyType.Confirmation.toString():
      case RuntimeLayoutNotifyType.VerificationAlert.toString():
      case RuntimeLayoutNotifyType.Alert.toString():
      case RuntimeLayoutNotifyType.CriticalAlert.toString():
        return this.translateService.instant('Notification');
      case RuntimeLayoutNotifyType.CriticalServerError.toString():
        return this.translateService.instant('Application Critical');
    }
  }

  private hasBeenConsumed(notifyControlObjectId: number): boolean {
    return this.occurredNotifys.indexOf(notifyControlObjectId) >= 0;
  }

  private markAsConsumed(notifyControlObjectId: number): void {
    this.occurredNotifys.push(notifyControlObjectId);

    this.storageService.set(this.storageKeyOccurredNotifys, this.occurredNotifys).subscribe();
  }

  showNotification(notification: Notification) {
    if (notification.type === RuntimeLayoutNotifyType.CriticalAlert) {
      LogUtils.error(notification.text || notification.title);
    } else {
      LogUtils.log(notification.text || notification.title);
    }

    this.blockingCount += notification.blocking ? 1 : 0;
    if (this.blockingCount === 1) {
      this.blockingSubject.next(true);
    }

    this.notificationSubject.next(notification);
  }

  getNotifications(): Observable<Notification> {
    return this.notificationSubject as Observable<Notification>;
  }

  getBlockingState(): Observable<boolean> {
    return this.blockingSubject as Observable<boolean>;
  }

  dismissNotification(notification: Notification): void {
    this.blockingCount -= notification.blocking ? 1 : 0;
    if (this.blockingCount === 0) {
      this.blockingSubject.next(false);
    }
  }

  showAlert(
    header: string, msg: string
  ): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      from(this.alertCtrl.create({
        header: header,
        message: msg,
        buttons: [this.translateService.instant('Ok')]
      }))
      .subscribe((alert: HTMLIonAlertElement) => {
        from(alert.onDidDismiss())
        .subscribe(() => {
          observer.next();
          observer.complete();
        });

        alert.present();
      });
    });
  }

  showConfirm(header: string, msg: string, okText?: string, nokText?: string): Observable<HTMLIonAlertElement> {
    const subject = new Subject<HTMLIonAlertElement>();
    const resultSubject = new Subject<boolean>();

    setTimeout(() => {
      this.alertCtrl.create({
        header: header,
        message: msg,
        buttons: [
          nokText === '' ? undefined : {
            text: nokText || this.translateService.instant('Cancel'),
            role: 'cancel',
            handler: () => {
              resultSubject.next(false);
              resultSubject.complete();
            }
          },
          {
            text: okText || this.translateService.instant('Ok'),
            handler: () => {
              resultSubject.next(true);
              resultSubject.complete();
            }
          }
        ].filter(x => x),
        backdropDismiss: false
      }).then((alert: HTMLIonAlertElement) => {
        (alert as any).$result = resultSubject.asObservable();

        alert.present();

        subject.next(alert);
        subject.complete();
      });
    });

    return subject.asObservable();
  }

  showToast(
    msg: string,
    duration = 0,
    position: 'top' | 'bottom' | 'middle' = 'top',
    cssClass?: string,
  ): Observable<HTMLIonToastElement> {
    const subject = new Subject<HTMLIonToastElement>();

    setTimeout(() => {
      this.toastCtrl.create({
        message: msg,
        duration: duration,
        position: position,
        cssClass: cssClass,
      }).then((toast: HTMLIonToastElement) => {
        toast.present();

        subject.next(toast);
        subject.complete();
      });
    });

    return subject.asObservable();
  }

}
