import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutEventSourceType } from './runtime-layout-event-source-type.enum';



@Type.d('RuntimeLayoutChangeActiveScreen')
export class RuntimeLayoutChangeActiveScreen extends Message<RuntimeLayoutChangeActiveScreen> {

  @Field.d(50, 'int64', 'required')
  snapshotTick: number;
  @Field.d(51, RuntimeLayoutEventSourceType, 'required')
  sourceType: RuntimeLayoutEventSourceType;
  @Field.d(52, 'int64', 'required')
  layoutObjectId: number;
  @Field.d(53, 'int64', 'optional')
  layoutScreenObjectId: number;
  @Field.d(54, 'int64', 'optional')
  layoutScreenObjectTick: number;

  @Field.d(63, 'string', 'optional')
  eventDateTime: string;

}
