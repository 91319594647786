<div *ngIf="showOverlay" class="overlay"></div>

<div *ngFor="let notification of notifications"
  class="notification show"
  [ngClass]="notification.type == 1 ? 'confirm' : notification.type == 99 ? 'critical' : 'alert'"
  (click)="dismissNotification(notification)">

  <h3>{{ notification.title }}</h3>

  <p>{{ notification.text }}</p>

  <a href="javascript:void(0);"
    class="button-close">
  </a>

</div>
