<div class="control-visual1">
  <h2 *ngIf="layoutControl.parseRV('ControlHeadlineEnabled')" style="padding-left: 4px;">
    {{ layoutControl.parseRV('ControlHeadlineText') }}
  </h2>

  <gridster *ngIf="visualDefinition"
    class="gridster background"
    [ngStyle]="visualDefinition.style"
    [options]="gridsterOptions">
    <gridster-item *ngFor="let item of visualDefinition.items || []"
      class="gridster-item"
      [ngStyle]="item.itemStyle"
      [item]="item">
      <div class="gridster-item-content">
        <img *ngIf="item.field?.$type === 'image' && item.field.$value"
          class="image"
          [src]="item.field.$value"
          alt="picture" />
        <span *ngIf="item.field && item.field.$type !== 'image'"
          class="label"
          [ngStyle]="item.labelStyle"
          [innerHTML]="item.field.$value != null ? item.field.$value : ''">
        </span><!-- don't ident the above cos the labelStyle may have "white-space: pre;" set -->
      </div>
    </gridster-item>
  </gridster>
</div>
