import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Notification, RuntimeLayoutNotifyType, SolutionDeviceControlScannerEnabledFlagType } from 'src/app/shared/models';
import { BluetoothDevice, BluetoothDeviceType } from 'src/app/shared/models/bluetooth-device.model';
import { KeyboardType } from 'src/app/shared/models/keyboard-type.enum';
import { LogUtils } from 'src/app/shared/utils';
import { DictString, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutValue, RuntimeLayoutValueType } from '../../../models';
import { BasePlugin, PluginType } from '../../../services/plugin';
import { ControlBaseComponent } from '../base/control-base.component';
import { ControlInput1Component } from '../input1/control-input1.component';

@Component({
  selector: 'lc-control-temp1',
  templateUrl: 'control-temp1.component.html',
  styleUrls: ['./control-temp1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlTemp1Component extends ControlBaseComponent implements OnInit {

  @ViewChild('controlManualComponent', { static: false }) controlManualComponent: ControlInput1Component;

  allowManual: boolean;
  autoBluetoothCapture: boolean;
  btPlugin: BasePlugin;
  currentTempData: any;
  isManual: boolean;
  originalForwardButton: boolean;
  staticControlManual: any;
  theme: string;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }


  ngOnInit() {
    if (!this.layoutControl) return;

    this.originalForwardButton = this.layoutScreen.forwardButton;

    this.allowManual = this.layoutControl.parseRV('AllowManual');
    this.autoBluetoothCapture = this.layoutControl.parseRV('AutoBluetoothCapture');

    this.staticControlManual = {
      controlHeadlineEnabled: true,
      controlHeadlineText: this.layoutControl.parseRV('ControlHeadlineText') || this.translateService.instant('Temperature'),
      keyboardType: KeyboardType.Numeric,
    };

    this.btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);
    if (!this.btPlugin.isPluginAllowed()) {
      this.toggleManual();
      this.appService.setStatusBarMessage(this.translateService.instant('Web devices only support manual temperature measurement'));
      return;
    }

    setTimeout(() => {
      this.layoutControl.scannerEnabledType = SolutionDeviceControlScannerEnabledFlagType.Simple;
      this.appService.refreshScannerPlugins();

      this.start();
    }, 250);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.appService.setStatusBarMessage('');
  }

  start() {
    if (!this.btPlugin.isPluginAllowed()) return;

    const btDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.Thermometer]);
    if (!btDevices?.length) {
      this.appService.setStatusBarMessage(this.translateService.instant('No thermometer device configured'));
      setTimeout(() => { // check again later...
        if (this.isDestroyed) return;

        this.start();
      }, 500);
      return;
    }

    this.appService.setStatusBarMessage('');
    this.layoutScreen.forwardButton = true;

    this.btPlugin.action({
      command: 'start',
      callback: (data: any) => {
        this.ngZone.run(() => {
          if (data?.error) {
            this.notificationService.showNotification(new Notification({
              title: this.translateService.instant('Bluetooth'),
              text: data.error,
              type: RuntimeLayoutNotifyType.Alert,
            }));
            return;
          }
          this.currentTempData = data;
          this.cdr.markForCheck();

          if (this.currentTempData.valueType === 'temp-stream' && this.autoBluetoothCapture && this.currentTempData.value) {
            this.sendTemperatureValue('Bluetooth');
          } else if (this.currentTempData.valueType === 'temp-read') {
            LogUtils.log('BT Temperature reading:', this.currentTempData);
            this.sendTemperatureValue('Bluetooth');
          }
        });
      },
      options: {
        stream: true,
        // serviceUUID: BluetoothDevice.HealthThermometerServiceUUID,
        // characteristicUUID: BluetoothDevice.TemperatureStreamCharacteristicUUID,
      }
    }).subscribe();
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};

    context['TemperatureInCelsius'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(this.currentTempData?.value || 0),
      valueTypeId: RuntimeLayoutValueType.Double
    });

    context['BluetoothTemperatureRead'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(!this.isManual),
      valueTypeId: RuntimeLayoutValueType.Bool
    });

    const deviceNameSplit = this.currentTempData?.source?.split(' ');
    if (deviceNameSplit) {
      context['BluetoothDevice'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(deviceNameSplit[0] || ''),
        valueTypeId: RuntimeLayoutValueType.String
      });

      context['BluetoothDeviceID'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(deviceNameSplit[1]),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    return context;
  }

  forwardButtonOverride(): boolean {
    if (!this.isManual) {
      if (this.scanValue != null) { // we want to ignore the temp value that comes from the scannerService (on read)...
        LogUtils.log('Ignoring scanner.simpleCallback()')
        this.scanValue = undefined;
        return true;
      }
      if (this.currentTempData?.valueType === 'temp-stream') { // if not manual and we have a previous stream reading...
        this.sendTemperatureValue('Bluetooth');
        return true;
      }
      return true; // never allow "just" FORWARD without a read value...
    }

    const value = this.controlManualComponent ? RuntimeLayoutValue.parse((this.controlManualComponent.getControlContext() || {}).TextBox) : undefined;
    if (!value) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('Please enter a value!'),
        type: RuntimeLayoutNotifyType.VerificationAlert,
      }));
      return true;
    }

    this.currentTempData = { value: value }
    this.sendTemperatureValue('Manual');
    return true;
  }

  toggleManual() {
    this.isManual = !this.isManual;
    if (this.isManual) this.layoutScreen.forwardButton = true;
    else this.layoutScreen.forwardButton = this.originalForwardButton;

    this.cdr.markForCheck();
  }

  private sendTemperatureValue(portName: string) {
    const eventContextValues: any = {};

    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    });

    this.triggerEvent.emit({
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
  }

}

