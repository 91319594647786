import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutLoadType } from './runtime-layout-load-type.enum';


@Type.d('RuntimeLayoutLoad')
export class RuntimeLayoutLoad extends Message<RuntimeLayoutLoad> {

  @Field.d(3, 'int64', 'optional', 0)
  tick: number;

  @Field.d(50, 'int64', 'optional')
  controlObjectId: number;
  @Field.d(51, 'string', 'optional')
  loadGuidId: string;
  @Field.d(52, 'int32', 'optional')
  loadType: RuntimeLayoutLoadType;
  @Field.d(53, 'float', 'optional')
  value: number;
  @Field.d(54, 'string', 'optional')
  text: string;

}
