import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ControlList1MapComponent } from './control-list1-map.component';


@NgModule({
  declarations: [
    ControlList1MapComponent,
  ],
  exports: [
    ControlList1MapComponent,
  ],
  imports: [
    CommonModule,
  ],
  providers: [],
})
export class ControlList1MapModule { }
