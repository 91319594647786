import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AngularFormsInputMasksModule } from '../../angular-forms-input-masks/angular-forms-input-masks.module';
import { BarcodeScannerLivestreamOverlayModule } from '../../barcode-scanner/barcode-scanner-livestream-overlay/barcode-scanner-livestream-overlay.module';
import { ControlInput1Component } from './control-input1.component';
import { ControlInput1DateTimeModule } from './datetime/control-input1-datetime.module';


@NgModule({
  declarations: [
    ControlInput1Component,
  ],
  exports: [
    ControlInput1Component,
  ],
  imports: [
    AngularFormsInputMasksModule,
    SharedModule,

    BarcodeScannerLivestreamOverlayModule,
    ControlInput1DateTimeModule,
  ],
  providers: [],
})
export class ControlInput1Module { }
