import { DesignStyleJsonItem } from './design-style-json-item.model';

export class DesignStyleJson {

  static readonly latestVersion = 3;

  cols?: number; // deprecated with v2 (Gridster)
  rows?: number; // deprecated with v2 (Gridster)

  screenRows: number;
  style: Partial<CSSStyleDeclaration>;

  items: DesignStyleJsonItem[];
  properties: any;

  versionNumber: number;

  constructor(item?: any) {
    Object.assign(this, item);

    this.items = (item.items || []).filter((i: any) => {
      return i.field && i.field.webFieldValueType;
    }).map((i: any) => {
      return new DesignStyleJsonItem(i, this.versionNumber);
    });

    this.upgradeToLatestVersion(); // parse from whatever version and "upgrade" it to the latest
  }

  static parseDesignStyleJson(styleJson: string) {
    if (styleJson && typeof styleJson === 'string') {
      return new DesignStyleJson(JSON.parse(styleJson));
    } else if (styleJson) {
      return new DesignStyleJson(styleJson);
    } else {
      return null;
    }
  }

  private upgradeToLatestVersion() {
    if (this.versionNumber == null || this.versionNumber < 2) {
      delete this.cols; // we don't need this anymore with Gridster...
      delete this.rows; // we don't need this anymore with Gridster...
    } else if (this.versionNumber === 2) {
      // Gridster FTW!!!
    } else if (this.versionNumber === 3) {
    }

    this.versionNumber = DesignStyleJson.latestVersion;
  }

}
