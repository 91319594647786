import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LayoutCoreMessage, LayoutMessageType } from '../../models';
import { RuntimeLayoutResourceUploadRequest } from '../../models/runtime-layout/resource/runtime-layout-resource-upload-request.model';
import { RuntimeLayoutResourceUploadResponse } from '../../models/runtime-layout/resource/runtime-layout-resource-upload-response.model';
import { HeartbeatService } from '../app/heartbeat.service';
import { BusyService } from '../busy/busy.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutResourceUploadService {

  private readonly chunkSize = 1024 * 100;
  private readonly timeoutInMs = 5 * 1000;

  constructor(
    private busyService: BusyService,
    private heartbeatService: HeartbeatService,
    private webSocketClientService: WebSocketClientService
  ) { }

  upload(
    contentType: string,
    content: Uint8Array,
    chunkId = 0,
    guidId?: string,
  ): Observable<string> {
    return new Observable((observer: Observer<any>) => {
      this.busyService.setBusy(true, 'Uploading resource...');

      const chunk = content.slice(chunkId * this.chunkSize, (chunkId * this.chunkSize) + this.chunkSize);

      const msgContent = new RuntimeLayoutResourceUploadRequest({
        contentType: contentType,
        totalSize: content.length,
        guidId: guidId,
        chunkSize: this.chunkSize,
        chunkId: chunkId,
        chunk: chunk,
      });
      // LogUtils.log('Uploading resource:', msgContent);
      const msgContentBuffer = RuntimeLayoutResourceUploadRequest.encode(msgContent).finish();

      const coreMsg = new LayoutCoreMessage({
        messageSequenceNr: this.webSocketClientService.getSequenceNumber(),
        messageType: LayoutMessageType.ResourceUploadRequest,
        messageContent: msgContentBuffer,
      });
      const coreMsgBuffer  = LayoutCoreMessage.encode(coreMsg).finish();

      const subscription = this.webSocketClientService
      .getMessages$(coreMsg.messageSequenceNr)
      .pipe(delay(10))
      .subscribe((msg: LayoutCoreMessage) => {
        this.handleIncomingMessage(msg, observer, subscription, contentType, content, chunkId+1);
      }, (error: any) => {
        this.handleError(subscription, observer, error);
      });

      this.heartbeatService.scheduleNextHeartbeat(this.timeoutInMs);
      this.webSocketClientService.send(coreMsgBuffer);
    });
  }

  private handleIncomingMessage(
    msg: LayoutCoreMessage,
    observer: Observer<any>,
    subscription: Subscription,
    contentType: string,
    content: Uint8Array,
    chunkId: number,
  ) {
    if (!subscription || subscription.closed) return;

    if (!msg) {
      this.busyService.setBusy(false);
      observer.next(null);
      observer.complete();

      subscription.unsubscribe();
      subscription = null;
      return;
    }

    const response = RuntimeLayoutResourceUploadResponse.decode(msg.messageContent);
    if (response?.success) {
      if (response.successComplete) {
        this.busyService.setBusy(false);
        observer.next(response.guidId);
        observer.complete();
      } else {
        this.upload(contentType, content, chunkId, response.guidId)
        .subscribe((resourceGuidId: string) => {
          observer.next(resourceGuidId);
          observer.complete();
        });
      }
    } else if (response?.failedReupload) {
      this.upload(contentType, content, 0)
      .subscribe((resourceGuidId: string) => {
        observer.next(resourceGuidId);
        observer.complete();
      });
    } else {
      this.busyService.setBusy(false);
      observer.next(null);
      observer.complete();
    }

    subscription.unsubscribe();
    subscription = null;
  }

  private handleError(subscription: Subscription, observer: Observer<any>, error: any) {
    if (!subscription || subscription.closed) return;

    this.busyService.setBusy(false);

    subscription.unsubscribe();
    subscription = null;

    observer.error(error);
  }

}
