export const BARCODE_TYPES = [
    'code_128',
    // 'code_32',
    'code_39',
    // 'code_39_vin',
    'code_93',
    'ean',
    // 'ean_2',
    // 'ean_5',
    'ean_8',
    'upc',
    'upc_e',
    // 'codabar',
    // 'i2of5',
];
