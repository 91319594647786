import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlExternal1Component } from './control-external1.component';


@NgModule({
  declarations: [
    ControlExternal1Component,
  ],
  exports: [
    ControlExternal1Component,
  ],
  imports: [
    SharedModule,

  ],
  providers: [],
})
export class ControlExternal1Module { }
