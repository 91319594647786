export enum RuntimeLayoutEventPlatformObjectType {
  Unknown = 0,
  BackButton = 1,
  ForwardButton = 2,
  Action = 3,
  LogOff = 4,
  MainMenu = 5,
  Scanner = 6,
  Setting = 7,
  Menu = 8,
  Trigger = 9,
}
