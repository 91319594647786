import { ChecklistCheck } from './checklist-check.model';


export class ChecklistGroup {

  name: string;
  description: string;
  checks: ChecklistCheck[];

  constructor(item?: Partial<ChecklistGroup>) {
    Object.assign(this, item);
  }

}
