<div class="grid-container"
  (window:resize)="refreshCanvas()">
  <div class="control-sog1">
    <h2 *ngIf="staticControl?.controlHeadlineEnabled || layoutControl?.parseRV('ControlHeadlineEnabled')"
      style="padding-left: 4px;">
      {{ staticControl?.controlHeadlineText || layoutControl.parseRV('ControlHeadlineText') }}
    </h2>

    <div class="signature">
      <span class="agreement">
        {{ agreementText }}
      </span>

      <span class="hint">
        {{ helpText }}
      </span>

      <signature-pad
        [options]="options"
        (onBeginEvent)="drawStart()"
        (onEndEvent)="drawStop()">
      </signature-pad>

      <div *ngIf="b64Image"
        class="delete"
        (click)="deleteSignature()">
        <ion-icon name="trash"></ion-icon>
      </div>
    </div>
  </div>
</div>