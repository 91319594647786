import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { from, Observable, of, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DeviceEnrollment, HostRestartResponse } from '../../../models';
import { LocalSettingsService } from '../../local-settings/local-settings.service';
import { ApiService } from '../api.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { StorageService } from '../../app/storage.service';




@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private readonly urlSuffixPlaceholder = 'device/{what}';
  private readonly storageKey = 'lc_deviceEnrollment';
  private readonly storageKeyResources = 'lc_resources';

  constructor(
    private apiService: ApiService,
    private localSettingsService: LocalSettingsService,
    private storageService: StorageService,
  ) { }

  web(runCode?: string, runSet?: string, runSetSubKey?: string): Observable<DeviceEnrollment> {
    this.apiService.setApiUrl(environment.apiUrl, true);

    return from(FingerprintJS.load({ monitoring: false }))
    .pipe(
      mergeMap((agent: any) => {
        return from(agent.get())
      }),
      mergeMap((result: any) => {
        const fingerprint = result.visitorId;
        return this.apiService.post<DeviceEnrollment>(
          this.urlSuffixPlaceholder.replace('{what}', 'web'),
          {
            clientRunEngineGuidId: environment.clientRunEngineGuidId,
            fingerprint: fingerprint,
            runCode: runCode,
            runSet: runSet,
            runSetSubKey: runSetSubKey,
          }
        )
      }),
      mergeMap((response: any) => {
        const deviceEnrollment: DeviceEnrollment = response?.runDeviceEnrollment || {};
        deviceEnrollment.$deviceRunStatus = response.deviceRunStatus;
        deviceEnrollment.$deviceOemGuidId = undefined;
        deviceEnrollment.$enrollmentKey = undefined;
        deviceEnrollment.$runCode = runCode;
        deviceEnrollment.$runSet = runSet;
        deviceEnrollment.$runSetSubKey = runSetSubKey;

        return this.storageService.get(this.storageKeyResources)
        .pipe(
          mergeMap((resourceGuidIds: string[]) => {
            resourceGuidIds = resourceGuidIds || [];
            return zip(
              this.storageService.remove(this.storageKeyResources),
              ...resourceGuidIds.map((resourceGuidId: string) => {
                return this.storageService.remove(this.storageKeyResources + '_' + resourceGuidId)
              })
            );
          }),
          map(() => {
            return deviceEnrollment;
          })
        );
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment) => {
        return this.storageService.set(this.storageKey, deviceEnrollment)
        .pipe(
          map(() => {
            return deviceEnrollment;
          })
        );
      })
    );
  }

  restart(enrollmentGuidId: string, factoryReset?: boolean): Observable<HostRestartResponse> {
    return this.apiService.post<HostRestartResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'restart'),
      {
        enrollmentGuidId: enrollmentGuidId,
        clientRunEngineGuidId: environment.clientRunEngineGuidId,
        runDeviceDebug: this.localSettingsService.get().runDeviceDebug,
        factoryReset: factoryReset,
      }
    );
  }

}
