import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { BusyService } from '../busy/busy.service';




@Injectable({
  providedIn: 'root'
})
export class JsPluginLoaderService {

  private plugins = {
    cortex: {
      loaded: false,
      src: [
        'assets/js-plugins/cortex/CortexDecoderWeb.js',
      ],
    },
    leaflet: {
      loaded: false,
      src: [
        'assets/js-plugins/leaflet/leaflet.js',
        'assets/js-plugins/leaflet/L.Control.Locate.js',
        'assets/js-plugins/leaflet/L.Control.LocateEx.js',
        'assets/js-plugins/leaflet/rastercoords.js',
        'assets/js-plugins/leaflet/svg-icon.js',
      ],
    },
  };

  constructor(
    private busyService: BusyService,
  ) {

  }

  load(pluginKey: string): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      const plugin = this.plugins[pluginKey];
      if (!plugin.loaded) {
        plugin.loaded = true;

        const hostElement = document.getElementById('js-plugin-scripts');

        if (typeof plugin.src === 'string') {
          this.loadScripts(hostElement, [plugin.src], observer);
        } else {
          this.loadScripts(hostElement, plugin.src, observer);
        }
      } else {
        setTimeout(() => {
          observer.next(true);
          observer.complete();
        });
      }
    });
  }

  private loadScripts(hostElement: any, scripts: string[], observer: Observer<boolean>): void {
    if (scripts?.length) {
      this.busyService.setBusy(true);

      const src = scripts.splice(0, 1)[0];
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = src;
      scriptElement.onload = () => {
        console.log(src);
        this.loadScripts(hostElement, scripts, observer);
      }
      scriptElement.onerror = (error: any) => {
        console.error(`error loading ${src}`);
        this.busyService.setBusy(false);
        observer.next(false);
        observer.complete();
      };
      hostElement.appendChild(scriptElement);
    } else {
      this.busyService.setBusy(false);
      observer.next(true);
      observer.complete();
    }
  }
}
