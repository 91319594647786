import { Field, Message, Type } from 'protobufjs/light';


@Type.d('RuntimeLayoutHeadPointer')
export class RuntimeLayoutHeadPointer extends Message<RuntimeLayoutHeadPointer> {

  @Field.d(50, 'int64', 'required')
  objectId: number;

}
