import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { DictString, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutValue, RuntimeLayoutValueType } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
import * as swishQrcodeSvg from 'swish-qrcode-svg';
import { from } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'lc-control-swish-qr1',
  templateUrl: 'control-swish-qr1.component.html',
  styleUrls: ['./control-swish-qr1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlSwishQr1Component extends ControlBaseComponent implements OnInit {

  theme: string;

  swishAmount: number;
  swishAmountCurrency: string;
  swishMessage: string;
  swishNumber: string;
  swishBase64: string;
  swishSvg: SafeHtml;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.swishAmount = this.layoutControl.parseRV('Amount');
    this.swishAmountCurrency = this.layoutControl.parseRV('AmountCurrency', '');
    this.swishMessage = this.layoutControl.parseRV('Message', '');
    const messageEditable = this.layoutControl.parseRV('MessageEditable', false);
    this.swishNumber = this.layoutControl.parseRV('SwishNumber', '');

    const barcodePreRendered = this.layoutControl.parseRV('BarcodePreRendered');
    if (barcodePreRendered) {
      this.swishBase64 = this.layoutControl.parseRV('SwishBase64');
    } else {
      const swishString = this.generateString({
        amount: this.swishAmount,
        lock: ['amount', 'number', !messageEditable ? 'message' : undefined].filter(x => x),
        message: this.swishMessage,
        number: this.swishNumber,
      });

      this.swishSvg = this.domSanitizer.bypassSecurityTrustHtml(
        swishQrcodeSvg(
          swishString,
          { backgroundColor: 'white', margin: 4, size: undefined },
        )
      );
      this.cdr.markForCheck();
    }
  }

  forwardButtonOverride(): boolean {
    const eventContextValues: any = {};

    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify('Payed'),
      valueTypeId: RuntimeLayoutValueType.String,
    });

    this.triggerEvent.emit({
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
    return true;
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};

    // context['Swish'] = new RuntimeLayoutValue({
    //   valueJson: JSON.stringify(this.swishConfirmation || 0),
    //   valueTypeId: RuntimeLayoutValueType.Double
    // });

    if (this.layoutControl?.parseRV('EventGps')) {
      context['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    return context;
  }

  private generateString(options: any) {
    options = Object.assign({
      amount: 0,
      lock: [],
      message: '',
      number: ''
    }, options);

    return `C${options.number};${options.amount};${options.message};${this.mapLock(options.lock)}`;
  }

  private mapLock(lockArray: string[]) {
    let ret = 7;

    if (!Array.isArray(lockArray) || lockArray.length === 0) {
      return ret;
    }

    if (lockArray.indexOf('amount') !== -1) {
      ret -= 2;
    }

    if (lockArray.indexOf('message') !== -1) {
      ret -= 4;
    }

    if (lockArray.indexOf('number') !== -1) {
      ret -= 1;
    }

    return ret;
  }

}

