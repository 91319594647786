import { Injectable, Injector } from '@angular/core';
import { BasePlugin } from './base-plugin';
import { BluetoothLEPlugin } from './bluetooth/bluetooth-le.plugin';
import { BluetoothSerialPlugin } from './bluetooth/bluetooth-serial.plugin';
import { BluetoothPlugin } from './bluetooth/bluetooth.plugin';
import { NfcPlugin } from './nfc/nfc.plugin';
import { ZebraPlugin } from './zebra/zebra.plugin';
import { SatoPlugin } from './sato/sato.plugin';
import { PointMobilePlugin } from './point-mobile/point-mobile.plugin';
import { HoneywellPlugin } from './honeywell/honeywell.plugin';
import { CipherLabPlugin } from './cipherlab/cipherlab.plugin';
import { OpticonPlugin } from './opticon/opticon.plugin';

export enum PluginType {
  Bluetooth,
  BluetoothLE,
  BluetoothSerial,
  CipherLab,
  Honeywell,
  NFC,
  Opticon,
  PointMobile,
  Zebra,
  Sato,
}

@Injectable({
  providedIn: 'root'
})
export class PluginService {

  private pluginInstances = new Map<PluginType, BasePlugin>();

  constructor(
    private injector: Injector,
  ) {
  }

  getInstance(pluginType: PluginType) {
    let instance = this.pluginInstances.get(pluginType);

    if (instance) return instance;

    switch (pluginType) {
      case PluginType.Bluetooth:
        instance = this.injector.get(BluetoothPlugin);
        break;
      case PluginType.BluetoothLE:
        instance = this.injector.get(BluetoothLEPlugin);
        break;
      case PluginType.BluetoothSerial:
        instance = this.injector.get(BluetoothSerialPlugin);
        break;
      case PluginType.CipherLab:
        instance = this.injector.get(CipherLabPlugin);
        break;
      case PluginType.Honeywell:
        instance = this.injector.get(HoneywellPlugin);
        break;
      case PluginType.NFC:
        instance = this.injector.get(NfcPlugin);
        break;
      case PluginType.Opticon:
        instance = this.injector.get(OpticonPlugin);
        break;
      case PluginType.PointMobile:
        instance = this.injector.get(PointMobilePlugin);
        break;
      case PluginType.Zebra:
        instance = this.injector.get(ZebraPlugin);
        break;
      case PluginType.Sato:
        instance = this.injector.get(SatoPlugin);
        break;
    }
    this.pluginInstances.set(pluginType, instance);
    return instance;
  }


}
