import { Field, Message, Type } from 'protobufjs/light';

@Type.d('RuntimeLayoutResourceRequest')
export class RuntimeLayoutResourceRequest extends Message<RuntimeLayoutResourceRequest> {

  // @Field.d(1, 'int64', 'required')
  // objectId: number;
  // @Field.d(2, 'bool', 'optional')
  // active: boolean;
  // @Field.d(3, 'int64', 'required')
  // tick: number;

  @Field.d(20, 'int64', 'optional')
  resourceId: number;
  @Field.d(21, 'string', 'optional')
  guidId: string;
  @Field.d(22, 'int64', 'optional', 0)
  tick: number;


  @Field.d(40, 'int64', 'required')
  chunkSize: number;
  @Field.d(41, 'int32', 'required')
  chunkId: number;
  @Field.d(42, 'bool', 'required')
  useChunks: boolean;

}
