import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutSettingType } from './runtime-layout-setting-type.enum';



@Type.d('RuntimeLayoutSetting')
export class RuntimeLayoutSetting extends Message<RuntimeLayoutSetting> {

  @Field.d(100, 'string', 'optional')
  name: string;
  @Field.d(101, 'int32', 'optional')
  settingType: RuntimeLayoutSettingType;
  @Field.d(102, 'string', 'optional')
  value: string;

}
