import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlInput1Module } from '../control/input1/control-input1.module';
import { ScannerDrawerComponent } from './scanner-drawer.component';


@NgModule({
  declarations: [
    ScannerDrawerComponent,
  ],
  exports: [
    ScannerDrawerComponent,
  ],
  imports: [
    SharedModule,

    ControlInput1Module,
  ],
  providers: [],
})
export class ScannerDrawerModule { }
