import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CriticalErrorMessage, DictNumber, LayoutCoreMessage, LayoutMessageResult, LayoutMessageType, RuntimeLayoutEvent, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutEventSourceType, RuntimeLayoutSnapshot } from '../../models';
import { LogUtils } from '../../utils';
import { HeartbeatService } from '../app/heartbeat.service';
import { BusyService } from '../busy/busy.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';
import { ClientAuthService } from './client-auth.service';


@Injectable({
  providedIn: 'root'
})
export class LayoutEventService {

  private readonly timeoutInMs = 5 * 1000;

  constructor(
    private busyService: BusyService,
    private clientAuthService: ClientAuthService,
    private heartbeatService: HeartbeatService,
    private webSocketClientService: WebSocketClientService
  ) { }

  trigger(
    layoutSnapshot: RuntimeLayoutSnapshot,
    controlsContext: DictNumber<RuntimeLayoutEventContext>,
    eventContext: RuntimeLayoutEventContext,
    platformObjectType: RuntimeLayoutEventPlatformObjectType,
    platformObjectGuidId?: string,
  ): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      this.busyService.setBusy(true, 'Sending event to server...');

      const rle = this.buildRuntimeLayoutEvent(
        layoutSnapshot,
        controlsContext,
        eventContext,
        platformObjectType,
        platformObjectGuidId
      );
      const msgContent = new RuntimeLayoutEvent(rle);
      LogUtils.log('Sending event to server:', msgContent);
      const msgContentBuffer = RuntimeLayoutEvent.encode(msgContent).finish();

      const coreMsg = new LayoutCoreMessage({
        messageSequenceNr: this.webSocketClientService.getSequenceNumber(),
        messageType: LayoutMessageType.LayoutEvent,
        messageContent: msgContentBuffer,
      });
      const coreMsgBuffer  = LayoutCoreMessage.encode(coreMsg).finish();

      const subscription = this.webSocketClientService
      .getMessages$(coreMsg.messageSequenceNr)
      .pipe(delay(10))
      .subscribe((msg: LayoutCoreMessage) => {
        this.handleIncomingMessage(msg, observer, subscription);
      }, (error: any) => {
        this.handleError(subscription, observer, error);
      });

      this.heartbeatService.scheduleNextHeartbeat(this.timeoutInMs);
      this.webSocketClientService.send(coreMsgBuffer);
    });
  }

  private handleIncomingMessage(
    msg: LayoutCoreMessage, observer: Observer<any>, subscription: Subscription
  ) {
    this.busyService.setBusy(false);
    if (!subscription || subscription.closed) return;

    if (msg?.messageType === LayoutMessageType.CriticalError) {
      const error = CriticalErrorMessage.decode(msg.messageContent);
      observer.error(error);
    } else {
      observer.next(msg?.messageResult === LayoutMessageResult.Success);
      observer.complete();
    }

    subscription.unsubscribe();
    subscription = null;
  }

  private handleError(subscription: Subscription, observer: Observer<any>, error: any) {
    if (!subscription || subscription.closed) return;

    this.busyService.setBusy(false);

    subscription.unsubscribe();
    subscription = null;

    observer.error(error);
  }

  private buildRuntimeLayoutEvent(
    layoutSnapshot: RuntimeLayoutSnapshot,
    controlsContext: DictNumber<RuntimeLayoutEventContext>,
    eventContext: RuntimeLayoutEventContext,
    platformObjectType: RuntimeLayoutEventPlatformObjectType,
    platformObjectGuidId: string,
  ): RuntimeLayoutEvent {
    let eventDateTime = Date.now();
    eventDateTime += (this.clientAuthService.deviceDateTimeMillisecondsDiff || 0);
    return new RuntimeLayoutEvent({
      sourceType: RuntimeLayoutEventSourceType.Active,
      snapshotTick: layoutSnapshot.snapshotTick,
      layoutObjectId: layoutSnapshot.runtimeLayout.objectId,
      screenObjectId: layoutSnapshot.runtimeLayout.activeScreen,
      screenObjectTick: layoutSnapshot.runtimeLayout.layoutScreens[layoutSnapshot.runtimeLayout.activeScreen].tick,
      platformObjectType: platformObjectType,
      platformObjectGuidId: platformObjectGuidId,

      controlContexts: controlsContext,
      eventContext: eventContext,
      eventDateTime: (new Date(eventDateTime)).toISOString(),
    });
  }

}
