import { Field, MapField, Message, Type } from 'protobufjs/light';
import { DictString } from '../../dict.model';
import { RuntimeLayoutValue } from '../runtime-layout-value.model';


@Type.d('RuntimeLayoutHead')
export class RuntimeLayoutHead extends Message<RuntimeLayoutHead> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(50, 'int64', 'required')
  runtimeHeadObjectId: number;
  @Field.d(51, 'int64', 'optional')
  sourceRuntimeObjectId: number;
  @Field.d(52, 'int32', 'required')
  headSequenceNr: number;
  @Field.d(53, 'int64', 'required')
  headDesignStyleObjectId: number;
  @MapField.d(54, 'string', RuntimeLayoutValue)
  renderValues: DictString<RuntimeLayoutValue>;

  scannerEnabledType: any; // only here to keep interface compatibility with layoutControl...remove when refactoring code

  parseRV(key: string, defaultValue?: any): any {
    let value = null;
    if (this.renderValues && this.renderValues[key]) {
      value = this.renderValues[key].parse();
    }
    return (value !== null && value !== undefined) ? value : defaultValue;
  }

}
