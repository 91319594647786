import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PluginService, PluginType, VibrationService } from 'src/app/shared/services';
import { BrowserUtils } from 'src/app/shared/utils';



@Component({
  selector: 'lc-bluetooth-menu-popover',
  templateUrl: 'bluetooth-menu.popover.html',
  styleUrls: ['./bluetooth-menu.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BluetoothMenuPopover implements OnInit {

  constructor(
    private pluginService: PluginService,
    private popoverCtrl: PopoverController,
    private vibrationService: VibrationService,
  ) {

  }

  ngOnInit(): void {
    if (!BrowserUtils.isDeviceApp()) return;

    // we don't really need to "list" devices at this point, but this will trigger the android permission prompt (if required)
    const btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);
    btPlugin.status()
    .subscribe((status: any) => {
      btPlugin.initialize({ forceEnable: true })
      .pipe(
        mergeMap(() => {
          return status.btClassic?.enabled ? btPlugin.action({ command: 'list' }) : of(null);
        })
      ).subscribe();
    });

  }

  goTo(page: string) {
    this.vibrationService.vibrate();

    this.popoverCtrl.dismiss(page);
  }

  openModal() {
    this.vibrationService.vibrate();

    this.popoverCtrl.dismiss();
  }

}
