import { Field, Message, Type } from 'protobufjs/light';


@Type.d('RuntimeLayoutResourceUploadResponse')
export class RuntimeLayoutResourceUploadResponse extends Message<RuntimeLayoutResourceUploadResponse> {

  // @Field.d(1, 'int64', 'required')
  // objectId: number;
  // @Field.d(2, 'bool', 'optional')
  // active: boolean;
  // @Field.d(3, 'int64', 'required')
  // tick: number;

  @Field.d(21, 'string', 'required')
  guidId: string;
  @Field.d(22, 'int64', 'optional', 0)
  tick: number;

  @Field.d(30, 'bool', 'optional')
  success: boolean;
  @Field.d(31, 'bool', 'optional')
  successComplete: boolean;
  @Field.d(32, 'bool', 'optional')
  failedReupload: boolean;

}
