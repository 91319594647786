import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Notification, RuntimeLayoutNotifyType } from 'src/app/shared/models';
import { LogUtils } from 'src/app/shared/utils';
import { DictString, RuntimeLayoutValue } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';


@Component({
  selector: 'lc-control-update1',
  templateUrl: 'control-update1.component.html',
  styleUrls: ['./control-update1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlUpdate1Component extends ControlBaseComponent implements OnInit, OnDestroy {

  theme: string;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    LogUtils.warn('Not supported control: ', this.layoutControl);

    this.notificationService.showNotification(new Notification({
      title: this.translateService.instant('Control not supported'),
      text: this.translateService.instant('Please update the LogicCenter application to get the latest functionality, or contact support.'),
      type: RuntimeLayoutNotifyType.Unknown,
      blocking: true,
    }));
    this.cdr.markForCheck();
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};

    return context;
  }

}

