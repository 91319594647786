
export class Resource {

  guidId: string;
  content: Uint8Array;
  contentType: string;
  tick: number;

  constructor(item?: Partial<Resource>) {
    Object.assign(this, item);
  }

}
