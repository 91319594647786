import { Observable } from 'rxjs';

export enum WebSocketConnectionStatus {
  Closed = 0,
  Open = 1,
}

export interface WebSocketConnection {
  connectionStatus: Observable<number>,
  messages: Observable<Uint8Array>,
}
