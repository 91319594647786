import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutDebugLog } from './runtime-layout-debug-log.model';



@Type.d('RuntimeLayoutDebugLogMessage')
export class RuntimeLayoutDebugLogMessage extends Message<RuntimeLayoutDebugLogMessage> {

  @Field.d(20, RuntimeLayoutDebugLog, 'repeated')
  logs: RuntimeLayoutDebugLog[];

}
