<div class="popover-grid-container">
  <h2>
    {{ 'Enrollment' | translate }}
  </h2>

  <div>
    <button class="button"
      (click)="goTo('enroll-new')">
      {{ 'New' | translate }}
    </button>

    <button class="button"
      disabled style="opacity: .5;"
      (click)="goTo('enroll-list')">
      {{ 'List' | translate }}
    </button>
  </div>
</div>