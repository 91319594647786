import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import * as Sentry from '@sentry/browser';
import { from } from 'rxjs';
import { ActionPopover } from 'src/app/popovers';
import { FooterComponent, HeaderComponent, KeyboardComponent, KeyboardService } from 'src/app/shared/components';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ControlPrint1Component } from 'src/app/shared/components/control/print1/control-print1.component';
import { DictNumber, EventObject, LAYOUT_DUAL1, LAYOUT_FULL1, Notification, RuntimeLayout, RuntimeLayoutControl, RuntimeLayoutControlCode, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutNotifyType, RuntimeLayoutScreen, RuntimeLayoutSnapshot, RuntimeLayoutValue, RuntimeLayoutValueType, VerificationType } from 'src/app/shared/models';
import { BluetoothDeviceType } from 'src/app/shared/models/bluetooth-device.model';
import { AppService, CheckVersionService, LayoutEventService, LocalSettingsService, NotificationService, PluginService, PluginType, ScannerService } from 'src/app/shared/services';
import { GeolocationService, TranslateService } from 'src/app/shared/services/app';
import { LogUtils } from 'src/app/shared/utils';
import satoApi from 'webaep-api';
import { ScreenControlContainerComponent } from './components/screen';
import { ScreenBaseComponent } from './components/screen/base/screen-base.component';


export enum ClientSetting {
  HasBluetoothTemperature = 'HasBluetoothTemperature',
  HasCamera = 'HasCamera',
  HasPrinter = 'HasPrinter',
  HasRfidPrinter = 'HasRfidPrinter',
  PrinterDpi = 'PrinterDpi',
}


@Component({
  selector: 'lc-main-page',
  templateUrl: 'main.page.html',
  styleUrls: ['./main.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainPage extends BaseComponent implements OnInit {

  readonly FULL1 = LAYOUT_FULL1;
  readonly DUAL1 = LAYOUT_DUAL1;

  @ViewChild(FooterComponent, { static: true }) footerComponent: FooterComponent;
  @ViewChild(HeaderComponent, { static: true }) headerComponent: HeaderComponent;
  @ViewChild('screenComponent', { static: false }) screenComponent: ScreenBaseComponent;
  @ViewChild(KeyboardComponent, { static: true }) keyboardComponent: KeyboardComponent;

  private clientSettingValues: any;
  previousTriggerEventTick: number;
  layoutSnapshot: RuntimeLayoutSnapshot;
  layout: RuntimeLayout;
  layoutScreen: RuntimeLayoutScreen;
  layoutControl: RuntimeLayoutControl;

  bufferSendTimeout: any;
  showScannerDrawer: boolean;
  inactivityTriggerPreviousTick: number;
  inactivityTriggerTimeout: any;

  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private checkVersionService: CheckVersionService,
    private geolocationService: GeolocationService,
    private keyboardService: KeyboardService,
    private layoutEventService: LayoutEventService,
    private localSettingsService: LocalSettingsService,
    private ngZone: NgZone,
    private notificationService: NotificationService,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private pluginService: PluginService,
    private router: Router,
    private scannerService: ScannerService,
    private translateService: TranslateService,
  ) {
    super();

    this.subscriptions.push(
      this.router.events
      .subscribe((e: any) => {
        if (e instanceof NavigationEnd && e.url.indexOf('/main') >= 0) {
          this.refresh();
        }
      }),
      this.appService.listenToBackButtonClick()
      .subscribe(() => {
        this.platformBackButtonAction();
      })
    );
  }

  ngOnInit() {
    this.checkVersionService.check();

    this.refresh();
  }

  refresh() {
    if (!this.appService.isInitialized) {
      setTimeout(() => {
        this.refresh();
      }, 100);
      return;
    }

    const cipherLabPlugin = this.pluginService.getInstance(PluginType.CipherLab);
    const honeywellPlugin = this.pluginService.getInstance(PluginType.Honeywell);
    const opticonPlugin = this.pluginService.getInstance(PluginType.Opticon);
    const pointMobilePlugin = this.pluginService.getInstance(PluginType.PointMobile);
    const zebraPlugin = this.pluginService.getInstance(PluginType.Zebra);
    this.showScannerDrawer = !cipherLabPlugin.isPluginAllowed()
    && !honeywellPlugin.isPluginAllowed()
    && !opticonPlugin.isPluginAllowed()
    && !pointMobilePlugin.isPluginAllowed()
    && !zebraPlugin.isPluginAllowed();

    this.keyboardService.init(this.platform, this.keyboardComponent, 'lc-main-page main');

    this.clearBufferSendTimeout();

    this.layoutSnapshot = this.appService.getLayoutSnapshot();

    let newLayout;
    let newLayoutScreen;
    let newLayoutControl: RuntimeLayoutControl;
    if (this.layoutSnapshot?.runtimeLayout?.layoutScreens) {
      newLayout = this.layoutSnapshot.runtimeLayout;
      newLayoutScreen = newLayout.layoutScreens[newLayout.activeScreen];
      newLayoutControl = newLayoutScreen?.controls?.[newLayoutScreen.primaryLayoutControlObjectId];

      if (newLayoutControl?.layoutControlCode === RuntimeLayoutControlCode.RequestClientSetting1) {
        // if we get a RequestClientSetting1 control, we don't want to update the screen with a new control, simply send the settings to the server
        this.handleRequestClientSetting(newLayoutControl);
        return;
      }

      if (
        (this.layoutScreen && (!newLayoutScreen || this.layoutScreen.objectId !== newLayoutScreen.objectId))
        || (this.layoutControl && (!newLayoutControl || this.layoutControl.objectId !== newLayoutControl.objectId))
      ) {
        if (this.layoutControl.layoutControlCode === RuntimeLayoutControlCode.RfidScan) this.scannerService.clear();
        if (this.inactivityTriggerTimeout) clearTimeout(this.inactivityTriggerTimeout);

        this.layout = undefined;
        this.layoutScreen = undefined;
        this.layoutControl = undefined;

        if (this.keyboardService.isVisible() && !newLayoutControl.parseRV('KeyboardAutoEnable')) {
          this.keyboardService.hide();
        }

        this.clearInactivityTimeout();
        this.cdr.markForCheck();
      }
    }

    setTimeout(() => {
      if (!this.layoutSnapshot) return;

      if (this.layout && this.layoutScreen && this.layoutControl) {
        // LogUtils.warn('Refreshing the same control / scannerPlugins!');
        this.appService.refreshScannerPlugins();
        setTimeout(() => {
          this.screenComponent?.controlComponents?.forEach((cc: ScreenControlContainerComponent) => {
            cc.controlComponent?.refresh();
          });
        }, 10);
      }

      this.layout = newLayout;
      this.layoutScreen = newLayoutScreen;
      this.layoutControl = newLayoutControl;

      if (this.layout && this.layoutScreen && this.layoutControl) {
        this.notificationService.consumeLocalEvents(this.layoutScreen, this.layout.runtimeSolutionStartedTick);
        this.checkInactivityTriggers();
      } else {
        LogUtils.error('Received an invalid / empty LayoutSnapshot!', this.layoutSnapshot);

        Sentry.setExtra('layoutSnapshot', this.layoutSnapshot);
        Sentry.setExtra('log', LogUtils.getLogArray());
        Sentry.captureException(new Error('Received an invalid / empty LayoutSnapshot!'));
      }

      this.cdr.markForCheck();

      setTimeout(() => {
        // sometimes the topleft menu doesn't refresh, so force it...
        this.headerComponent.refresh();
      }, 10);
    }, 10);
  }

  private checkInactivityTriggers() {
    if (!this.layoutControl?.triggers?.length) return this.clearInactivityTimeout();

    if (!this.inactivityTriggerTimeout || !this.inactivityTriggerPreviousTick) this.inactivityTriggerPreviousTick = Date.now();
    this.inactivityTriggerTimeout = setTimeout(() => {
      if (!this.inactivityTriggerTimeout) return;

      for (const trigger of this.layoutControl?.triggers || []) {
        if (Date.now() - this.inactivityTriggerPreviousTick >= trigger.inactivityInMilliSeconds) {
          this.clearInactivityTimeout();
          this.onTriggerEvent({
            platformObjectGuidId: trigger.triggerGuidId,
            platformObjectType: RuntimeLayoutEventPlatformObjectType.Trigger
          });
          return;
        };
      }

      this.checkInactivityTriggers();
    }, 250);
  }

  private clearInactivityTimeout(): void {
    if (!this.inactivityTriggerTimeout) return;

    clearTimeout(this.inactivityTriggerTimeout);
    this.inactivityTriggerTimeout = undefined;

    this.inactivityTriggerPreviousTick = undefined;
  }

  private clearBufferSendTimeout() {
    if (!this.bufferSendTimeout) return;

    clearTimeout(this.bufferSendTimeout);
    this.bufferSendTimeout = undefined;
  }

  platformBackButtonAction() {
    if (!this.layoutSnapshot) {
      navigator['app'].exitApp(); // Ionic 4
      return;
    }

    if (this.keyboardService.isVisible()) {
      this.keyboardService.hide();
      this.cdr.markForCheck();
    } else if (this.layoutScreen?.backButton) {
      this.triggerEvent(null, { platformObjectType: RuntimeLayoutEventPlatformObjectType.BackButton });
    }
  }

  onTriggerEvent(eo: EventObject, explicitButtonClick?: boolean) {
    if (!this.layoutSnapshot) return;
    if (!this.layoutControl) return;

    if (
      eo.platformObjectType !== RuntimeLayoutEventPlatformObjectType.BackButton &&
      this.layoutControl.parseRV('EventGpsLock', false) &&
      !this.geolocationService.getLastKnownPosition()
    ) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('GPS lock required to continue'),
        type: RuntimeLayoutNotifyType.VerificationAlert,
      }));
      return;
    }

    this.clearBufferSendTimeout();

    const buffering = this.layoutControl.layoutControlCode === RuntimeLayoutControlCode.RfidScan
    || this.layoutControl.parseRV('InputBuffering');

    if (eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.Action) {
      this.showActionMenu();
    } else if (
      eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.ForwardButton
      && this.screenComponent?.forwardButtonOverride()
    ) {
      return;
    } else if (
      eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.ForwardButton
      || (eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.Scanner && buffering) // advanceScan
      || (eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.Unknown && buffering) // advanceScan / RFID
    ) {
      let controlsContext = this.screenComponent?.getControlsContext();
      if (buffering) {
        const bufferValues = controlsContext[this.layoutControl.objectId].parseRV('RfidBufferTids') || controlsContext[this.layoutControl.objectId].parseRV('BufferValues') || [];
        if (explicitButtonClick || bufferValues.length) {
          // start by disabling the back and action buttons
          this.layoutScreen.backButton = false;
          this.layoutScreen.actionButton = false;
          this.footerComponent.refresh();

          // if the buffer is full, send to server
          const bufferingItemCount = this.layoutControl.parseRV('BufferingItemCount') || this.layoutControl.parseRV('InputBufferingItemCount') || 0;
          if (explicitButtonClick || bufferValues.length >= bufferingItemCount) {
            this.triggerEvent(controlsContext, eo);
          } else {
            // if a SendTimeout is set, (re)initialize it
            const sendTimeoutInSec = this.layoutControl.parseRV('BufferingSendTimeoutSeconds') || this.layoutControl.parseRV('InputBufferingSendTimeoutSeconds');
            if (sendTimeoutInSec) {
              this.bufferSendTimeout = setTimeout(() => {
                if (!this.bufferSendTimeout) return;

                this.triggerEvent(controlsContext, eo);
              }, sendTimeoutInSec * 1000);
            }
          }
        }
      } else {
        this.runVerifications(controlsContext, eo);
      }
    } else if (
      eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.BackButton
      && this.screenComponent?.backButtonOverride()
    ) {
      return;
    } else {
      let controlsContext = this.screenComponent?.getControlsContext();
      this.triggerEvent(controlsContext, eo);
    }

    this.cdr.markForCheck();
  }

  private runVerifications(controlsContext: DictNumber<RuntimeLayoutEventContext>, eo: EventObject) {
    if (
      !this.layoutControl?.objectId ||
      (Object.keys(controlsContext).length > 0 && !controlsContext?.[this.layoutControl.objectId])
    ) {
      LogUtils.error('RunVerifications on an invalid control/controlContext!', this.layoutControl, controlsContext);

      Sentry.setExtra('layoutControl', this.layoutControl);
      Sentry.setExtra('controlsContext', controlsContext);
      Sentry.setExtra('log', LogUtils.getLogArray());

      Sentry.captureException(new Error('RunVerifications on an invalid control/controlContext!'));
      return;
    }

    let verifyErrorText = undefined;
    if (
      this.layoutControl.parseRV('VerificationAllowEmpty') === false &&
      !controlsContext[this.layoutControl.objectId].parseRV('TextBox') &&
      controlsContext[this.layoutControl.objectId].parseRV('TextBox') !== '0'
    ) {
      verifyErrorText = this.layoutControl.parseRV(
        'VerificationText',
        this.translateService.instant('Please enter a value!')
      );
    } else if (
      this.layoutControl.parseRV('VerificationType') === VerificationType.Equal &&
      (this.layoutControl.parseRV('VerificationValue') || '').split('|').indexOf(controlsContext[this.layoutControl.objectId].parseRV('TextBox')) < 0
    ) {
      verifyErrorText = this.layoutControl.parseRV('VerificationText') || `${this.translateService.instant('Value must be')} '${this.layoutControl.parseRV('VerificationValue')}'`;
    } else if (
      this.layoutControl.parseRV('VerificationType') === VerificationType.Regex &&
      !(new RegExp(this.layoutControl.parseRV('VerificationValue'))).test(controlsContext[this.layoutControl.objectId].parseRV('TextBox'))
    ) {
      verifyErrorText = this.layoutControl.parseRV('VerificationText') || `${this.translateService.instant('Value must follow the format')}: '${this.layoutControl.parseRV('VerificationValue')}'`;
    } else if (
      this.layoutControl.parseRV('VerificationType') === VerificationType.LowerOrEqual &&
      (
        !this.isNumeric(controlsContext[this.layoutControl.objectId].parseRV('TextBox')) ||
        parseFloat(controlsContext[this.layoutControl.objectId].parseRV('TextBox')) < 0 ||
        parseFloat(controlsContext[this.layoutControl.objectId].parseRV('TextBox')) > parseFloat(this.layoutControl.parseRV('VerificationValue', 0))
      )
    ) {
      verifyErrorText = this.layoutControl.parseRV('VerificationText') || `${this.translateService.instant('Value must be a number between 0 and')} ${this.layoutControl.parseRV('VerificationValue', 0)}`;
    }

    if (verifyErrorText !== undefined) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Verification'),
        text: verifyErrorText,
        type: RuntimeLayoutNotifyType.VerificationAlert,
      }));
    } else {
      this.triggerEvent(controlsContext, eo);
    }
  }

  private isNumeric(str: any) {
    return !isNaN(parseFloat(str)) && isFinite(str);
  }

  private triggerEvent(controlsContext: DictNumber<RuntimeLayoutEventContext>, eo: EventObject, isUserAction = true) {
    // if we get 2 triggers too close to one another...ignore the second one.
    if (isUserAction && this.previousTriggerEventTick && Date.now() - this.previousTriggerEventTick < 250) {
      LogUtils.warn('triggerEvent was called twice in less than 250ms...second attempt was ignored.', controlsContext, eo);
      return;
    }
    this.previousTriggerEventTick = Date.now();

    this.screenComponent?.controlComponents?.forEach((cc: ScreenControlContainerComponent) => {
      cc.controlComponent?.unsubscribeScannerSubscription();
    });

    if (controlsContext?.[this.layoutControl.objectId]?.values?.BufferValues?.valueJson) {
      controlsContext[this.layoutControl.objectId].values.BufferValues.valueJson = JSON.stringify(controlsContext[this.layoutControl.objectId].parseRV('BufferValues', []).join('|'));
    }
    if (controlsContext?.[this.layoutControl.objectId]?.values?.RfidBufferTids?.valueJson) {
      controlsContext[this.layoutControl.objectId].values.RfidBufferTids.valueJson = JSON.stringify(controlsContext[this.layoutControl.objectId].parseRV('RfidBufferTids', []).join('|'));
    }

    this.layoutEventService.trigger(
      this.layoutSnapshot,
      controlsContext,
      eo.eventContext,
      eo.platformObjectType,
      eo.platformObjectGuidId,
    ).subscribe((result: boolean) => {
      if (eo.callback) eo.callback(result);
    }, (error: any) => {
      LogUtils.error('triggerEvent.trigger() error:', error);
    });
  }

  private showActionMenu() {
    from(this.popoverCtrl.create({
      component: ActionPopover,
      componentProps: { layoutControl: this.layoutControl },
      cssClass: `popover-action`,
      backdropDismiss: true,
      showBackdrop: true
    }))
    .subscribe((popover: HTMLIonPopoverElement) => {
      from(popover.onDidDismiss())
      .subscribe((result: OverlayEventDetail<string>) => {
        const actionGuidId = result.data;
        if (!actionGuidId) return;

        this.screenComponent.preActionTrigger()
        .subscribe(() => {
          this.layoutEventService.trigger(
            this.layoutSnapshot,
            null,
            null,
            RuntimeLayoutEventPlatformObjectType.Action,
            actionGuidId
          ).subscribe((result: boolean) => {

          }, (error: any) => {
            LogUtils.error('showActionMenu.trigger() error:', error);
          });

          this.cdr.markForCheck();
        });
      });
      popover.present();
    });
  }

  @HostListener('document:click', ['$event'])
  anywhereClick(event: MouseEvent) {
    this.appService.updateSolutionInfoToast(null);
  }

  private handleRequestClientSetting(layoutControl: RuntimeLayoutControl): void {
    if (!layoutControl) return;

    let hasUnknowns = false;
    let hasNoValues = false;
    this.clientSettingValues = {};
    for (const rvKey of Object.keys(layoutControl.renderValues || {})) {
      if (rvKey.indexOf('ClientSetting.') < 0) continue;

      const clientSettingGuidId = rvKey.split('.')[1];
      const clientSetting = layoutControl.parseRV(rvKey);
      switch (clientSetting) {
        case ClientSetting.HasPrinter:
          const btPrinterDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.Printer, BluetoothDeviceType.PrinterSato]);
          this.clientSettingValues[clientSettingGuidId] = satoApi.isPrinter() || btPrinterDevices?.length > 0;
          hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          break;
        case ClientSetting.HasBluetoothTemperature:
          const btTempDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.Thermometer]);
          this.clientSettingValues[clientSettingGuidId] = btTempDevices?.length > 0;
          hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          break;
        case ClientSetting.HasCamera:
          if (this.platform.is('android') || this.platform.is('ios')) {
            this.clientSettingValues[clientSettingGuidId] = true; // should have a proper check...
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
            break;
          }
          if (satoApi.isPrinter() || !navigator?.mediaDevices?.getUserMedia) {
            this.clientSettingValues[clientSettingGuidId] = false;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
            break
          }

          navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            this.clientSettingValues[clientSettingGuidId] = stream.getVideoTracks().length > 0;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          })
          .catch((error) => {
            this.clientSettingValues[clientSettingGuidId] = false;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          });
          break;
        case ClientSetting.HasRfidPrinter:
          const rfidPrinterDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.PrinterSato]);
          this.clientSettingValues[clientSettingGuidId] = rfidPrinterDevices?.length > 0 && rfidPrinterDevices.some(d => d.settings.rfid);
          hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          break;
        case ClientSetting.PrinterDpi:
          if (satoApi.isPrinter()) {
            const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
            satoPlugin.status()
            .subscribe((status: any) => {
              this.clientSettingValues[clientSettingGuidId] = status?.satoPrinterVariables?.HeadInfo?.dpi;
              hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
            });
          } else if (ControlPrint1Component.defaultPrinter) {
            this.clientSettingValues[clientSettingGuidId] = ControlPrint1Component.defaultPrinter.settings?.dpi;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          } else {
            const printerDpiDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.Printer, BluetoothDeviceType.PrinterSato]);
            this.clientSettingValues[clientSettingGuidId] = printerDpiDevices?.[0]?.settings?.dpi || 0;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          }
          break;
        default:
          this.clientSettingValues[clientSettingGuidId] = null;
          hasUnknowns = true;
          break;
      }
    }

    setTimeout(() => { // give it some time as some settings are not synchronous
      this.sendClientSettingValue(layoutControl, hasUnknowns ? 'Unknown' : hasNoValues ? 'NoValue' : 'Value');
    }, 100);
  }

  private sendClientSettingValue(layoutControl: RuntimeLayoutControl, portName: string): void {
    const eventContextValues: any = {};
    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    });

    const controlContext = {};
    for (const guidId of Object.keys(this.clientSettingValues || {})) {
      controlContext['ClientValue.' + guidId] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.clientSettingValues[guidId]),
        valueTypeId: RuntimeLayoutValueType.String,
      });
    }

    if (this.layoutControl?.parseRV('EventGps')) {
      controlContext['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }
    const controlsContext: any = {
      [layoutControl.objectId]: new RuntimeLayoutEventContext({
        values: controlContext
      })
    };

    this.triggerEvent(
      controlsContext,
      {
        eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
        platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
      },
      false,
    );
  }

}
