import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutDesignStyle } from './runtime-layout-design-style.model';


@Type.d('RuntimeLayoutDesign')
export class RuntimeLayoutDesign extends Message<RuntimeLayoutDesign> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'optional', 0)
  tick: number;

  @Field.d(10, 'string', 'optional')
  designGuidId: string;
  @Field.d(11, 'string', 'optional')
  name: string;
  @Field.d(12, 'string', 'optional')
  designOriginalGuidId: string;

  @Field.d(50, RuntimeLayoutDesignStyle, 'repeated')
  designStyles: RuntimeLayoutDesignStyle[];

}
