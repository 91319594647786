import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';


@Component({
  template: '<div></div>'
})
export class BaseComponent implements OnDestroy {

  isDestroyed: boolean;
  subscriptions: Subscription[] = [];

  constructor() { }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    if (!this.subscriptions) return;

    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.subscriptions.length = 0;
  }

}
