import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CriticalErrorMessage, LayoutCoreMessage, LayoutMessageResult, LayoutMessageType, RuntimeLayoutSetting, RuntimeLayoutSettingGroup } from '../../models';
import { BluetoothDevice } from '../../models/bluetooth-device.model';
import { RuntimeLayoutSettingGroupSetType } from '../../models/runtime-layout/setting/runtime-layout-setting-group-set-type.enum';
import { RuntimeLayoutSettingType } from '../../models/runtime-layout/setting/runtime-layout-setting-type.enum';
import { LogUtils } from '../../utils';
import { HeartbeatService } from '../app/heartbeat.service';
import { BusyService } from '../busy/busy.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';


@Injectable({
  providedIn: 'root'
})
export class SetSettingsService {

  private readonly settingGroupPath: string = 'Plugin/Bluetooth/';
  private readonly timeoutInMs = 5 * 1000;

  constructor(
    private busyService: BusyService,
    private heartbeatService: HeartbeatService,
    private webSocketClientService: WebSocketClientService
  ) { }

  setBluetoothDeviceSettingRemotely(device: BluetoothDevice, active = true) {
    const settingGroup = new RuntimeLayoutSettingGroup({
      active: active,
      path: this.settingGroupPath,
      name: device.id,
      settingGroupDateTime: (new Date()).toISOString(),
      setType: RuntimeLayoutSettingGroupSetType.Hardware,
      settings: [
        new RuntimeLayoutSetting({
          name: 'id',
          settingType: RuntimeLayoutSettingType.Setting,
          value: device.id
        }),
        new RuntimeLayoutSetting({
          name: 'mode',
          settingType: RuntimeLayoutSettingType.Setting,
          value: device.mode
        }),
        new RuntimeLayoutSetting({
          name: 'name',
          settingType: RuntimeLayoutSettingType.Setting,
          value: device.name
        }),
        new RuntimeLayoutSetting({
          name: 'type',
          settingType: RuntimeLayoutSettingType.Setting,
          value: device.type
        }),
      ]
    });

    this.trigger(settingGroup)
    .subscribe({
      next: () => {
        LogUtils.log(`BT Device '${device.id}' setting updated remotely. Active=${active}`);
      },
      error: (error: any) => {
        console.error(error);
      }
    });
  }

  trigger(
    layoutSettingGroup: RuntimeLayoutSettingGroup,
  ): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      this.busyService.setBusy(true, 'Saving settings...');

      const msgContent = new RuntimeLayoutSettingGroup(layoutSettingGroup);
      console.log(msgContent);
      const msgContentBuffer = RuntimeLayoutSettingGroup.encode(msgContent).finish();

      const coreMsg = new LayoutCoreMessage({
        messageSequenceNr: this.webSocketClientService.getSequenceNumber(),
        messageType: LayoutMessageType.SetSetting,
        messageContent: msgContentBuffer,
      });
      const coreMsgBuffer  = LayoutCoreMessage.encode(coreMsg).finish();

      const subscription = this.webSocketClientService
      .getMessages$(coreMsg.messageSequenceNr)
      .pipe(delay(10))
      .subscribe((msg: LayoutCoreMessage) => {
        this.handleIncomingMessage(msg, observer, subscription);
      }, (error: any) => {
        this.handleError(subscription, observer, error);
      });

      this.heartbeatService.scheduleNextHeartbeat(this.timeoutInMs);
      this.webSocketClientService.send(coreMsgBuffer);
    });
  }

  private handleIncomingMessage(
    msg: LayoutCoreMessage, observer: Observer<any>, subscription: Subscription
  ) {
    this.busyService.setBusy(false);
    if (!subscription || subscription.closed) return;

    if (msg?.messageType === LayoutMessageType.CriticalError) {
      const error = CriticalErrorMessage.decode(msg.messageContent);
      observer.error(error);
    } else {
      observer.next(msg?.messageResult === LayoutMessageResult.Success);
      observer.complete();
    }

    subscription.unsubscribe();
    subscription = null;
  }

  private handleError(subscription: Subscription, observer: Observer<any>, error: any) {
    if (!subscription || subscription.closed) return;

    this.busyService.setBusy(false);

    subscription.unsubscribe();
    subscription = null;

    observer.error(error);
  }

}
