import { Field, Message, Type } from 'protobufjs/light';

export enum RuntimeLayoutVariableValueType {
  Value = 0,
  Data = 1
}

@Type.d('RuntimeLayoutVariable')
export class RuntimeLayoutVariable extends Message<RuntimeLayoutVariable> {
  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(10, 'int64', 'optional')
  runtimeVariableObjectId: number;
  @Field.d(11, 'string', 'optional')
  variableGuidId: string;
  @Field.d(12, 'string', 'optional')
  originalVariableGuidId: string;

  @Field.d(50, 'string', 'optional')
  value: string;

  @Field.d(51, 'int32', 'optional', RuntimeLayoutVariableValueType.Value)
  valueType: RuntimeLayoutVariableValueType;

}
