import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutControlPointer } from '../control/runtime-layout-control-pointer.model';
import { RuntimeLayoutHeadPointer } from '../head';


@Type.d('RuntimeLayoutScreenControlCollection')
export class RuntimeLayoutScreenControlCollection extends Message<RuntimeLayoutScreenControlCollection> {

  @Field.d(100, RuntimeLayoutControlPointer, 'optional')
  activeControlPointer: RuntimeLayoutControlPointer;
  // @Field.d(101, RuntimeLayoutControlPointer, 'repeated')
  // controlPointers: RuntimeLayoutControlPointer[];
  @Field.d(102, RuntimeLayoutHeadPointer, 'repeated')
  headPointers: RuntimeLayoutHeadPointer[];

}
