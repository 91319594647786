import { Field, Message, Type } from 'protobufjs/light';

@Type.d('SolutionChangeMessage')
export class SolutionChangeMessage extends Message<SolutionChangeMessage> {

  @Field.d(20, 'string', 'required')
  text: string;

  @Field.d(100, 'string', 'optional')
  deviceSolutionSetGuidId: string;
  @Field.d(101, 'int64', 'optional', 0)
  deviceSolutionSetTick: number;
  @Field.d(102, 'string', 'optional')
  deviceSolutionSetName: string;
  @Field.d(103, 'string', 'optional')
  deviceSolutionSetVersion: string;
  @Field.d(104, 'int32', 'optional')
  deviceSolutionSetSysVersion: number;
  @Field.d(105, 'string', 'optional')
  deviceSolutionSetSysVersionDateTime: string;
  @Field.d(106, 'string', 'optional')
  deviceSolutionSetVersionType: string;
  @Field.d(107, 'bool', 'optional', false)
  deviceSolutionSetLocked: boolean;

  @Field.d(120, 'string', 'optional')
  deviceSolutionGuidId: string;
  @Field.d(121, 'int64', 'optional', 0)
  deviceSolutionTick: number;
  @Field.d(122, 'string', 'optional')
  deviceSolutionName: string;
  @Field.d(123, 'string', 'optional')
  deviceSolutionVersion: string;
  @Field.d(124, 'int32', 'optional')
  deviceSolutionSysVersion: number;
  @Field.d(125, 'string', 'optional')
  deviceSolutionSysVersionDateTime: string;
  @Field.d(126, 'string', 'optional')
  deviceSolutionVersionType: string;
  @Field.d(127, 'bool', 'optional', false)
  deviceSolutionLocked: boolean;

  @Field.d(130, 'string', 'optional')
  deviceRunEngineServiceName: string;
  @Field.d(131, 'string', 'optional')
  layoutRunEngineServiceName: string;

  @Field.d(140, 'bool', 'optional')
  colorOverrideShow: boolean;
  @Field.d(141, 'string', 'optional')
  color: string;

  @Field.d(150, 'bool', 'optional')
  restartOnly: boolean;
  @Field.d(151, 'bool', 'optional')
  forceRestart: boolean;

}