import { Field, MapField, Message, Type } from 'protobufjs/light';
import { DictNumber } from '../dict.model';
import { RuntimeLayoutDesign } from './design/runtime-layout-design.model';
import { RuntimeLayoutNativeKeyboard } from './runtime-layout-native-keyboard.enum';
import { RuntimeLayoutDeviceLicense } from './runtime-layout-device-license.model';
import { RuntimeLayoutMenuSettings } from './runtime-layout-menu-settings.enum';
import { RuntimeLayoutObjectPointer } from './runtime-layout-object-pointer.model';
import { RuntimeLayoutPrivacyLevel } from './runtime-layout-privacy-level.enum';
import { RuntimeLayoutSession } from './runtime-layout-session.model';
import { RuntimeLayoutText } from './runtime-layout-text.model';
import { RuntimeLayoutUIAttributes } from './runtime-layout-ui-attributes.enum';
import { RuntimeLayoutScreen } from './screen/runtime-layout-screen.model';
import { RuntimeLayoutSmartImage } from './smart-image/runtime-layout-smart-image.model';


@Type.d('RuntimeLayout')
export class RuntimeLayout extends Message<RuntimeLayout> {
  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(50, 'string', 'optional')
  solutionLayoutGuidId: string;
  @Field.d(51, 'int64', 'optional')
  startedTick: number;
  @Field.d(52, 'int64', 'optional')
  activeScreen: number;
  @MapField.d(53, 'int64', RuntimeLayoutScreen)
  layoutScreens: DictNumber<RuntimeLayoutScreen>;
  @Field.d(54, RuntimeLayoutObjectPointer, 'repeated')
  removedLayoutScreens: RuntimeLayoutObjectPointer[];
  @Field.d(55, RuntimeLayoutDesign, 'repeated')
  layoutDesigns: RuntimeLayoutDesign[];
  // @Field.d(56, RuntimeLayoutObjectPointer, 'repeated')
  // removedLayoutDesignControls: RuntimeLayoutObjectPointer[];
  // @MapField.d(57, 'int64', RuntimeLayoutData)
  // datas: DictNumber<RuntimeLayoutData>;
  // @Field.d(58, RuntimeLayoutObjectPointer, 'repeated')
  // removedDatas: RuntimeLayoutObjectPointer[];
  @MapField.d(70, 'int32', RuntimeLayoutText)
  layoutTexts: DictNumber<RuntimeLayoutText>;

  @Field.d(80, 'int64', 'optional')
  runtimeSolutionStartedTick: number;

  // @Field.d(90, 'bool', 'optional')
  // disableMenu: boolean;

  @Field.d(91, 'int32', 'optional', RuntimeLayoutMenuSettings.Default)
  menuSettings: RuntimeLayoutMenuSettings;

  @Field.d(92, 'int32', 'optional', RuntimeLayoutUIAttributes.None)
  uiAttributes: RuntimeLayoutUIAttributes;

  @Field.d(100, RuntimeLayoutSession, 'optional')
  layoutSession: RuntimeLayoutSession;

  @Field.d(110, RuntimeLayoutSmartImage, 'repeated')
  smartImages: RuntimeLayoutSmartImage[];

  @Field.d(120, 'bool', 'optional')
  deviceLocation: boolean;

  @Field.d(121, 'string', 'optional')
  deviceLocationScheduler: string;

  // @Field.d(130, 'string', 'repeated')
  // deviceLicenseGuidIds: string[];

  @Field.d(131, RuntimeLayoutDeviceLicense, 'repeated')
  deviceLicenses: RuntimeLayoutDeviceLicense[];

  @Field.d(140, 'int32', 'optional')
  privacyLevel: RuntimeLayoutPrivacyLevel;
  @Field.d(141, 'string', 'optional')
  privacyLevelTimeoutDateTime: string;

  @Field.d(150, 'int32', 'optional')
  defaultKeyboard: RuntimeLayoutNativeKeyboard;
  @Field.d(151, 'bool', 'optional')
  useLanguageKeyboard: boolean;

  @Field.d(160, 'bool', 'optional')
  customSymbologies: boolean;
  @Field.d(161, 'string', 'optional')
  customSymbologiesJson: string;
}
