import { Field, Message, Type } from 'protobufjs/light';
import encoding from 'text-encoding';
const pako = require('pako');


@Type.d('RuntimeLayoutResourceResponse')
export class RuntimeLayoutResourceResponse extends Message<RuntimeLayoutResourceResponse> {

  // @Field.d(1, 'int64', 'required')
  // objectId: number;
  // @Field.d(2, 'bool', 'optional')
  // active: boolean;
  // @Field.d(3, 'int64', 'required')
  // tick: number;

  @Field.d(20, 'int64', 'optional')
  resourceId: number;
  @Field.d(21, 'string', 'required')
  guidId: string;
  @Field.d(22, 'int64', 'optional', 0)
  tick: number;
  @Field.d(23, 'string', 'optional')
  contentType: string;
  @Field.d(24, 'int64', 'optional')
  totalSize: number;

  @Field.d(30, 'bool', 'optional')
  success: boolean;
  @Field.d(31, 'bool', 'optional')
  notExists: boolean;
  @Field.d(32, 'bool', 'optional')
  notSupported: boolean;

  @Field.d(40, 'int64', 'optional')
  chunkId: number;
  @Field.d(41, 'int64', 'optional')
  chunkSize: number;
  @Field.d(42, 'bytes', 'optional')
  chunkBinary: Uint8Array;

  get chunk(): Uint8Array {
    if (this._chunk) return this._chunk;

    this._chunk = pako.ungzip(this.chunkBinary);
    return this._chunk;
  }
  private _chunk: Uint8Array;
}
