import { Directive, ElementRef, Input, OnChanges, OnInit} from "@angular/core";

/**
 * Applies css to the element's Shadow DOM subelements.
 * Usage: <ion-button appShadowCss shadowCustomCss='.button-inner{white-space:normal;}'>
 *
 * Important notice: Only use shadow css when it is absolutely necessary as it potentially
 * breaks compatibility with future ionic versions. Always check if you can use exposed element variables in
 * variables.scss instead or if you can apply css to non Shadow elements (eg., directly to ion-radio in app.scss).
 */
@Directive({
  selector: '[shadowCss]',
})
export class ShadowCssDirective implements OnChanges, OnInit {
  @Input() shadowCustomCss = '';

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    const shadow = this.el.nativeElement.shadowRoot || this.el.nativeElement.attachShadow({ mode: 'open' });
    if (!shadow) return;

    let innerHTML = '';
    innerHTML += '<style>';
    innerHTML += this.shadowCustomCss;
    innerHTML += '</style>';
    shadow.innerHTML += innerHTML;

  }

  ngOnChanges(): void {
    this.ngOnInit();
  }
}