<div class="barcode-scanner-livestream-overlay"
    [style.display]="showScanner ? 'block' : 'none'">
    <div class="barcode-scanner-livestream-overlay-content">
        <div class="barcode-scanner-livestream-overlay-close" (click)="hide()">X</div>
        <lc-barcode-scanner-livestream
            [layout]="layout"
            [layoutControl]="layoutControl"
            [types]="types"
            (valueChanges)="onValueChanges($event)">
        </lc-barcode-scanner-livestream>
    </div>
</div>