import { Field, Message, Type } from 'protobufjs/light';
import { ClientRunEngineType } from './client-run-engine-type.enum';

@Type.d('ClientAuthenticationMessage')
export class ClientAuthMessage extends Message<ClientAuthMessage> {

  @Field.d(20, 'string', 'required')
  enrollmentGuidId: string;
  @Field.d(21, 'int64', 'optional')
  fromTick: number;
  @Field.d(22, 'bool', 'optional')
  noSnapshot: boolean; // noSnapshot = true => this means I want to auth but tell the server not to bother sending a snapshot immediately after as I'll make a follow up request (with events or something)
  @Field.d(23, 'string', 'optional')
  clientVersion: string;
  @Field.d(24, 'string', 'optional')
  deviceDateTime: string;
  // @Field.d(25, 'bool', 'optional')
  // supportSnapshot: boolean;
  @Field.d(26, 'string', 'optional')
  languageGuidId: string;
  @Field.d(27, 'bool', 'optional')
  runDeviceDebug: boolean;
  @Field.d(28, 'string', 'optional')
  sourceEnrollmentGuidId: string;
  @Field.d(29, 'string', 'optional')
  runCode: string;

  @Field.d(50, 'string', 'optional')
  solutionSetGuidId: string;
  @Field.d(51, 'int64', 'optional', 0)
  solutionSetTick: number;
  @Field.d(52, 'string', 'optional')
  solutionGuidId: string;
  @Field.d(53, 'int64', 'optional', 0)
  solutionTick: number;

  @Field.d(60, 'string', 'optional')
  clientRunEngineGuidId: string;
  @Field.d(61, 'bool', 'optional')
  deviceReload: boolean;
  @Field.d(62, ClientRunEngineType, 'optional')
  clientRunEngineType: ClientRunEngineType;

  @Field.d(100, 'string', 'optional')
  layoutClientDataType: string;

}