<div class="height-100 flex-col center middle gap-1">
  <h2 *ngIf="layoutControl.parseRV('ControlHeadlineEnabled')"
    style="padding-left: 4px;">
    {{ layoutControl.parseRV('ControlHeadlineText') }}
  </h2>

  <div *ngIf="rfidUiType === 0"
    class="flex-col gap-1 center">
    <div *ngIf="!uiBufferValues?.length"
      class="counter text">
      {{ 'RFID Scan' | translate }}
    </div>

    <div *ngIf="uiBufferValues?.length"
      class="counter number">
      {{ uiBufferValues?.length || 0 }} ({{ allBufferValues?.length || 0 }})
    </div>

    <div *ngIf="uiBufferValues?.length"
      class="counter text">
      {{ 'new tags found' | translate }}...
    </div>
  </div>

</div>