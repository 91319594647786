import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'getDesignStyle' })
export class GetDesignStylePipe implements PipeTransform {
  transform (i: number, j: number, thisComponent: any, pipePureValueBusting?: number): any {
    if (!thisComponent) return null;
    return thisComponent.getDesignStyleWithMapping(i, j);
  }

}