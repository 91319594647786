import { Pipe, PipeTransform } from '@angular/core';
import { DesignStyleJsonItem } from 'src/app/shared/models/studio';


@Pipe({ name: 'getItemData' })
export class GetItemDataPipe implements PipeTransform {
  transform (item: DesignStyleJsonItem, i: number, j: number, thisComponent: any, pipePureValueBusting?: number): any {
    if (!thisComponent) return null;
    return thisComponent.getItemData(item, i, j);
  }

}