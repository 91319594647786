import { Field, Message, Type } from 'protobufjs/light';

export enum RuntimeLayoutLocalEventType {
  Unknown = 0,
  Notify = 1,
  Plugin = 2,
}

@Type.d('RuntimeLayoutLocalEvent')
export class RuntimeLayoutLocalEvent extends Message<RuntimeLayoutLocalEvent> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'optional', 0)
  tick: number;


  @Field.d(50, 'int64', 'required')
  objectTickStart: number;
  @Field.d(51, RuntimeLayoutLocalEventType, 'optional', RuntimeLayoutLocalEventType.Unknown)
  localEventType: RuntimeLayoutLocalEventType;
  @Field.d(52, 'int64', 'optional')
  targetObjectId: number;
  @Field.d(53, 'bytes', 'optional')
  eventBinary: Uint8Array;

}
