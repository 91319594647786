import { Field, MapField, Message, Type } from 'protobufjs/light';
import { DictNumber } from '../../dict.model';
import { RuntimeLayoutObjectPointer } from '../runtime-layout-object-pointer.model';
import { RuntimeLayoutSetData } from './runtime-layout-set-data.model';

@Type.d('RuntimeLayoutSet')
export class RuntimeLayoutSet extends Message<RuntimeLayoutSet> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(10, 'int64', 'optional', 0)
  runtimeSetObjectId: number;
  @Field.d(11, 'string', 'optional')
  setGuidId: string;
  @Field.d(12, 'int64', 'optional')
  parentObjectId: number;
  @Field.d(13, 'bool', 'optional')
  complexSet: boolean;

  @MapField.d(50, 'int64', RuntimeLayoutSetData)
  datas: DictNumber<RuntimeLayoutSetData>;
  @Field.d(51, RuntimeLayoutObjectPointer, 'repeated')
  removedDatas: RuntimeLayoutObjectPointer[];

}
