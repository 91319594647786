import { Field, Message, Type } from 'protobufjs/light';


@Type.d('RuntimeLayoutDeviceLicense')
export class RuntimeLayoutDeviceLicense extends Message<RuntimeLayoutDeviceLicense> {
  @Field.d(1, 'string', 'required')
  deviceLicenseGuidId: string;
  @Field.d(2, 'string', 'optional')
  name: string;
  @Field.d(3, 'string', 'optional')
  licenseKey: string;

  @Field.d(4, 'string', 'optional')
  settings: string;

}
