import { DictNumber, DictString } from '../models';

export class DictUtils {

  static dictToArray<T>(map: DictNumber<T> | DictString<T>): T[] {
    if (map) {
      return Object.keys(map).map((key: string) => {
        return map[key];
      });
    } else {
      return [];
    }
  }

}
