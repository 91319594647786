import { Field, MapField, Message, Type } from 'protobufjs/light';
import { DictString } from '../../dict.model';
import { RuntimeLayoutValue } from '../runtime-layout-value.model';


@Type.d('RuntimeLayoutData')
export class RuntimeLayoutData extends Message<RuntimeLayoutData> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(9, 'int64', 'optional', 0)
  runtimeSetObjectId: number;
  @Field.d(10, 'int64', 'optional', 0)
  runtimeDataObjectId: number;
  @Field.d(11, 'string', 'required')
  dataGuidId: string;
  @Field.d(12, 'string', 'optional')
  solutionTypeGuidId: string;

  @MapField.d(50, 'string', RuntimeLayoutValue)
  values: DictString<RuntimeLayoutValue>;

  // @Field.d(60, 'bool', 'optional')
  // isBinary: boolean;
  // @Field.d(61, 'string', 'optional')
  // binaryContentType: string;
  // @Field.d(62, 'string', 'optional')
  // base64Binary: string;

  @Field.d(70, 'bool', 'optional')
  isResource: boolean;
  @Field.d(71, 'bool', 'optional')
  persistantResource: string;
  @Field.d(72, 'string', 'optional')
  resourceGuidId: string;
  @Field.d(73, 'int64', 'optional')
  resourceTick: number;


  parseRV(key: string, defaultValue?: any): any {
    if (this.values?.[key] == null) return null;

    const value = RuntimeLayoutValue.parse(this.values[key]);
    return value != null ? value : defaultValue;
  }

}
