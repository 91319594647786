import { Message, Type, Field } from 'protobufjs/light';

enum RuntimeLayoutControlTriggerType {
  Timer = 0,
  DataUpdate = 1,
  Inactivity = 2,
}

@Type.d('RuntimeLayoutControlTrigger')
export class RuntimeLayoutControlTrigger extends Message<RuntimeLayoutControlTrigger> {

  @Field.d(1, 'string', 'required')
  triggerGuidId: string;
  @Field.d(2, 'int32', 'optional', RuntimeLayoutControlTriggerType.Inactivity)
  triggerType: RuntimeLayoutControlTriggerType;
  @Field.d(10, 'int32', 'optional')
  inactivityInMilliSeconds: number;

}
