import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BluetoothDevice, BluetoothDeviceType } from 'src/app/shared/models/bluetooth-device.model';
import { LocalSettingsService, VibrationService } from 'src/app/shared/services';




@Component({
  selector: 'lc-select-printer-popover',
  templateUrl: 'select-printer.popover.html',
  styleUrls: ['./select-printer.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPrinterPopover implements AfterViewInit {

  btPrinters: BluetoothDevice[];

  constructor(
    private cdr: ChangeDetectorRef,
    private localSettingsService: LocalSettingsService,
    private popoverCtrl: PopoverController,
    private vibrationService: VibrationService,
  ) {
    this.btPrinters = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.Printer, BluetoothDeviceType.PrinterSato]);
  }

  ngAfterViewInit() {
    this.cdr.markForCheck();
  }

  buttonClick(device: BluetoothDevice) {
    this.vibrationService.vibrate();

    this.popoverCtrl.dismiss(device);
  }

}
