<div class="popover-grid-container">
  <div>
    <button class="button"
      (click)="success()">
      {{ 'Success' | translate }}
    </button>

    <button class="button"
      (click)="failure()">
      {{ 'Failure' | translate }}
    </button>

    <button *ngIf="isDeviceApp"
      class="button"
      (click)="skip()">
      {{ 'Skip Test' | translate }}
    </button>
  </div>
</div>