<ion-app [ngStyle]="hostStyleHack">
  <ion-menu
    contentId="main"
    menuId="menu"
    side="end"
    type="overlay"
    [swipeGesture]="false">
    <ion-content class="ion-no-padding">
      <lc-side-menu>
      </lc-side-menu>
      <!--<button (click)="notification()" style="z-index: 9;position: absolute;top: 0;left: 0;">TEST NOTIFICATION</button>-->
    </ion-content>
  </ion-menu>

  <ion-router-outlet id="main"
    [class.run-device-debug]="settings?.runDeviceDebug">
  </ion-router-outlet>

  <lc-readonly-overlay *ngIf="readonly && !isNewEnrollmentPage"></lc-readonly-overlay>

  <ngx-loading [show]="busy.show" [text]="busy.text"></ngx-loading>

  <lc-notify></lc-notify>
</ion-app>

<div id="js-plugin-scripts"></div>
