import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TextService } from '../text/text.service';



@Injectable({
  providedIn: 'root'
})
export class TranslateService {


  constructor(
    private textService: TextService,
  ) {

  }


  get(text: string): Observable<string> {
    return this.textService.getTranslation(text);
  }

  instant(text: string): string {
    return this.textService.instantTranslation(text);
  }

}
