import { Field, Message, Type } from 'protobufjs/light';


@Type.d('RuntimeLayoutAction')
export class RuntimeLayoutAction extends Message<RuntimeLayoutAction> {

  @Field.d(1, 'string', 'required')
  actionGuidId: string;
  @Field.d(2, 'string', 'optional')
  actionText: string;
  @Field.d(3, 'bool', 'optional', false)
  enabled: boolean;

  @Field.d(10, 'bool', 'optional')
  shortAction: boolean;
  @Field.d(11, 'string', 'optional')
  shortActionText: string;
  @Field.d(12, 'int32', 'optional')
  shortActionPosition: number;
  @Field.d(13, 'int32', 'optional')
  shortActionSize: number;
  // @Field.d(14, 'string', 'optional')
  // shortActionIcon: string;

}
