import { RuntimeLayoutNotifyType } from './runtime-layout/local-event/runtime-layout-notify.model';

export class Notification {

  id: number;
  title: string;
  text: string;
  type: RuntimeLayoutNotifyType;
  blocking?: boolean;
  showTimeInSeconds?: number;

  constructor(item?: Partial<Notification>) {
    Object.assign(this, item);

    this.id = this.id || Date.now();
  }

}
