<ion-header>
  <ion-toolbar>
    <ion-title>
        {{ 'System Info' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <ion-button *ngIf="deviceEnrollment"
        full
        (click)="refreshDeviceEnrollment()">
        <ion-icon name="refresh"></ion-icon>
      </ion-button>

      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="wrapper">
    <div><!-- this needs to be inside a div to make it display: block; (ion-segment has display: flex; and we want to keep that)-->
      <ion-segment (ionChange)="activeView = $event?.detail?.value"
        [value]="activeView">
        <ion-segment-button value="system">
          <ion-label>System</ion-label>
        </ion-segment-button>
        <ion-segment-button value="licenses">
          <ion-label>Licenses</ion-label>
        </ion-segment-button>
        <ion-segment-button value="network">
          <ion-label>Network</ion-label>
        </ion-segment-button>
        <ion-segment-button value="status">
          <ion-label>Status</ion-label>
        </ion-segment-button>
        <ion-segment-button value="operational">
          <ion-label>Ops</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>

    <ion-list *ngIf="activeView === 'system'">
      <ion-item>
        <ion-label>
          <b>{{ 'App Version' | translate }}:</b>
        </ion-label>
        <ion-label>
          v{{ appVersion }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Device name' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceId }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Solution' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.deviceSolutionName }} <b>{{ deviceEnrollment?.deviceSolutionVersion ? 'v' + deviceEnrollment?.deviceSolutionVersion : '' }}</b>
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Solution Set' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.deviceSolutionSetName }} <b>{{ deviceEnrollment?.deviceSolutionSetVersion ? 'v' + deviceEnrollment?.deviceSolutionSetVersion : '' }}</b>
        </ion-label>
      </ion-item>

       <ion-item>
        <ion-label>
          <b>{{ 'Environment' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.solutionEnvironmentName }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Enrolled Date' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.enrolledDateTime | date:'yyyy-MM-dd HH:mm:ss' }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Device Engine' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.deviceRunEngineServiceName }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Layout Engine' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.layoutRunEngineServiceName }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Site' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.siteName }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'User' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.userName }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Role' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.roleName }}
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-list *ngIf="activeView === 'licenses'">
      <ion-item>
        <ion-label>
          <b>{{ 'Has Device License?' | translate }}</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.hasDeviceLicense ? 'Yes' : 'No' }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Agreement Name' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.solutionAgreementName || '-' }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Device License Name' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ deviceEnrollment?.deviceLicenseName || '-' }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'Additional Licenses' | translate }}:</b>
        </ion-label>
        <ion-label class="ion-text-wrap"
          [innerHTML]="(additionalDeviceLicenses || []).join('<br>')">
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-list *ngIf="activeView === 'network'">
      <ion-item>
        <ion-label>
          <b>{{ 'Local IP Address' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ ipAddress || 'Not Available' }}
        </ion-label>
      </ion-item>

      <ion-item *ngIf="networkInfo">
        <ion-label>
          <b>{{ 'Connection' | translate }}:</b>
        </ion-label>
        <ion-label *ngIf="!networkInfo.type">
          {{ networkInfo?.effectiveType?.replace('4g', 'fast') }}
        </ion-label>
        <ion-label *ngIf="networkInfo.type === 'none'">
          {{ networkInfo?.type }}
        </ion-label>
        <ion-label *ngIf="networkInfo.type && networkInfo.type !== 'none'">
          {{ networkInfo?.effectiveType?.replace('4g', 'fast') }} {{ networkInfo?.type ? '(' + networkInfo.type + ')' : '' }}
        </ion-label>
      </ion-item>

      <ion-item *ngIf="networkInfo">
        <ion-label>
          <b>{{ 'Downlink' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ networkInfo?.downlink ? (networkInfo?.downlink + 'Mbps') : ('Not Available') }}
        </ion-label>
      </ion-item>

      <ion-item *ngIf="networkInfo">
        <ion-label>
          <b>{{ 'RTT' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ networkInfo?.rtt ? (networkInfo?.rtt + 'ms') : ('Not Available') }}
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-list *ngIf="activeView === 'status' && status">
      <ion-item>
        <ion-label>
          <b>{{ 'DateTime' | translate }}:</b>
        </ion-label>
        <ion-label>
          {{ status.dateTime | date:'yyyy-MM-dd HH:mm:ss' }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'SystemOk' | translate }}?</b>
        </ion-label>
        <ion-label>
          {{ status.systemOk }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'SystemError' | translate }}?</b>
        </ion-label>
        <ion-label>
          {{ status.SystemError }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <b>{{ 'ReqResp Time' | translate }}?</b>
        </ion-label>
        <ion-label>
          {{ status.requestResponseTimeInMs }}ms
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-list *ngIf="activeView === 'operational' && stats">
      <ion-item>
        <ion-label class="ion-text-wrap">
          <b>{{ 'Network Downlink' | translate }} (since {{ stats['NetworkInfoDownlink']?.oldestDate | date:'yyyy-MM-dd HH:mm:ss' }}):</b><br>
          min: {{ stats['NetworkInfoDownlink']?.min || 0 }}Mbps, avg: {{ stats['NetworkInfoDownlink']?.avg || 0 }}Mbps, max: {{ stats['NetworkInfoDownlink']?.max || 0 }}Mbps
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label class="ion-text-wrap">
          <b>{{ 'Network RTT' | translate }} (since {{ stats['NetworkInfoRTT']?.oldestDate | date:'yyyy-MM-dd HH:mm:ss' }}):</b><br>
          min: {{ stats['NetworkInfoRTT']?.min || 0 }}ms, avg: {{ stats['NetworkInfoRTT']?.avg || 0 }}ms, max: {{ stats['NetworkInfoRTT']?.max || 0 }}ms
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label class="ion-text-wrap">
          <b>{{ 'Api ReqResp Time' | translate }} (since {{ stats['ApiRequestResponseTimeInMs']?.oldestDate | date:'yyyy-MM-dd HH:mm:ss' }}):</b><br>
          min: {{ stats['ApiRequestResponseTimeInMs']?.min / 1000 }}s, avg: {{ stats['ApiRequestResponseTimeInMs']?.avg / 1000 }}s, max: {{ stats['ApiRequestResponseTimeInMs']?.max / 1000 }}s
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label class="ion-text-wrap">
          <b>{{ 'Api Failed Requests Count' | translate }} (since {{ stats['ApiFailedRequestsCount']?.oldestDate | date:'yyyy-MM-dd HH:mm:ss' }}):</b><br>
          {{ stats['ApiFailedRequestsCount']?.count || 0 }}
        </ion-label>
      </ion-item>


      <ion-item>
        <ion-label class="ion-text-wrap">
          <b>{{ 'WS Init Connection Time' | translate }} (since {{ stats['WsConnectionTimeInMs']?.oldestDate | date:'yyyy-MM-dd HH:mm:ss' }}):</b><br>
          min: {{ stats['WsConnectionTimeInMs']?.min / 1000 }}s, avg: {{ stats['WsConnectionTimeInMs']?.avg / 1000 }}s, max: {{ stats['WsConnectionTimeInMs']?.max / 1000 }}s
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label class="ion-text-wrap">
          <b>{{ 'WS ReqResp Time' | translate }} (since {{ stats['WsRequestResponseTimeInMs']?.oldestDate | date:'yyyy-MM-dd HH:mm:ss' }}):</b><br>
          min: {{ stats['WsRequestResponseTimeInMs']?.min / 1000 }}s, avg: {{ stats['WsRequestResponseTimeInMs']?.avg / 1000 }}s, max: {{ stats['WsRequestResponseTimeInMs']?.max / 1000 }}s
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label class="ion-text-wrap">
          <b>{{ 'WS Counts' | translate }} (since {{ stats['WsReconnectRetriesCount']?.oldestDate | date:'yyyy-MM-dd HH:mm:ss' }}):</b><br>
          disconnects: {{ stats['WsDisconnectsCount']?.count || 0 }}, reconnect retries: {{ stats['WsReconnectRetriesCount']?.count || 0 }}, timeouts: {{ stats['WsTimeoutsCount']?.count || 0 }}
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label class="ion-text-wrap">
          <b>{{ 'App Usage' | translate }} (since {{ stats['AppAliveStateChanges']?.oldestDate | date:'yyyy-MM-dd HH:mm:ss' }}):</b><br>
          alive: {{ stats['AppAliveStateChanges']?.stateDurationString['alive'] || '-' }}, paused: {{ stats['AppAliveStateChanges']?.stateDurationString['paused'] || '-' }}, online: {{ stats['AppOnlineStateChanges']?.stateDurationString['online'] || '-' }}, offline: {{ stats['AppOnlineStateChanges']?.stateDurationString['offline'] || '-' }}
        </ion-label>
      </ion-item>
    </ion-list>

    <div class="flex-1"></div>

    <div *ngIf="activeView === 'operational' && stats"
      style="text-align: center; padding-bottom: 8px;">
      <ion-button
        (click)="clearOpsStats()">
        {{ 'Clear Statistics' | translate }}&nbsp;&nbsp;
        <ion-icon name="trash"></ion-icon>
      </ion-button>
    </div>
  </div>

</ion-content>
