import { Injectable, Injector } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { BrowserUtils, LogUtils } from '../../../utils';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';


export interface OpticonPluginSettings {

}


@Injectable({
  providedIn: 'root'
})
export class OpticonPlugin extends BasePlugin {

  private readonly intentActionBarcode = 'com.opticon.decode.action';
  private readonly intentCategoryBarcode = 'com.opticon.decode.category';

  private isBarcodeScannerEnabled: boolean;
  private isPluginAllowedChecked: boolean;
  private settings: OpticonPluginSettings;

  constructor(
    private device: Device,
    injector: Injector,
    private platform: Platform,
    private scannerService: ScannerService,
  ) {
    super(injector);

    this.pluginName = 'OpticonPlugin';

    this.settings = {};
  }

  isPluginAllowed(): boolean {
    const manufacturer = this.platform.is('cordova') ? (this.device.manufacturer || '').toLowerCase() : '';
    return BrowserUtils.isDeviceApp() && manufacturer.indexOf('opticon') >= 0;
  }

  initialize(options?: any): Observable<void> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Opticon device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    this.registerScannerCallback();

    return of(null);
  }

  private registerScannerCallback(): void {
    this.log('Registering to receive barcode scanner data...');
    (window as any).plugins.intentShim.unregisterBroadcastReceiver();
    (window as any).plugins.intentShim.registerBroadcastReceiver({
      filterActions: [
        this.intentActionBarcode,
      ],
      filterCategories: [
        this.intentCategoryBarcode,
      ]},
      this.handleScannerIntent.bind(this)
    );
  }

  private handleScannerIntent(intent: any): void {
    intent.extras = intent.extras || {};
    LogUtils.log('Opticon Intent: ', intent);

    if (intent.action === this.intentActionBarcode) {
      if (!this.isBarcodeScannerEnabled) return;

      const value = intent.extras['com.opticon.decode.barcode_data'];
      const valueType = intent.extras['com.opticon.decode.barcode_type'];
      this.scannerService.emitScan({
        source: 'SCANNER',
        value: value,
        valueType: valueType,
      });
    }
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Opticon device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    switch(options.command) {
      case 'enable_scanner':
        if (!this.isBarcodeScannerEnabled) this.log('Enabling barcode scanner...');
        this.isBarcodeScannerEnabled = true;
        return of(null);
      case 'disable_scanner':
        if (this.isBarcodeScannerEnabled) this.log('Disabling barcode scanner...');
        this.isBarcodeScannerEnabled = false;
        return of(null);
      case 'has_scanner':
        return of(true);
      case 'toggle_soft_scan':
        return of(null);
      default:
        return of(null);
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Opticon device...');
      this.isPluginAllowedChecked = true;
      return of('Not running on Opticon device...');
    }

    return of({
      enabled: this.isPluginAllowed(),
      isBarcodeScannerEnabled: this.isBarcodeScannerEnabled,
      log: Array.from(this.logRingBuffer),
    });
  }

  private sendBroadcast(action: string, extraName?: string, extraValue?: any,) {
    (window as any).plugins.intentShim.sendBroadcast({
      action: action,
      extras: extraName ? {
        [extraName]: extraValue,
        'SEND_RESULT': 'false'
      } : {
        'SEND_RESULT': 'false'
      }
    },
      () => { }, // Success in sending the intent, not success of DW to process the intent.
      () => { }, // Failure in sending the intent, not failure of DW to process the intent.
    );
  }

}
