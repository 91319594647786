
export class DeviceLocationSchedule {
  guidId: string;
  name: string;
  default: boolean;
  requireUser: boolean;
  gpsEnabled: boolean;

  timeDaysOfWeek: number;
  timeHourStart: string;
  timeHourEnd: string;

  gpsTimeSpanInSeconds: number;
  sendTimeSpanInSeconds: number;

  constructor(item?: Partial<DeviceLocationSchedule>) {
    Object.assign(this, item);
  }
}

export class DeviceLocationScheduler {
  name: string;
  version: string;
  sysVersion: number;
  tick: number;
  schedulers: DeviceLocationSchedule[];

  constructor(item?: Partial<DeviceLocationScheduler>) {
    Object.assign(this, item);

    this.schedulers = (this.schedulers || []).map(x => new DeviceLocationSchedule(x));
  }
}