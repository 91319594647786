import { NgModule } from '@angular/core';
import { FooterComponent, HeaderComponent } from './components';
import { SharedModule } from './shared.module';


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
  ]
})
export class NavigationModule { }